import React, { useState, useEffect } from 'react';
import './Notify.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { useDispatch, useSelector } from 'react-redux';
import { setStatus } from '../../services/store/reducers/notifySlice';

function Notify(props) {
    const dispatch = useDispatch();
    const type = useSelector((state) => state.notify.type);
    const message = useSelector((state) => state.notify.message);
    const status = useSelector((state) => state.notify.status);
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        let timer;
        if (status) {
            setProgress(100);
            timer = setTimeout(() => {
                dispatch(setStatus(false));
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [status, dispatch]);

    useEffect(() => {
        let progressTimer;
        if (status) {
            progressTimer = setInterval(() => {
                setProgress(prev => prev - 1);
            }, 50);
        }
        return () => clearInterval(progressTimer);
    }, [status]);

    return (
        <div className={"Notify-container"} style={{ backgroundColor: type ? "rgba(3, 158, 0, 1)" : "rgba(158, 0, 0, 1)", right: status ? "30px" : "-300px" }}>
            <p>{message}</p> 
            <button onClick={() => dispatch(setStatus(false))}>
                <i
                    style={{ color: "#fff", marginRight: "5px", marginLeft: "5px" }}
                    className={"fa-solid fa-xmark"}
                ></i>
            </button>
            <div className="Notify-progress" style={{ width: `${progress}%` }}></div>
        </div>
    );
}

export default Notify;
