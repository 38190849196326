import { Link, Hash, Images, Youtube, TicketCheck, Puzzle, Store } from "lucide-react"

export const componentsSpaceLock = [
    {name: "Profile", vertical: true, logo: false},
]
// pour Account ne pas oublier de faire se connecter aux réseaux si pas fait
export const componentsSpace = [
    {id: "", name: "Link", color: '#ff8500', title: "Titre de la section", subtitle: "Sous-titre du lien", link: "https://example.com", visibility: true,},
    {id: "", name: "LinkMerch", color: '#ff8500', title: "Nom du produit", subtitle: "9.99€", link: "https://example.com", visibility: true,},
    {id: "", name: "Links", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,},
    {id: "", name: "Files", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,},
    {id: "", name: "File", color: '#ff8500', title: "Titre de la section", subtitle: "Sous-titre du lien", visibility: true,},
    {id: "", name: "LinkMusic", color: '#ff8500', idMedia: "", visibility: true,},
    {id: "", name: "LinkPodcast", color: '#ff8500', idMedia: "", visibility: true,},
    {id: "", name: "LinkTikTok", color: '#ff8500', idMedia: "", visibility: true,},
    {id: "", name: "Gallery", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,},
    {id: "", name: "Media", color: '#ff8500', link: "", description: "Titre du media", type: "image", visibility: true,},
    {id: "", name: "Merch", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,},
    {id: "", name: "LinksMusic", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,},
    {id: "", name: "LinksPodcast", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,},
    {id: "", name: "LinksTikTok", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,},
    {id: "", name: "Podcast", color: '#ff8500', title: "Titre de la section", visibility: true,},
    {id: "", name: "Video", color: '#ff8500', idMedia: "", description: true, visibility: true,},
    {id: "", name: "IndividualVideo", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,},
    {id: "", name: "VideoCollection", color: '#ff8500', title: "Titre de la section", nbVideo: 3, visibility: true,},
    {id: "", name: "YoutubeShort", color: '#ff8500', title: "Titre de la section", visibility: true,},
    {id: "", name: "TikTok", color: '#ff8500', title: "Titre de la section", visibility: true,},
    {id: "", name: "Text", color: '#ff8500', title: "Titre de la section", description: "Ajouter du texte.", visibility: true,},
    {id: "", name: "LinkedinAccount", color: '#ff8500', title: "Titre de la section", description: "Décrivez votre poste/activité que vous pratiquez.", visibility: true,},
    {id: "", name: "MusicAccount", color: '#ff8500', title: "Titre de la section", description: "Décrivez vous.", visibility: true,},
    {id: "", name: "LinkConcert", color: '#ff8500', link: "https://example.com", title: "Nom de l'évènement", where: "Lieu", type: true, mounth: "Mois", day: "1", year: "2024", visibility: false,},
    {id: "", name: "Event", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,}
];
