export function extractShortId(shortUrl) {
    try {
      const urlObj = new URL(shortUrl);
      const baseWithoutParams = shortUrl.replace(urlObj.search, '');
  
      // 2. On split sur "/shorts/"
      const parts = baseWithoutParams.split('/shorts/');
      if (parts.length > 1) {
        // la partie après "/shorts/" est l'ID potentiel
        // ex: "HZpOPl6SBpQ"
        return parts[1].replace('/', ''); // enlever un éventuel slash résiduel
      }
      // Si jamais l'URL ne contient pas "/shorts/", renvoie null
      return null;
    } catch (err) {
      console.error('extractShortId error:', err);
      return null;
    }
} 

export async function getYoutubeShortOEmbedData(shortUrl) {
    try {
      // 1. Extraire l’ID du short
      const shortId = extractShortId(shortUrl);
      if (!shortId) {
        throw new Error('Impossible de trouver un ID Shorts dans l’URL : ' + shortUrl);
      }
      const watchUrl = `https://www.youtube.com/watch?v=${shortId}`;
      const oEmbedUrl = `https://www.youtube.com/oembed?url=${encodeURIComponent(watchUrl)}&format=json`;
  
      // 4. Appeler l’API
      const response = await fetch(oEmbedUrl);
      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status} - ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erreur getYoutubeShortOEmbedData:', error);
      return null;
    }
}
  