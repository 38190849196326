import React, {useState, useEffect} from 'react';
import "./Link.css";
import { Image } from 'lucide-react';
import { getImageComponent } from '../../services/storage/storageService'; // Assurez-vous que le chemin est correct
import { useUser } from '../../utils/contexts/userContext'; // Assurez-vous que le contexte utilisateur est importé
import { useSelector } from 'react-redux';

const LinkEditComponent = ({ linkContainer }) => { // Renommé container en linkContainer
    const { user } = useUser(); // Récupération de l'utilisateur
    const [image, setImage] = useState(null);
    const email = useSelector((state) => state.user.email);

    useEffect(() => {
        const fetchMedia = async () => {
            if (linkContainer) {
                try {
                    // Utilisation de la fonction pour récupérer le média
                    const url = await getImageComponent(email, 'Link', linkContainer.id);
                    setImage(url); // Mettre à jour la prévisualisation avec l'URL récupérée
                } catch (error) {
                    console.error('Erreur lors de la récupération du média:', error);
                    setImage(null); // Réinitialiser si une erreur se produit
                }
            }
        };

        fetchMedia();
    }, [linkContainer]); 

    return (
        <div className='flex items-center'>
            {image ? (
                <img src={image} alt="content" className='mr-2 w-16 h-16 object-cover object-center rounded-lg' />
            ) : (
                <Image className='mr-2 w-16 h-16' />
            )}
            <div className='flex flex-col'>
                <strong className='titleOverflow text-sm truncate'>{linkContainer.title}</strong>
                <p className='titleOverflow text-sm truncate'>{linkContainer.subtitle}</p>
                <a href={linkContainer.link} className='titleOverflow text-blue-500 text-sm text-left font-bold truncate'>{linkContainer.link}</a>
            </div>
        </div>
    );
};

export default LinkEditComponent;
