import axios from 'axios';
import { normalizeSearchTerm } from '../../utils';

const baseUrl = process.env.REACT_APP_API_URL;

// Fonction générique pour appeler ton serveur Express
const fetchFromDeezer = async (endpoint) => {
    try {
        // Assurez-vous que `endpoint` ne commence pas par `/api/deezer`
        const url = `${baseUrl}${endpoint}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(`Erreur lors de l'appel à l'API Deezer (${endpoint}):`, error.message);
        throw new Error('Erreur lors de la récupération des données Deezer.');
    }
};

export const searchDeezerTrack = async (query, limit = 5) => {
    const endpoint = `/api/deezer/search?q=${encodeURIComponent(query)}&limit=${limit}`;

    // Appel à l'API via fetchFromDeezer
    const response = await fetchFromDeezer(endpoint);

    // Vérification de la structure des données avant de les mapper
    if (!response || !response.data || !Array.isArray(response.data)) {
        throw new Error('Les données renvoyées par l\'API Deezer sont invalides.');
    }

    // Transformation des résultats
    return response.data.map((track) => ({
        title: track.title || 'Titre inconnu',
        artists: [track.artist?.name || 'Artiste inconnu'],
        image: track.album?.cover_medium || '',
        link: track.link || '',
        id: track.id || null,
        preview: track.preview || null, // Lien de prévisualisation
        platform: 'deezer',
    }));
};

export const getDeezerTrackById = async (id) => {
    if (!id) throw new Error("Le paramètre 'id' est requis.");

    const response = await fetchFromDeezer(`/api/deezer/track/${id}`);

    // Validation de la structure des données renvoyées
    if (!response || !response.id || !response.title || !response.artist || !response.album) {
        throw new Error('Les données renvoyées par l\'API Deezer pour cette musique sont invalides.');
    }

    // Transformation des données
    return {
        title: response.title,
        artists: [response.artist.name],
        image: response.album.cover_medium || '',
        link: response.link || '',
        preview: response.preview || null, // Lien de prévisualisation
        platform: 'deezer',
    };
};

// Fonction pour récupérer les informations d'un artiste
export const getDeezerArtistInfo = async (artistId) => {
    return fetchFromDeezer(`/artist/${artistId}`);
};

// Fonction pour récupérer les musiques d'un artiste (top 10)
export const getDeezerTopTracks = async (artistId, limit = 10) => {
    return fetchFromDeezer(`/artist/${artistId}/top?limit=${limit}`);
};

// Fonction pour récupérer les albums d'un artiste
export const getDeezerAlbums = async (artistId) => {
    return fetchFromDeezer(`/artist/album/${artistId}`);
};

// Fonction pour récupérer les musiques d'un album
export const getDeezerAlbumTracks = async (albumId) => {
    return fetchFromDeezer(`/album/${albumId}`);
};

export const searchDeezerPodcasts = async (searchTerm, limit) => {
    try {
        // Séparer le terme de recherche en deux parties : le podcast et l'épisode (séparés par une virgule)
        const [podcastSearchTerm, episodeSearchTerm] = searchTerm.split(',', 2).map(str => str.trim());
        console.log(searchTerm);

        // Recherche du podcast sur Deezer via votre serveur Express
        const podcastResponse = await fetchFromDeezer(`/api/deezer/search/podcast?q=${encodeURIComponent(podcastSearchTerm)}&limit=${limit}`);
        const podcastData = podcastResponse; // Les données sont directement disponibles avec axios
        console.log(podcastData);

        if (podcastData.data && podcastData.data.length > 0) {
            const podcast = podcastData.data[0];
            const podcastId = podcast.id; // Utiliser l'ID du podcast trouvé
            
            // Recherche de tous les épisodes du podcast
            let episodes = await getDeezerPodcastEpisodes(podcastId, podcast.title);

            if (episodeSearchTerm) {
                // Normaliser le terme de recherche de l'épisode
                const normalizedEpisodeSearchTerm = normalizeSearchTerm(episodeSearchTerm);

                // Filtrer les épisodes en fonction du titre normalisé
                episodes = episodes.filter(episode => {
                    // Normaliser le titre de l'épisode pour éviter les problèmes avec les caractères spéciaux
                    const normalizedEpisodeTitle = normalizeSearchTerm(episode.title);
                    // Vérifier si le terme de recherche est inclus dans le titre normalisé de l'épisode
                    return normalizedEpisodeTitle.includes(normalizedEpisodeSearchTerm);
                });
            }

            // Limiter le nombre d'épisodes à retourner si nécessaire
            return episodes.slice(0, limit); // Limiter les résultats si besoin (par exemple 5 premiers)
        }

        return []; // Si aucun podcast n'est trouvé
    } catch (error) {
        console.error('Failed to fetch podcasts or episodes:', error);
        return [];
    }
};

// Modification de la fonction pour accepter un `limit` d'épisodes
const getDeezerPodcastEpisodes = async (id, podcastTitle) => {
    try {
        // Appel pour récupérer tous les épisodes disponibles du podcast
        const response = await fetchFromDeezer(`/api/deezer/podcast/episodes?q=${id}&limit=500`);
        const data = response; // Les données sont directement disponibles avec axios
        console.log(data);

        if (data.data && data.data.length > 0) {
            const episodes = data.data.map((item) => ({
                title: item.title,
                image: item.picture || '',
                link: `https://www.deezer.com/episode/${item.id}`,
                platform: 'deezer',
                preview: null,
                duration: null,
                id: item.id,
                releaseDate: item.release_date,
                podcastTitle: podcastTitle, // Nom du podcast
                podcastCreator: null,
            }));

            // Trier les épisodes par date de publication, du plus récent au plus ancien
            return episodes.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));
        }

        return []; // Si aucun épisode n'est trouvé
    } catch (error) {
        console.error('Failed to fetch episodes:', error);
        return [];
    }
};

export const getDeezerPodcastEpisodesById = async (id) => {
    try {
        // Appel à votre serveur Express pour récupérer les épisodes du podcast Deezer
        const response = await fetchFromDeezer(`/api/deezer/episode?q=${id}`);
        const data = response; // Les données sont directement disponibles avec axios
        if (data) {
            return {
                title: data.title,
                image: data.picture_medium || '',
                link: `https://www.deezer.com/episode/${data.id}`,
                platform: 'deezer',
                preview: null,
                duration: null,
                id: data.id,
                releaseDate: data.release_date,
                podcastTitle: data.podcast.title, // Nom du podcast
                podcastCreator: null,
            };
        }

        return []; // Si aucun épisode n'est trouvé
    } catch (error) {
        console.error('Failed to fetch episodes:', error);
        return [];
    }
};

// Fonction pour récupérer les podcasts d'un créateur
export const getDeezerCreatorPodcasts = async (creatorName) => {
    return fetchFromDeezer(`/search?q=artist:"${creatorName}"&media=podcast`);
};

// Fonction pour récupérer les 10 derniers podcasts d'un créateur
export const getDeezerLatestPodcasts = async (creatorName) => {
    return fetchFromDeezer(`/search?q=artist:"${creatorName}"&media=podcast&order=date&limit=10`);
};

// Fonction pour récupérer les 10 podcasts les plus vus d'un créateur
export const getDeezerTopPodcasts = async (creatorName) => {
    return fetchFromDeezer(`/search?q=artist:"${creatorName}"&media=podcast&order=viewCount&limit=10`);
};
