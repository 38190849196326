import React, { useState, useEffect } from 'react';
import { useUser } from '../../utils/contexts/userContext'; 
import "./MusicAccount.css";
import InputFieldText from '../InputField/InputFieldText';
import { Trash2, PencilLine } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { setPage } from '../../services/store/reducers/userSlice';

const MusicAccountEdit = ({ container, deleteBlock }) => { 
    const { user } = useUser();
    const [visibility, setVisibility] = useState(true);
    const [edit, setEdit] = useState(false);
    const [title, setTitle] = useState(null);
    const [editDescription, setEditDescription] = useState(false);
    const [description, setDescription] = useState(null);
    const dispatch = useDispatch();

    const handleChangeTitle = (newValue) => {
        setTitle(newValue);
    };

    const handleChangeDescription = (newValue) => {
      setDescription(newValue);
  };

    const updateComponent = (id) => { 
        if (title !== null) {
            user.updateObjectInPageById(id, { title: title });
            dispatch(setPage(user.page));
        }
        if (description !== null) {
          user.updateObjectInPageById(id, { description: description });
          dispatch(setPage(user.page));
      }
        setEdit(false);
        setEditDescription(false);
    };
  
    useEffect(() => {
        if(container) {
            setTitle(container.title);
            setDescription(container.description);
        }
    }, [container]);

    return (
        <div className='flex h-auto mt-2 items-center'>
            {container &&
            <div className='flex flex-col w-full'>
                <div className='flex flex-row w-full justify-between gap-6'>
                    {edit ? (
                        <InputFieldText 
                            value={title} 
                            maxLength={256}
                            onChange={(e) => handleChangeTitle(e.target.value)} 
                            placeholder={title} 
                            title={""} 
                            type={true}
                            onBlur={() => updateComponent(container.id)} // Appel de la nouvelle fonction renommée
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    updateComponent(container.id);
                                }
                            }}
                        />
                    ) : (
                        <p className="mr-2 text-lg">{title}</p>
                    )}

                    <div className='flex flex-row gap-2'>
                        <button className='buttonDragAndDrop' onClick={() => setVisibility(!visibility)}>
                            {visibility ? (
                                <i className="fa-solid fa-eye" style={{ color: "#000" }}></i>
                            ) : (
                                <i className="fa-solid fa-eye-slash" style={{ color: "#000" }}></i>
                            )}
                        </button>
                        <button className='buttonDragAndDrop' onClick={() => setEdit(!edit)}>
                            <PencilLine />
                        </button>
                        <button className='buttonDragAndDrop' onClick={() => deleteBlock(container.id)}>
                            <Trash2 />
                        </button>
                    </div>
                </div>
                <div className='flex flex-row w-full justify-between gap-6 my-3'>
                    {editDescription ? (
                        <InputFieldText 
                            type={true}
                            maxLength={256}
                            value={description} 
                            onChange={(e) => handleChangeDescription(e.target.value)} 
                            placeholder={description} 
                            title={""} 
                            onBlur={() => updateComponent(container.id)} // Appel de la nouvelle fonction renommée
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    updateComponent(container.id);
                                }
                            }}
                        />
                    ) : (
                        <p className="mr-2 text-sm text-left">{description}</p>
                    )}

                    <div className='flex flex-row gap-2'>
                        <button className='buttonDragAndDrop' onClick={() => setEditDescription(!editDescription)}>
                            <PencilLine />
                        </button>
                    </div>
                </div>
                
            </div>
            }
        </div>
    );
};

export default MusicAccountEdit;
