import axios from 'axios';
import { normalizeSearchTerm } from '../../utils';

const API_KEY = process.env.REACT_APP_API_KEY_YOUTUBE; // Ta clé API YouTube
const BASE_URL = 'https://www.googleapis.com/youtube/v3';

// Fonction pour effectuer des requêtes génériques à l'API YouTube
const fetchFromYoutube = async (endpoint, params) => {
    try {
        const response = await axios.get(`${BASE_URL}/${endpoint}`, {
            params: { key: API_KEY, ...params },
        });
        return response.data;
    } catch (error) {
        console.error(`Erreur lors de l'appel à l'API YouTube (${endpoint}):`, error);
        return null;
    }
};

export const searchYoutubeTrack = async (query, maxResults = 1) => {
    try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
            params: {
                part: 'snippet',
                q: query,
                type: 'video',
                maxResults,
                key: API_KEY, // Utilisez votre clé API
            },
        });
        console.log(response.data);
        if (response.data && response.data.items) {
            return response.data.items.map((item) => ({
                id: item.id.videoId,
                title: item.snippet.title,
                artists: [item.snippet.channelTitle], // Le titre du channel comme artiste
                image: item.snippet.thumbnails.default.url,
                link: `https://www.youtube.com/watch?v=${item.id.videoId}`,
                preview: null, 
                platform: 'youtubemusic',
            }));
        }

        return [];
    } catch (error) {
        console.error('Erreur lors de la recherche sur YouTube Music:', error);
        throw error;
    }
};

export const getYoutubeTrackById = async (id) => {
    const apiKey = API_KEY; // Remplace par ta clé API YouTube
    const response = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${id}&key=${apiKey}`
    );
    const data = await response.json();

    const video = data.items[0]?.snippet;
    return {
        title: video.title,
        artists: [video.channelTitle], // Utilise le nom de la chaîne comme artiste
        image: video.thumbnails.medium?.url || '',
        link: `https://www.youtube.com/watch?v=${id}`,
        preview: null, // Pas de flux audio direct disponible
        platform: 'youtubemusic',
    };
};

// Fonction pour récupérer les informations d'un artiste
export const getYoutubeArtistInfo = async (artistName) => {
    const data = await fetchFromYoutube('search', {
        part: 'snippet',
        q: artistName,
        type: 'channel',
        maxResults: 1,
    });
    return data?.items?.[0] || null;
};

// Fonction pour récupérer les vidéos d'un artiste (top 10)
export const getYoutubeArtistTopTracks = async (channelId, limit = 10) => {
    return fetchFromYoutube('search', {
        part: 'snippet',
        channelId: channelId,
        type: 'video',
        order: 'viewCount',
        maxResults: limit,
    });
};

// Fonction pour récupérer les albums d'un artiste
export const getYoutubeAlbums = async (artistName, limit = 10) => {
    return fetchFromYoutube('search', {
        part: 'snippet',
        q: `${artistName} album`,
        type: 'playlist',
        maxResults: limit,
    });
};

// Fonction pour récupérer les vidéos d'une playlist (musiques d'un album)
export const getYoutubeAlbumTracks = async (playlistId, limit = 10) => {
    return fetchFromYoutube('playlistItems', {
        part: 'snippet',
        playlistId: playlistId,
        maxResults: limit,
    });
};

export const searchYouTubeMusicPodcasts = async (searchTerm, limit = 5) => {
    try {
        // Séparer le terme de recherche en deux parties : le podcast et l'épisode (séparés par une virgule)
        const [podcastSearchTerm, episodeSearchTerm] = searchTerm.split(',').map(str => str.trim());
        console.log(searchTerm, podcastSearchTerm, episodeSearchTerm);

        // Rechercher le podcast sur YouTube Music via l'API YouTube
        const podcastResponse = await fetch(`https://www.googleapis.com/youtube/v3/search?part=snippet&q=${encodeURIComponent(podcastSearchTerm)}&type=playlist&maxResults=${limit}&key=${API_KEY}`);
        const podcastData = await podcastResponse.json();  // Récupérer les données JSON
        console.log(podcastData);

        if (podcastData.items && podcastData.items.length > 0) {
            const podcast = podcastData.items[0];
            const playlistId = podcast.id.playlistId;  // Utiliser l'ID de la playlist trouvée

            // Recherche des vidéos du podcast (les épisodes de la playlist)
            let episodes = await getYouTubeMusicPodcastEpisodes(playlistId, podcast.snippet.title);

            if (episodeSearchTerm) {
                // Si un terme de recherche d'épisode est fourni, filtrer les épisodes à la volée
                const normalizedEpisodeSearchTerm = normalizeSearchTerm(episodeSearchTerm);
                episodes = episodes.filter(episode => {
                    const normalizedEpisodeTitle = normalizeSearchTerm(episode.title);
                    return normalizedEpisodeTitle.includes(normalizedEpisodeSearchTerm);
                });
            }

            // Limiter le nombre d'épisodes à retourner si nécessaire
            return episodes.slice(0, limit); // Limiter les résultats si besoin
        }

        return []; // Si aucun podcast n'est trouvé
    } catch (error) {
        console.error('Failed to fetch podcasts or episodes:', error);
        return [];
    }
};

const getYouTubeMusicPodcastEpisodes = async (playlistId, podcastTitle) => {
    try {
        // Appel API pour récupérer les vidéos (épisodes) d'une playlist
        const response = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=50&key=${API_KEY}`);
        const data = await response.json();  // Récupérer les données JSON
        console.log(data);

        if (data.items && data.items.length > 0) {
            const episodes = data.items.map(item => {
                // Exclure les shorts (si la vidéo est un short)
                if (item.snippet.resourceId.kind !== "youtube#video") {
                    return null; // Ignorer les shorts ou autres types
                }

                return {
                    title: item.snippet.title,
                    image: item.snippet.thumbnails.high.url || '',
                    link: `https://www.youtube.com/watch?v=${item.snippet.resourceId.videoId}`,  // Lien vers l'épisode
                    platform: 'youtubemusic',
                    preview: null,  // Ajouter le lien de prévisualisation si disponible
                    duration: null,  // Ajouter la durée de l'épisode si disponible
                    id: item.snippet.resourceId.videoId,
                    releaseDate: item.snippet.publishedAt,
                    podcastTitle: podcastTitle, // Nom du podcast
                    podcastCreator: null,  // Ajouter l'artiste ou créateur du podcast si disponible
                };
            }).filter(Boolean);  // Filtrer les nulls (les shorts ou autres vidéos non valides)

            // Trier les épisodes par date de publication, du plus récent au plus ancien
            return episodes.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));
        }

        return []; // Si aucun épisode n'est trouvé
    } catch (error) {
        console.error('Failed to fetch episodes:', error);
        return [];
    }
};

export const getYouTubeMusicPodcastEpisodeById = async (episodeId) => {
    try {
        // Appel API pour récupérer les informations détaillées de l'épisode
        const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${episodeId}&key=${API_KEY}`);
        const data = await response.json();  // Récupérer les données JSON
        console.log(data);

        if (data.items && data.items.length > 0) {
            const item = data.items[0]; // Récupérer le premier élément (l'épisode)

            // Retourner les informations détaillées de l'épisode
            return {
                title: item.snippet.title,
                image: item.snippet.thumbnails.high.url || '',  // Image de l'épisode
                link: `https://www.youtube.com/watch?v=${item.id}`,  // Lien vers l'épisode
                platform: 'youtubemusic',
                preview: null,  // Ajouter le lien de prévisualisation si disponible
                duration: item.contentDetails.duration,  // Ajouter la durée de l'épisode
                id: item.id,
                releaseDate: item.snippet.publishedAt,
                podcastTitle: item.snippet.channelTitle,  // Nom du podcast
                podcastCreator: null,  // Ajouter l'artiste ou créateur du podcast si disponible
            };
        }

        return null; // Si aucun épisode n'est trouvé
    } catch (error) {
        console.error('Failed to fetch episode:', error);
        return null;
    }
};


export const getYouTubeMusicPodcastById = async (id) => {
    // Récupérer les détails de la playlist (podcast)
    const response = await fetch(
        `https://www.googleapis.com/youtube/v3/playlists?part=snippet&id=${id}&key=${API_KEY}`
    );

    if (!response.ok) {
        throw new Error('Impossible de récupérer le podcast de YouTube Music.');
    }

    const data = await response.json();
    const podcast = data.items[0];

    return {
        title: podcast.snippet.title,
        artists: podcast.snippet.channelTitle,
        image: podcast.snippet.thumbnails.medium.url,
        link: `https://www.youtube.com/playlist?list=${id}`,
        platform: 'youtubemusic',
        id: podcast.id,
    };
};

// Fonction pour récupérer les vidéos d'un créateur
export const getYoutubeCreatorPodcasts = async (channelId, limit = 10) => {
    return fetchFromYoutube('search', {
        part: 'snippet',
        channelId: channelId,
        type: 'video',
        q: 'podcast',
        maxResults: limit,
    });
};

// Fonction pour récupérer les 10 derniers podcasts d'un créateur
export const getYoutubeLatestPodcasts = async (channelId, limit = 10) => {
    return fetchFromYoutube('search', {
        part: 'snippet',
        channelId: channelId,
        type: 'video',
        q: 'podcast',
        order: 'date',
        maxResults: limit,
    });
};

// Fonction pour récupérer les 10 podcasts les plus vus d'un créateur
export const getYoutubeTopPodcasts = async (channelId, limit = 10) => {
    return fetchFromYoutube('search', {
        part: 'snippet',
        channelId: channelId,
        type: 'video',
        q: 'podcast',
        order: 'viewCount',
        maxResults: limit,
    });
};
