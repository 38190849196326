import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHobsManager } from '../../../utils/contexts/hobsManagerContext';
import "../DashboardPages.css";

const Collaborateurs = () => {
    const { hobsManager } = useHobsManager();
    const dispatch = useDispatch();
    const email = useSelector((state) => state.user.email);

    return (
        <div className="DashboardPages">
            <div className='w-full flex flex-row items-start justify-center p-5 pb-0 gap-5'>
                
            </div>
        </div>
    );
};

export default Collaborateurs;
