import React from 'react'
import './LinkedinProfil.css';
import { useUser } from '../../utils/contexts/userContext';
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../utils';

function LinkedinProfil(props) {
    const color = useSelector((state) => state.user.colorFont);
    const backgroundColor = useSelector((state) => state.user.backgroundColor);

    const bgdImage = useSelector(state => state.user.backgroundImage);
    const {user} = useUser();
    const images = useSelector((state) => state.user.images);
    const link = user.links.find(link => link.name === "linkedin")?.link;
    // quand l'utilisateur aura connecté son compte faudra mettre le lien
    return (
        <div className={`LinkedinProfil`} style={bgdImage ? {backgroundColor: hexToRgb(user.backgroundColor)} : {backgroundColor: `rgba(255,255,255,0.15)`}}>
            <img className={`imgProfil`} src={images} alt='profile linkedin' />
            <div className={`containerInfoPerso`}>
                <p className={`titleProfil`} style={{color: color}}>{user.lastname + " " + user.firstname}</p>
                <p className={`singerProfil`} style={{color: color}}>{props.description !== "" ? props.description : "Décrivez votre poste/activité que vous pratiquez."}</p>
            </div>
            <div className={`linkPlateform`} style={bgdImage ? {backgroundColor: user.colorFont} : {backgroundColor: user.backgroundColor}}>
            <a className={"linkImgPlateform gap-1"} href={link} target='_blank' rel="noreferrer">
                    <div className={"linkImg"}>
                        <i style={bgdImage ? {color: user.backgroundColor} : {color: user.colorFont}} className={"fa-brands fa-linkedin"}></i>
                    </div>
                    <p className={`textLinkedinProfil text-lg max-md:text-base max-425:text-sm max-350:text-xs`} style={bgdImage ? {color: user.backgroundColor} : {color: user.colorFont}}>Découvrez mon Linkedin</p>
                </a>
            </div>
        </div>

    );
}

export default LinkedinProfil;