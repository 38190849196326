import React from 'react'
import './Logo.css';
import ImageProfile from '../ImageProfile/ImageProfile';

function Logo(props) {

    const user = props.user;
    
    return (
        <div className='Logo'>
            <div className="containerCover">
                {props.image !== undefined ?
                    <ImageProfile img={props.image} imgFullCover={false} format={true} />
                    :
                    <div className='w-32 h-44 rounded-[20px] flex items-center overflow-hidden justify-center' style={{backgroundColor: user.colorFont}}>
                        <i style={{ color: user.backgroundColor, fontSize: "100px", position: "relative" }} className={"fa-regular fa-user"}></i>
                    </div>
                }
            </div>
            {props.logo &&
                <img className='imgLogo' src={props.logo} alt="logo personnalité" />
            }
            {user.firstname === "" && user.lastname === "" ? null :
                <h1 className='titleProfile' style={{color: user.colorFont}}>{user.firstname} {user.lastname}</h1>
            }
            {user.description === "" ? null :
                <p className='subtitleProfile' style={{color: user.colorFont}}>{user.description}</p>
            }
        </div>
    );
}

export default Logo;