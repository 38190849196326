import { isHexColor } from "../../utils";

export default class HobsManager {
  constructor(data) {
    this.hobspaceInit = {
        ...data.hobspaceInit,
        firstname: "John",
        lastname: "Doe",
        phoneNumber: "33123456789",
    };
    this.entreprise = data.entreprise;
    this.collaborators = data.collaborators;
    this.subscription = data.subscription;
  }

  // Méthodes de gestion des liens
  updateLink(links) {
    this.hobspaceInit.links = links;
  }

  reorderLink(newOrder) {
    this.hobspaceInit.links = newOrder;
  }

  removeLink(name) {
    this.hobspaceInit.links = this.hobspaceInit.links.filter(
      (link) => link.name !== name
    );
  }

  updateLinkByName(name, newLink) {
    const updateRecursive = (links) => {
      return links.map((link) => {
        if (link.name === name) {
          return { ...link, link: newLink };
        }
        return link;
      });
    };
    this.hobspaceInit.links = updateRecursive(this.hobspaceInit.links);
  }

  // Méthodes de gestion des couleurs
  updateColorFont(color) {
    if (isHexColor(color)) {
      this.hobspaceInit.colorFont = color;
    } else {
      console.error(
        "Invalid color format. Please provide a valid hex color."
      );
    }
  }

  updateBackgroundColor(color) {
    if (isHexColor(color)) {
      this.hobspaceInit.backgroundColor = color;
    } else {
      console.error(
        "Invalid color format. Please provide a valid hex color."
      );
    }
  }

  // Méthode pour mettre à jour la description dans hobspaceInit
  updateDescription(description) {
    this.hobspaceInit.description = description;
  }

  updateCountry(country) {
    this.hobspaceInit.country = { code: country.code, name: country.name };
  }

  updateContactActive(email, phone) {
    this.hobspaceInit.contactActive = { email, phone };
  }

  updatePage(page) {
    this.hobspaceInit.page = page;
  }

  // Méthodes de gestion des composants sur la page
  reorderObjectToComponents(id, newOrder, component) {
    const updateRecursive = (items) => {
      return items.map((item) => {
        if (item.id === id) {
          if (component === "Link" && item.componentTickets) {
            console.log(newOrder, id);
            return { ...item, componentTickets: newOrder };
          } else if (component === "Article" && item.componentArticles) {
            console.log(newOrder);
            return { ...item, componentArticles: newOrder };
          } else if (item.components) {
            return { ...item, components: newOrder };
          }
        }
        if (item.components) {
          return { ...item, components: updateRecursive(item.components) };
        }
        return item;
      });
    };
    this.hobspaceInit.page = updateRecursive(this.hobspaceInit.page);
  }

  addObjectToComponents(id, newComponent) {
    const updateRecursive = (items) => {
      return items.map((item) => {
        if (item.id === id) {
          if (item.components) {
            return { ...item, components: [...item.components, newComponent] };
          }
          if (item.componentTickets && newComponent.name === "Link") {
            console.log(newComponent);
            return {
              ...item,
              componentTickets: [...item.componentTickets, newComponent],
            };
          }
          if (item.componentArticles && newComponent.name === "Article") {
            console.log(newComponent);
            return {
              ...item,
              componentArticles: [...item.componentArticles, newComponent],
            };
          }
        }
        if (item.components) {
          return { ...item, components: updateRecursive(item.components) };
        }
        return item;
      });
    };
    this.hobspaceInit.page = updateRecursive(this.hobspaceInit.page);
  }

  removeObjectById(id) {
    const updateRecursive = (items) => {
      return items
        .map((item) => {
          if (item.id === id) {
            return null;
          }
          if (item.components) {
            return {
              ...item,
              components: updateRecursive(item.components).filter(
                (subItem) => subItem !== null
              ),
            };
          }
          return item;
        })
        .filter((item) => item !== null);
    };
    this.hobspaceInit.page = updateRecursive(this.hobspaceInit.page);
    console.log(`Object with ID ${id} removed.`);
  }

  updateObjectInPageById(id, updates) {
    const updateRecursive = (items) => {
      return items.map((item) => {
        if (item.id === id) {
          return { ...item, ...updates };
        }
        if (item.components) {
          return { ...item, components: updateRecursive(item.components) };
        }
        return item;
      });
    };
    this.hobspaceInit.page = updateRecursive(this.hobspaceInit.page);
  }

  updateImageById(id, newImage) {
    const updateRecursive = (items) => {
      return items.map((item) => {
        if (item.id === id) {
          return { ...item, image: newImage };
        }
        if (item.components) {
          return { ...item, components: updateRecursive(item.components) };
        }
        return item;
      });
    };
    this.hobspaceInit.page = updateRecursive(this.hobspaceInit.page);
    console.log(`Image updated for ID ${id}: ${newImage}`);
  }

  // Méthodes pour vérifier les liens YouTube et musicaux
  hasYouTubeLink() {
    return this.hobspaceInit.links.some(
      (link) =>
        link.name === "youtube" &&
        link.active &&
        link.link.includes("youtube.com")
    );
  }

  hasMusicLinks() {
    const requiredLinks = ["deezer", "spotify", "applemusic"];
    return requiredLinks.every((requiredLink) =>
      this.hobspaceInit.links.some(
        (link) =>
          link.name === requiredLink && link.active && link.link
      )
    );
  }

  // --- Nouvelles fonctions pour l'entreprise ---
  updateEntrepriseName(newName) {
    if (!this.entreprise) this.entreprise = {};
    this.entreprise.name = newName;
  }

  updateEntrepriseAddress(newAddress) {
    if (!this.entreprise) this.entreprise = {};
    this.entreprise.address = newAddress;
  }

  updateEntrepriseDescription(newDescription) {
    if (!this.entreprise) this.entreprise = {};
    this.entreprise.description = newDescription;
  }

  // --- Nouvelles fonctions pour les collaborateurs ---
  addCollaborator(collaborator) {
    if (!this.collaborators) {
      this.collaborators = [];
    }
    this.collaborators.push(collaborator);
  }

  removeCollaborator(collaboratorId) {
    if (this.collaborators) {
      this.collaborators = this.collaborators.filter(
        (c) => c.id !== collaboratorId
      );
    }
  }

  updateCollaborator(collaboratorId, updatedData) {
    if (this.collaborators) {
      this.collaborators = this.collaborators.map((c) => {
        if (c.id === collaboratorId) {
          return { ...c, ...updatedData };
        }
        return c;
      });
    }
  }

  // Méthode pour exporter les données vers Firestore
  toFirestore() {
    return {
      description: this.hobspaceInit.description,
      country: this.hobspaceInit.country,
      colorFont: this.hobspaceInit.colorFont,
      backgroundColor: this.hobspaceInit.backgroundColor,
      links: this.hobspaceInit.links,
      page: this.hobspaceInit.page,
      contactActive: this.hobspaceInit.contactActive,
      entreprise: this.entreprise,
      collaborators: this.collaborators,
      subscription: this.subscription,
    };
  }

  // Méthode statique pour créer une instance à partir des données Firestore
  static fromFirestore(data) {
    return new HobsManager(data);
  }
}
