import React, { useState, useEffect } from 'react';
import './Me.css';
import NavigationHome from '../../Layouts/Navigation/NavigationHome/NavigationHome';
import Footer from '../../Layouts/Footer/Footer';
import { Link } from "react-router-dom";
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from "../../utils/contexts/userContext";
import MetaTags from '../../components/MetaTags/MetaTags';
import InputFieldText from '../../components/InputField/InputFieldText';
import { PencilLine, Trash2 } from 'lucide-react';
import Notify from '../../components/Notify/Notify';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';
import { formatPhoneNumber } from '../../utils';
import InputPhoneNumber from '../../components/InputField/InputPhoneNumber';
import { updateUserProfile, updateUserInfo, renameDocument, deleteUserInfo } from '../../services/storage/storageService';
import { checkHobsSpaceExists, checkEmailExist, checkPasswordStrength, checkHobspaceIsValid } from '../../services/auth/authService';
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { passwordIsValid, hobsSpaceIsValid } from '../../services/store/reducers/authSlice';
import { setNotify } from '../../services/store/reducers/notifySlice';
import { auth } from '../../services/firebase/firebase.config';
import { updatePassword as firebaseUpdatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import CustomModal from '../../components/Modal/CustomModal/CustomModal';

function Me() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const image = useSelector((state) => state.user.images);
    const email = useSelector((state) => state.user.email);
    const { user } = useUser();
    const [editEmail, setEditEmail] = useState(false);
    const [editFullName, setEditFullName] = useState(false);
    const [editPhone, setEditPhone] = useState(false);
    const [editPassword, setEditPassword] = useState(false);
    const [editHobspace, setEditHobspace] = useState(false);
    const [emailValue, setEmailValue] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newConfirmPassword, setNewConfirmPassword] = useState("");
    const [oldPasswordIsVisible, setOldPasswordIsVisible] = useState(false);
    const [newPasswordIsVisible, setNewPasswordIsVisible] = useState(false);
    const [newConfirmpasswordIsVisible, setNewConfirmPasswordIsVisible] = useState(false);
    const passwordValid = useSelector((state) => state.auth.passwordIsValid);
    const hobspaceValid = useSelector((state) => state.auth.hobsSpaceIsValid);
    const errorMessage = useSelector((state) => state.auth.errorMessage);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [hobspace, setHobspace] = useState("");
    const [isHovered, setIsHovered] = useState(false);

    const handleChangeEmail = (newValue) => {
        setEmailValue(newValue);
    };

    const handlePhoneNumberChange = (number) => {
        setPhoneNumber(number);
        // Supposons ici que votre objet user expose une méthode pour mettre à jour le numéro
        user.updatePhoneNumber(number);
    };

    const handleChangeFirstname = (newValue) => {
        setFirstname(newValue);
        user.updateFirstname(newValue);
    };

    const handleChangeLastname = (newValue) => {
        setLastname(newValue);
        user.updateLastname(newValue);
    };

    const updateEmail = () => { 
        const emailIsValid = checkEmailExist(emailValue, dispatch);
        if (emailValue !== "" && emailIsValid) {
            
        }
        setEditEmail(false);
    };

    const updateHobspace = async () => {
        // Vérifier si le hobspace est valide
        const isValid = checkHobspaceIsValid(hobspace, dispatch);
        if (!isValid) return;
      
        // Vérifier si le hobspace existe déjà (attendre le résultat si cette fonction est asynchrone)
        const exists = await checkHobsSpaceExists(hobspace, dispatch);
        if (!exists) return;
      
        // Si tout est bon, renommer le document
        try {
            await renameDocument("hobs-space", user.nameSpace ,hobspace);
            saveData({ nameSpace: hobspace });
            user.updateNameSpace(hobspace);
            setHobspace("");
        } catch (error) {
          console.error("Erreur lors du renommage du document : ", error.message);
          dispatch(
            hobsSpaceIsValid({
              isValid: false,
              message: "Erreur lors du renommage du hobspace",
            })
          );
          return;
        }
        console.log("setEditHobspace");
        setEditHobspace(false);
    };      

    const updatePassword = async () => { 
        // Vérifier que le nouveau mot de passe et sa confirmation correspondent
        if (newPassword !== newConfirmPassword) {
            dispatch(passwordIsValid({ isValid: false, message: "Les nouveaux mots de passe ne correspondent pas" }));
            return;
        }
        // Vérifier que le nouveau mot de passe est différent de l'ancien
        if (oldPassword === newPassword) {
            dispatch(passwordIsValid({ isValid: false, message: "Le nouveau mot de passe doit être différent de l'ancien" }));
            return;
        }
        // Vérifier la robustesse du nouveau mot de passe
        const isValid = checkPasswordStrength(newPassword, dispatch);
        if (newPassword === "" || !isValid) {
            dispatch(passwordIsValid({ isValid: false, message: "Le mot de passe ne respecte pas les critères de sécurité" }));
            return;
        }
        
        // Utiliser l'utilisateur Firebase authentifié via la config
        const currentUser = auth.currentUser;
        if (!currentUser) {
            dispatch(passwordIsValid({ isValid: false, message: "Utilisateur non authentifié" }));
            return;
        }
        
        try {
            // Créer les informations d'identification avec l'email et l'ancien mot de passe
            const credential = EmailAuthProvider.credential(currentUser.email, oldPassword);
            // Ré-authentifier l'utilisateur
            await reauthenticateWithCredential(currentUser, credential);
            // Mettre à jour le mot de passe
            await firebaseUpdatePassword(currentUser, newPassword);
            cancelUpadatePassword();
            dispatch(setNotify({type: false, message: 'Le mot de passe de l\'utilisateur a été mis à jour.'}));
        } catch (error) {
            console.error('error update password', error.message);
            dispatch(passwordIsValid({ isValid: false, message: "Le mot de passe actuel ne correspond pas" }));
            return;
        }
        setEditPassword(false);
    };    

    const cancelUpadatePassword = () => {
        setEditPassword(false);
        setOldPassword("");
        setNewPassword("");
        setNewConfirmPassword("");
    };

    const handleCancelDelete = () => {
        setOpenModal(false);
        setOldPassword("");
        setOldPasswordIsVisible(false);
    }

    const handleDeleteUser = async () => {
        try {
            await deleteUserInfo(email, oldPassword, user.nameSpace, dispatch, navigate);
            dispatch(passwordIsValid({type: true, message: ''}));
            setOpenModal(false);
        } catch (error) {
            dispatch(passwordIsValid({type: false, message: "Erreur lors de la suppression du compte"}));
            console.error('error delete user', error.message);
        }
    };

    const saveData = (updatedInfo) => {
        console.log("save data")
        updateUserInfo(email, updatedInfo, dispatch);
    };

    useEffect(() => {
        if (user) {
            setPhoneNumber(user.phoneNumber);
        }
    }, [user]);
 
    return (
      <div className="Home pb-12">
      <MetaTags />
      <TitleTabNavigator title="Accueil" />
      <NavigationHome />
      <div className="w-full flex flex-col items-center justify-center gap-5 py-6" style={{ backgroundColor: "var(--background-secondary)" }}>
        <h1 className="w-4/5 max-425:w-[90%] text-left text-[var(--text-primary)] text-2xl font-semibold">Information du compte</h1>
        <div className="w-4/5 max-425:w-[90%] p-5 bg-[var(--card-background)] border border-[var(--border)] rounded-3xl" style={{ boxShadow: "0px 0 5px rgba(0, 0, 0, 0.1)" }}>
          <h2 className="w-full text-left text-lg text-[var(--text-primary)] font-medium">Paramètres du compte</h2>
          {/* Section Email */}
          <div className="flex flex-col w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
            <h3 className="text-left w-full text-base text-[var(--text-primary)] font-medium">Adresse Email</h3>
            <div className="flex flex-row w-full items-center justify-between gap-3">
              {editEmail ? (
                <InputFieldText
                  value={emailValue}
                  maxLength={256}
                  onChange={(e) => handleChangeEmail(e.target.value)}
                  placeholder={email}
                  title=""
                  type={true}
                  onBlur={() => updateEmail()}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      updateEmail();
                    }
                  }}
                />
              ) : (
                <p className="text-sm lowercase font-light text-[var(--text-primary)] ">{email}</p>
              )}
              {/* Bouton pour éditer l'email (désactivé dans ce code, à activer si nécessaire) */}
              {/* <button onClick={() => setEditEmail(!editEmail)}>
                <PencilLine />
              </button> */}
            </div>
          </div>
          {/* Section Nom & Prénom */}
          <div className="flex flex-col w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
            <h3 className="text-left w-full text-base text-[var(--text-primary)] font-medium">Nom Prénom</h3>
            <div className="flex flex-row w-full items-center justify-between gap-3">
              {editFullName ? (
                <div className="flex flex-row items-center justify-center gap-3">
                  <InputFieldText
                    value={lastname}
                    maxLength={256}
                    onChange={(e) => handleChangeLastname(e.target.value)}
                    placeholder={user ? user.lastname : ''}
                    title=""
                    type={true}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setEditFullName(false);
                        if (lastname !== "") {
                          updateUserProfile(user, dispatch);
                          setLastname("");
                        }
                      }
                    }}
                  />
                  <InputFieldText
                    value={firstname}
                    maxLength={256}
                    onChange={(e) => handleChangeFirstname(e.target.value)}
                    placeholder={user ? user.firstname : ''}
                    title=""
                    type={true}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setEditFullName(false);
                        if (firstname !== "") {
                          updateUserProfile(user, dispatch);
                          setFirstname("");
                        }
                      }
                    }}
                  />
                </div>
              ) : (
                <p className="text-sm font-light text-[var(--text-primary)] ">
                  {user ? `${user.lastname} ${user.firstname}` : ''}
                </p>
              )}
              <button className='cursor-pointer' onClick={() => setEditFullName(!editFullName)}>
                <PencilLine />
              </button>
            </div>
          </div>
          {/* Section Hob's Space */}
          <div className="flex flex-col w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
            <h3 className="text-left w-full text-base text-[var(--text-primary)] font-medium">Hob's Space</h3>
            <div className="flex flex-row w-full items-center justify-between gap-3">
              {editHobspace ? (
                <InputFieldText
                  value={hobspace}
                  maxLength={256}
                  onChange={(e) => setHobspace(e.target.value)}
                  placeholder={user ? user.nameSpace : ''}
                  title=""
                  type={true}
                  onBlur={() => updateHobspace()}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      updateHobspace();
                    }
                  }}
                />
              ) : (
                <p className="text-sm lowercase font-light text-[var(--text-primary)]">
                  {user ? user.nameSpace : ''}
                </p>
              )}
              <button className='cursor-pointer' onClick={() => setEditHobspace(!editHobspace)}>
                <PencilLine />
              </button>
            </div>
            {hobspaceValid?.isValid === false && (
              <div>
                <ErrorMessage message={errorMessage} error={true} />
              </div>
            )}
          </div>
          {/* Section Numéro de Téléphone */}
          <div className="flex flex-col w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
            <h3 className="text-left w-full text-[var(--text-primary)] text-base font-medium">Numéro De Téléphone</h3>
            <div className="inputPhoneMe flex flex-row items-center justify-between gap-3">
              {editPhone ? (
                <InputPhoneNumber
                  title=""
                  value={phoneNumber || ""}
                  onChange={handlePhoneNumberChange}
                  required={false}
                  country={user ? user.country.code : 'us'}
                  onBlur={() => {
                    setEditPhone(false);
                    if (phoneNumber !== "") {
                      saveData({ phoneNumber: formatPhoneNumber(phoneNumber, user?.country.code) });
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      setEditPhone(false);
                      if (phoneNumber !== "") {
                        saveData({ phoneNumber: formatPhoneNumber(phoneNumber, user?.country.code) });
                      }
                    }
                  }}
                />
              ) : (
                <p className="text-sm lowercase font-light text-[var(--text-primary)]">
                  {user ? user.phoneNumber : ''}
                </p>
              )}
              <button className='cursor-pointer' onClick={() => setEditPhone(!editPhone)}>
                <PencilLine />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Section Sécurité : Changement du mot de passe */}
      <div className="w-4/5 max-425:w-[90%] p-5 bg-[var(--card-background)] border border-[var(--border)] rounded-3xl pb-6" style={{ boxShadow: "0px 0 5px rgba(0, 0, 0, 0.1)" }}>
        <h2 className="w-full text-left text-lg font-medium">Sécurité</h2>
        <div className="flex flex-col w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
          <h3 className="text-left w-full text-base text-[var(--text-primary)] font-medium">Mot De Passe</h3>
          <div className="flex flex-row w-full items-center justify-between gap-3">
            {editPassword ? (
              <div className="flex flex-col w-full gap-3">
                <div className="flex flex-col w-full">
                  <div className="flex flex-row items-center justify-between relative">
                    <input
                      className="border caret-[var(--text-primary)] bg-[var(--card-background)] text-[var(--text-primary)] placeholder:text-[var(--text-secondary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]"
                      value={oldPassword}
                      maxLength={256}
                      onChange={(e) => setOldPassword(e.target.value)}
                      type={oldPasswordIsVisible ? "text" : "password"}
                      placeholder="Entrez votre mot de passe actuel"
                    />
                    <button className="absolute cursor-pointer right-3" onClick={() => setOldPasswordIsVisible(!oldPasswordIsVisible)}>
                      {oldPasswordIsVisible ? <Eye size={16} /> : <EyeOff size={16} />}
                    </button>
                  </div>
                  <div className="flex flex-row items-center justify-between relative mt-3">
                    <input
                      className="border caret-[var(--text-primary)] bg-[var(--card-background)] text-[var(--text-primary)] placeholder:text-[var(--text-secondary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]"
                      value={newPassword}
                      maxLength={256}
                      onChange={(e) => setNewPassword(e.target.value)}
                      type={newPasswordIsVisible ? "text" : "password"}
                      placeholder="Entrez votre nouveau mot de passe"
                    />
                    <button className="absolute cursor-pointer right-3" onClick={() => setNewPasswordIsVisible(!newPasswordIsVisible)}>
                      {newPasswordIsVisible ? <Eye size={16} /> : <EyeOff size={16} />}
                    </button>
                  </div>
                  <div className="flex flex-row items-center justify-between relative mt-3">
                    <input
                      className="border caret-[var(--text-primary)] bg-[var(--card-background)] text-[var(--text-primary)] placeholder:text-[var(--text-secondary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]"
                      value={newConfirmPassword}
                      maxLength={256}
                      onChange={(e) => setNewConfirmPassword(e.target.value)}
                      type={newConfirmpasswordIsVisible ? "text" : "password"}
                      placeholder="Confirmez votre nouveau mot de passe"
                    />
                    <button className="absolute cursor-pointer right-3" onClick={() => setNewConfirmPasswordIsVisible(!newConfirmpasswordIsVisible)}>
                      {newConfirmpasswordIsVisible ? <Eye size={16} /> : <EyeOff size={16} />}
                    </button>
                  </div>
                  {passwordValid?.isValid === false && (
                    <div>
                      <ErrorMessage message={errorMessage} error={true} />
                    </div>
                  )}
                  <div className="flex flex-row items-center justify-start w-full mt-1">
                    <Link className="text-sm underline underline-offset-2" href="/forget-password">
                      Mot de passe oublié ?
                    </Link>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end gap-3">
                  <button
                    onClick={cancelUpadatePassword}
                    className="text-[var(--accent)] hover:text-[var(--hover)] cursor-pointer no-underline font-semibold uppercase text-center py-2 px-6 text-base rounded-[50px] border-2 border-[var(--text-primary)]"
                  >
                    Annuler
                  </button>
                  <button
                    onClick={() => updatePassword()}
                    className="text-[var(--text-primary)] hover:border-[var(--hover)] hover:bg-[var(--hover)] cursor-pointer no-underline text-center font-semibold uppercase bg-[var(--accent)] py-2 px-6 text-base rounded-[50px] border-2 border-[var(--accent)]"
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            ) : (
              <p className="text-sm lowercase font-light text-[var(--text-primary)]">************</p>
            )}
            <div className="flex flex-row items-center justify-center gap-2">
              {!editPassword && (
                <button className='cursor-pointer' onClick={() => setEditPassword(!editPassword)}>
                  <PencilLine />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
          <h3 className={`text-left w-full text-base font-medium ${isHovered ? 'text-red-600' : ''}`}>Supprimer le compte</h3>
          <div className="flex flex-row w-full items-center justify-between gap-3">
            <p className={`text-sm lowercase font-light ${isHovered ? 'text-red-600' : 'text-[var(--text-primary)]'}`}>{email}</p>
            <div className="flex flex-row items-center justify-center gap-2">
              <button
                onClick={() => setOpenModal(true)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`cursor-pointer ${isHovered ? 'text-red-600' : ''}`}
              >
                <Trash2 />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <Notify />
      <CustomModal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <div className="flex flex-col p-5 max-425:w-[300px] max-sm:w-[400px] w-[500px] rounded-[20px] bg-[var(--text-primary)] gap-5">
          <h2 className="text-xl font-semibold text-[var(--background-primary)] text-center">Êtes-vous sûr de vouloir supprimer votre compte ?</h2>
          <div className="flex flex-row items-center justify-between relative">
            <input
              className="border caret-[var(--card-background)] bg-[var(--text-primary)] text-[var(--card-background)] placeholder:text-[var(--background-secondary)] p-2 rounded-lg border-[var(--background-secondary)] w-full focus:outline-none focus:border-[var(--background-secondary)]"
              value={oldPassword}
              maxLength={256}
              onChange={(e) => setOldPassword(e.target.value)}
              type={oldPasswordIsVisible ? 'text' : 'password'}
              placeholder="Entrez votre mot de passe"
              required
            />
            <button className="absolute cursor-pointer right-3 text-[var(--background-secondary)] " onClick={() => setOldPasswordIsVisible(!oldPasswordIsVisible)}>
              {oldPasswordIsVisible ? <Eye size={16} /> : <EyeOff size={16} />}
            </button>
          </div>
          {passwordValid?.isValid === false && (
            <div>
              <ErrorMessage message={errorMessage} error={true} />
            </div>
          )}
          <div className="flex flex-row items-center justify-center gap-2">
            <button
              onClick={handleCancelDelete}
              className="text-red-600 no-underline font-semibold uppercase text-center py-2 px-6 text-base rounded-[50px] border-2 border-[var(--background-secondary)]"
            >
              Annuler
            </button>
            <button
              onClick={oldPassword !== "" ? handleDeleteUser : () => {}}
              className="text-white no-underline text-center font-semibold uppercase bg-red-600 py-2 px-6 text-base rounded-[50px] border-2 border-red-600"
              style={{ opacity: oldPassword !== "" ? 1 : 0.3, cursor: oldPassword !== "" ? 'pointer' : 'not-allowed' }}
            >
              Supprimer
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
    );
}

export default Me;
