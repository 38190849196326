import React, { useEffect, useState } from 'react';
import styles from './MusicProfil.module.css';
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../../utils';
import AppleMusic from '../../../assets/AppleMusic-logo-complet.png';
import Deezer from '../../../assets/Deezer-logo-complet.png';
import Spotify from '../../../assets/Spotify-logo-complet.png';
import YoutubeMusic from '../../../assets/YtbMusic-logo-complet.png';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import CustomModal from "../Modal/CustomModal/CustomModal";

function MusicProfil(props) {
    const bgdImage = useSelector(state => state.hobspace.backgroundImage);
    const { hobspace } = useHobspace();
    const images = useSelector((state) => state.hobspace.image);
    const [showModal, setShowModal] = useState(false);
    const [availablePlatforms, setAvailablePlatforms] = useState([]);

    const platforms = ['youtubemusic', 'spotify', 'deezer', 'applemusic'];
    
    useEffect(() => {
        const available = platforms.filter(platform => {
            const link = hobspace.links.find(link => link.name === platform)?.link;
            return link;
        });
        setAvailablePlatforms(available);
    }, [hobspace.links]);

    const getLinkPlateforme = (platform) => {
        return hobspace.links.find(link => link.name === platform)?.link;
    }

    const renderLogo = (platform) => {
        switch (platform) {
            case 'spotify':
                return {logo: Spotify};
            case 'deezer':
                return {logo: Deezer};
            case 'applemusic':
                return {logo: AppleMusic};
            case 'youtubemusic':
                return {logo: YoutubeMusic};
            default:
                return {logo: ""};
        }
    };

    // Handle modal visibility
    const toggleModal = () => setShowModal(!showModal);

    return (
        <div className={styles.MusicProfil} style={bgdImage ? { backgroundColor: hexToRgb(hobspace.backgroundColor) } : { backgroundColor: `rgba(255,255,255,0.15)` }}>
            <img className={styles.imgProfil} src={images} alt='profile' />
            <div className={styles.containerInfoPerso}>
                <p className={`${styles.titleProfil} text-xl max-md:text-lg max-425:text-base max-350:text-sm`} style={{ color: hobspace.colorFont }}>{hobspace.lastname + " " + hobspace.firstname}</p>
                <p className={`${styles.singerProfil} text-lg max-md:text-base max-425:text-sm max-350:text-xs`} style={{ color: hobspace.colorFont }}>{props.description !== "" ? props.description : "Décrivez vous."}</p>
            </div>

            <div className={styles.linkPlateformMusic} style={bgdImage ? { backgroundColor: hexToRgb(hobspace.colorFont, 1) } : { backgroundColor: hobspace.backgroundColor }}>
                {availablePlatforms.slice(0, 2).map((platform, index) => (
                    <>
                    <a key={platform} className={styles.linkImgPlateformMusic} href={getLinkPlateforme(platform)} target='_blank' rel="noreferrer">
                        <div className={`flex items-center justify-center gap-1`}>
                            <img className={`object-contain object-center h-8 max-sm:h-6 w-auto max-350:h-5`} src={renderLogo(platform).logo} alt={`${platform} logo`}/>
                        </div>
                    </a>
                    {index !== availablePlatforms.length - 1 &&
                        <div className={`h-[60%] min-w-[3px] rounded-[50px] mx-[6%] max-425:mx-[4%] max-350:mx-[3%] max-350:min-w-[2px]`} style={bgdImage ? {backgroundColor: hobspace.backgroundColor} : {backgroundColor: hobspace.colorFont}}></div> 
                    }
                    </>
                ))}
                
                {availablePlatforms.length > 2 && (
                    <>
                    <button className={styles.moreButton} onClick={toggleModal} style={bgdImage ? {color: hobspace.backgroundColor} : {color: hobspace.colorFont}}>
                        More
                    </button>
                    </>
                )}
            </div>

            <CustomModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}>
                <div className={`flex items-center flex-col justify-center relative w-full mt-14`}>
                    <div className='relative flex flex-row items-center justify-around w-[500px] max-sm:w-[400px] max-425:w-[300px] h-32 max-sm:h-28 max-425:h-20 gap-5 max-425:gap-3 rounded-t-[10px]' style={{ backgroundColor: hobspace.backgroundColor }}>
                        <img className={`rounded-[10px] object-cover object-center ml-7 mb-16 max-sm:mb-14 max-425:mb-10 max-425:ml-3 max-sm:ml-5 h-[140px] w-[140px] max-sm:w-[120px] max-425:w-[90px] max-425:h-[90px] max-sm:h-[120px]`} src={images || null} alt="music"/>
                        <div className={`text-left mr-5 w-[290px] max-sm:w-[220px] max-425:w-[175px] max-425:mr-3`}>
                            <p className={`text-[20px] capitalize text-left font-semibold overflow-hidden max-sm:text-lg max-425:text-sm w-full m-0 truncate`} style={{ color: hobspace.colorFont }}>
                            {hobspace.lastname + " " + hobspace.firstname}
                            </p>
                            <p className={`capitalize font-medium text-[18px] max-sm:text-base max-425:text-xs mt-[2px] mb-[10px] truncate`} style={{ color: hobspace.colorFont }}>
                            {props.description !== "" ? props.description : "Décrivez vous."}
                            </p>
                        </div>
                    </div>
                    <div className={`flex flex-col w-[500px] max-sm:w-[400px] max-425:w-[300px] items-center justify-center rounded-b-[10px] bottom-[0px] gap-0 px-5 max-425:px-3 max-425:py-2 py-4`} style={{ backgroundColor: hobspace.colorFont }}>
                        {availablePlatforms.length > 1 && availablePlatforms.map((platform, index) => (
                            <div className='w-full' key={platform}>
                                <a className={`flex items-center justify-between flex-row cursor-pointer no-underline`} href={getLinkPlateforme(platform)} target="_blank" rel="noreferrer">
                                    <div className={`flex items-center justify-start gap-2`}>
                                        <img className={`object-contain object-center h-[28px] w-auto max-sm:h-6 max-425:h-[18px]`} src={renderLogo(platform).logo} alt={`${platform} logo`}/>
                                    </div>
                                    <i className="fa-solid fa-arrow-up-right-from-square text-[16px] max-sm:text-sm max-425:text-xs" style={{color: "#000"}}></i>
                                    {/* <p className={`rounded-[20px] no-underline leading-[12px] cursor-pointer border border-black bg-transparent capitalize text-black font-medium text-[12px] px-[8px] py-[3px]`}>Play</p> */}
                                </a>
                                {index !== availablePlatforms.length - 1 &&
                                    <div className={`h-[0.5px] w-[90%] bg-black bg-opacity-10 rounded-[50px] my-[5px]`}></div> 
                                }
                                
                            </div>
                        ))}
                    </div>
                </div>
            </CustomModal>
        </div>
    );
}

export default MusicProfil;
