import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHobsManager } from '../../../utils/contexts/hobsManagerContext';
import "../DashboardPages.css";
import { PencilLine, PaintBucket, Baseline, Image, Trash, SquarePen } from 'lucide-react';
import InputFieldText from '../../../components/InputField/InputFieldText';
import MobilePreview from '../../../components/MobilePreview/MobilePreview';
import ButtonEditImage from '../../../components/ButtonEditImage/ButtonEditImage';
import Banner from '../../../components/Banner/Banner';
import Logo from '../../../components/Logo/Logo';
import Menu from '../../../components/Menu/Menu';
import { countries, links, generateUniqueId, componentsSpace } from '../../../utils';
import ReseauxSociaux from '../../../components/ReseauxSociaux/ReseauxSociaux';
import EmailButton from '../../../components/EmailButton/EmailButton';
import CallButton from '../../../components/CallButton/CallButton';
import { deleteFile, updatePortailManager, savePortailManager } from '../../../services/storage/storageService';
import DragAndDropLinks from '../../../components/DragAndDropLinks/DragAndDropLinks';
import DragAndDropBlocks from '../../../components/DragAndDropBlocks/DragAndDropBlocks';
import CustomModal from '../../../components/Modal/CustomModal/CustomModal';
import AddContent from '../../AddContent/AddContent';
import AddSocial from '../../AddContent/AddSocial';
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import { setPage, setMenu, setEntrepriseBackgroundImage, setEntrepriseBannerImage } from '../../../services/store/reducers/hobsManagerSlice';

const MonEntreprise = () => {
    const { hobsManager } = useHobsManager();
    const dispatch = useDispatch();
    const email = useSelector((state) => state.user.email);
    const bgdImage = useSelector((state) => state.hobsManager.backgroundImage);
    const bannerImage = useSelector((state) => state.hobsManager.bannerImage);
    const images = useSelector((state) => state.hobsManager.images);
    const components = useSelector((state) => state.hobsManager.page);
    const [error, setError] = useState({error: false, message: ""});
    const [mainComponentsInit, setMainComponentsInit] = useState(componentsSpace);
    const [linksInit, setLinksInit] = useState(links);
    const [linkSocialNetwork, setLinkSocialNetwork] = useState([]);
    const [openModalSocial, setOpenModalSocial] = useState(false);
    const [openModalBlocks, setOpenModalBlocks] = useState(false);
    const [editName, setEditName] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editDescription, setEditDescription] = useState(false);
    const [infoEntreprise, setInfoEntreprise] = useState({
        name: "",
        address: "",
        description: ""
    });
      
    const [hobspaceDefault, setHobspaceDefault] = useState({
        firstname: "John",
        lastname: "Doe",
        description: infoEntreprise?.description,
        phoneNumber: "33123456789",
        country: { code: "FR", name: "France" },
        colorFont: "#FFFFFF",
        backgroundColor: "#000000",
        links: [], 
        page: [], 
        contactActive: { email: false, phone: false }
    });
      

    const handleSaveLinks = (typeLink) => {
        const activeLinkNames = new Set(hobspaceDefault?.links.map(link => link.name));

        // Collect new active links to add to hobsManager.links
        const newActiveLinks = linksInit
        .filter(link => link.name === typeLink && !activeLinkNames.has(link.name))
        .map(link => {
            return { ...link, active: true };
        });

        // Append new active links to hobsManager.links without modifying existing order
        hobsManager.updateLink([...hobspaceDefault?.links, ...newActiveLinks]);
        const linksActive = newActiveLinks.map((link, index) => ({
            id: link.name,
            key: index,
            link: link.link,
            active: true,
            icon: link.icon,
            color: link.color
        }));
        setLinkSocialNetwork([...linkSocialNetwork, ...linksActive]);
        setOpenModalSocial(false);
    };

    const removeLinkhobsManager = (name) => {
        const filteredLinks = hobspaceDefault?.links.filter(link => link.name !== name);
    
        const updatedLinks = filteredLinks.map((link, index) => ({
            id: link.name, 
            key: index,     
            url: link.link, 
            active: true,
            icon: link.icon,
            color: link.color
        }));
    
        setLinkSocialNetwork(updatedLinks);
        hobsManager.updateLink(filteredLinks); // Mise à jour des liens de l'utilisateur
    }; 

    const handleReorder = (newOrder) => {
        const newOrderLinks = newOrder.map(link => {
            return linksInit.find(l => l.name === link.id);
        }).filter(link => link !== undefined);
        hobsManager.reorderLink(newOrderLinks);
        const newOrderLinksActive = newOrderLinks.map((link, index) => ({
            id: link.name,
            key: index,
            link: link.link,
            icon: link.icon,
            color: link.color
        }));
        setLinkSocialNetwork(newOrderLinksActive);
    };

    const handleChangeInfo = (field, value) => {
        setInfoEntreprise((prev) => ({ ...prev, [field]: value }));
    };

    // Fonction générique pour mettre à jour le hobspaceDefault
    const handleChangeHobsaceDefault = (field, value) => {
        setHobspaceDefault((prev) => ({ ...prev, [field]: value }));
    };

    // Mise à jour de la couleur de fond
    const handleChangeBackgroundColor = (value) => {
        handleChangeHobsaceDefault("backgroundColor", value);
    };

    // Mise à jour de la couleur du texte
    const handleChangeFontColor = (value) => {
        handleChangeHobsaceDefault("colorFont", value);
    };

    const handleChangeCountry = (e) => {
        const selectedCode = e.target.value;
        const selectedCountry = countries.find(country => country.code === selectedCode);
        if (selectedCountry) {
          handleChangeHobsaceDefault("country", { code: selectedCountry.code, name: selectedCountry.name });
        }
    };      

    // Lors de la mise à jour des infos de l'entreprise,
    // on synchronise aussi la description du hobspaceDefault
    const updateEntrepriseInfo = () => {
        console.log("Informations mises à jour :", infoEntreprise);
        setHobspaceDefault((prev) => ({
        ...prev,
        description: infoEntreprise?.description,
        }));
        setEditName(false);
        setEditAddress(false);
        setEditDescription(false);
        // Vous pouvez ajouter ici un appel à une API ou un dispatch Redux pour sauvegarder les infos
    };

    const deleteBackgroundImage = async () => {
        try {
            await deleteFile(email, 'backgroundImage', 'background-image');
            dispatch(setEntrepriseBackgroundImage(undefined));
        } catch (error) {
            console.error('Error deleting background image:', error.message);
        }
    };

    const deleteBannerImage = async () => {
        try {
            await deleteFile(email, 'banner', 'banner');
            dispatch(setEntrepriseBannerImage(undefined));
        } catch (error) {
            console.error('Error deleting banner image:', error.message);
        }
    };

    const updateLinksWithHobsManagerActive = (linksInit, hobsManangerLinks) => {
        return linksInit.map(link => {
            const activeLink = hobsManangerLinks?.find(active => active.name === link.name);
            if (activeLink) {
                return { ...link, active: true, link: activeLink.link };
            }
            return link;
        });
    };

    const handleSaveComponents = (typeComponent) => {
        const newActiveComponents = mainComponentsInit
            .filter(page => page.name === typeComponent)
            .map(page => {
                return { ...page, id: generateUniqueId(), icon: page.name };
            });
            
        hobsManager.updatePage([...hobspaceDefault?.page, ...newActiveComponents]);
        dispatch(setPage(hobspaceDefault?.page));
        const anchors = hobspaceDefault?.page.map(component => component.title);
        dispatch(setMenu(anchors));
        handleCancelCheckComponents();
        setOpenModalBlocks(false);
    };
    
    const handleCancelCheckComponents = () => {
        const resetComponents = mainComponentsInit.map(component => {
            if (component.active) {
                return { ...component, active: false };
            }
            return component;
        });

        // Update the state with the reset links
        setMainComponentsInit(resetComponents);
        setOpenModalBlocks(false);
    };

    const removeComponentHobsManager = (id) => {
        const filteredComponents = hobspaceDefault?.page.filter(component => component.id !== id);
        hobsManager.updatePage(filteredComponents);
        dispatch(setPage(hobspaceDefault?.page));
        const anchors = hobspaceDefault?.page.map(component => component.title);
        dispatch(setMenu(anchors));
    };

    const handleReorderComponent = (newOrder) => {
        const newOrderComponents = newOrder.map((orderedComponent) => {
            // Trouver le composant correspondant dans mainComponentsInit par son id
            return hobspaceDefault?.page.find((component) => component.id === orderedComponent.id);
        }).filter((component) => component !== undefined); // Filtrer les composants non définis

        // Mettre à jour user.page avec le nouvel ordre des composants
        hobsManager.updatePage(newOrderComponents);
        dispatch(setPage(hobspaceDefault?.page));
        const anchors = hobspaceDefault?.page.map(component => component.title);
        dispatch(setMenu(anchors));
    };

    const createPortailManager = async() => {
        if(infoEntreprise.name === "" || infoEntreprise.address === "" || infoEntreprise.description === ""){
            setError({error: true, message: "Veuillez remplir tous les champs"})
        }
        else {
            console.log(email)
            setIsLoading(true);
            setError({error: false, message: ""})
            await savePortailManager(email, {entreprise: infoEntreprise, hobspaceInit: hobspaceDefault, collaborators: [], subscription: {type: "crew"}});
            setIsLoading(false);
        }
    }

    const updateHobspaceDefault = async() => {
        await updatePortailManager(email, {hobspaceInit: hobspaceDefault}, dispatch);
    }

    useEffect(() => {
        if (hobsManager) {
            console.log(hobsManager, hobsManager.hobspaceInit)
            setHobspaceDefault({...hobsManager?.hobspaceInit, description: hobsManager?.entreprise?.description});
            setInfoEntreprise(hobsManager?.entreprise);
            const linksActive = hobsManager?.hobspaceInit.links?.filter(link => link.active).map((link, index) => ({
                id: link.name,
                key: index,
                active: true,
                link: link.link,
                icon: link.icon,
                color: link.color
            }));
            const pageActive = hobsManager?.hobspaceInit.page?.map((page, index) => ({
                id: page.id,
                key: index,
                name: page.name,
                icon: page.icon,
                color: page.color,
                visibility: page.visibility,
                ...( ["Links", "IndividualVideo", "LinksMusic", "LinksPodcast", "LinksTikTok", "Merch", "Event", "Gallery", "Files"].includes(page.name) && { components: page.components } ),
                title: page.title,
                ...( ["Link", "LinkConcert", "Media"].includes(page.name) && { link: page.link } ),
                ...( ["LinkedinAccount", "MusicAccount", "Text", "Media"].includes(page.name) && { description: page.description } ),
                ...( ["Video", "LinkMusic"].includes(page.name) && { idMedia: page.idMedia } ),
                ...( page.name === "VideoCollection" && { nbVideo: page.nbVideo } ),
                ...( ["Media"].includes(page.name) && { type: page.type } ),
            }))
            dispatch(setPage(pageActive));
            const linksInitActive = updateLinksWithHobsManagerActive(linksInit, hobsManager?.hobspaceInit.links);
            setLinkSocialNetwork(linksActive);
            setLinksInit(linksInitActive);
            const anchors = hobsManager?.hobspaceInit.page?.map(component => component.title);
            dispatch(setMenu(anchors));
        }
    }, [hobsManager]);   

    return (
        <div className="DashboardPages">
        <div className='w-full flex flex-row items-start justify-center p-5 pb-0 gap-5'>
            <div className="w-full flex flex-col items-center justify-center gap-5">
                <h1 className="w-full text-left text-[var(--text-primary)] text-2xl font-semibold">
                    Informations sur l'Entreprise
                </h1>
                <div
                    className="w-full p-5 bg-[var(--card-background)] border border-[var(--border)] rounded-3xl"
                    style={{ boxShadow: "0px 0 5px rgba(0, 0, 0, 0.1)" }}
                >
                    <h2 className="w-full text-left text-lg text-[var(--text-primary)] font-medium">
                    Profil de l'Entreprise
                    </h2>
                    {/* Nom */}
                    <div className="flex flex-row items-end justify-center w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
                        <div className='relative mr-8 w-40'>
                            {images !== undefined ?
                                <img className='w-28 h-28 border border-[var(--text-secondary)] rounded-xl' src={images} alt="" style={{objectFit: "cover"}} />
                                :
                                <div className='w-28 h-28 rounded-xl border border-[var(--text-secondary)] flex items-center overflow-hidden justify-center' style={{backgroundColor: hobspaceDefault?.backgroundColor}}>
                                    <i style={{ color: hobspaceDefault?.colorFont, fontSize: "101px", position: "relative", top: "15px" }} className={"fa-regular fa-user"}></i>
                                </div>
                            }
                            <div className='absolute bottom-[-10px] right-[-10px] '>
                                <ButtonEditImage
                                    manager={true}
                                    title={"Charger une photo de profile"}
                                    folder={"entreprise"}
                                    nameFile={"entreprise-image"}
                                    upload={true}
                                    email={email}
                                    ctaModalClassName={"flex justify-center items-center w-8 h-8 rounded-full border border-solid border-[var(--text-secondary)]"}
                                    ctaModalStyle={{ backgroundColor: "var(--accent)", color: "var(--text-primary)" }}
                                    ctaModalTitle={<SquarePen color="var(--text-primary)" size={16} />}
                                />
                            </div>
                            <div className='absolute top-[-10px] right-[-10px]'>
                                <button
                                    className="w-8 h-8 rounded-full border border-solid border-[var(--text-secondary)] overflow-hidden"
                                    style={{ backgroundColor: 'var(--accent)', color: 'var(--text-parimary)' }}
                                    onClick={() => document.getElementById('countrySelect').classList.toggle('sr-only')}
                                >
                                    <div className="flex justify-center">
                                        <img
                                            className="w-6 h-4 rounded-[2px] object-cover object-center"
                                            alt={hobspaceDefault?.country?.name}
                                            src={"http://purecatamphetamine.github.io/country-flag-icons/3x2/" + hobspaceDefault?.country?.code + ".svg"}
                                        />
                                    </div>
                                </button>
                                <select
                                    id="countrySelect"
                                    value={hobspaceDefault?.country?.code}
                                    onChange={handleChangeCountry}
                                    className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
                                >
                                    <option value="" disabled>Select a country</option>
                                    {countries.map((countryOption) => (
                                        <option key={countryOption.code} value={countryOption.code}>
                                            {countryOption.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='flex flex-col w-full gap-[6px]'>
                            <h3 className="text-left w-full text-base text-[var(--text-primary)] font-medium">
                                Nom
                            </h3>
                            <div className="flex flex-row w-full items-center justify-between gap-3">
                                {editName ? (
                                <InputFieldText
                                    value={infoEntreprise?.name}
                                    maxLength={256}
                                    onChange={(e) => handleChangeInfo("name", e.target.value)}
                                    placeholder="Hob's Space"
                                    title=""
                                    type={true}
                                    onBlur={updateEntrepriseInfo}
                                    onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        updateEntrepriseInfo();
                                    }
                                    }}
                                />
                                ) : (
                                <p className="text-sm font-light text-[var(--text-primary)]">
                                    {infoEntreprise?.name}
                                </p>
                                )}
                                <button onClick={() => setEditName(!editName)}>
                                <PencilLine />
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Adresse */}
                    <div className="flex flex-col w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
                    <h3 className="text-left w-full text-base text-[var(--text-primary)] font-medium">
                        Adresse
                    </h3>
                    <div className="flex flex-row w-full items-center justify-between gap-3">
                        {editAddress ? (
                        <InputFieldText
                            value={infoEntreprise?.address}
                            maxLength={512}
                            onChange={(e) => handleChangeInfo("address", e.target.value)}
                            placeholder="42 Rue de l'Innovation, 75010 Paris, France"
                            title=""
                            type={true}
                            onBlur={updateEntrepriseInfo}
                            onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                updateEntrepriseInfo();
                            }
                            }}
                        />
                        ) : (
                        <p className="text-sm font-light text-[var(--text-primary)]">
                            {infoEntreprise?.address}
                        </p>
                        )}
                        <button onClick={() => setEditAddress(!editAddress)}>
                        <PencilLine />
                        </button>
                    </div>
                    </div>
                    {/* Description */}
                    <div className="flex flex-col w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
                    <h3 className="text-left w-full text-base text-[var(--text-primary)] font-medium">
                        Description
                    </h3>
                    <div className="flex flex-row w-full items-center justify-between gap-3">
                        {editDescription ? (
                        <InputFieldText
                            value={infoEntreprise?.description}
                            maxLength={512}
                            onChange={(e) => handleChangeInfo("description", e.target.value)}
                            placeholder="Entreprise innovante dans les solutions numériques."
                            title=""
                            type={true}
                            onBlur={updateEntrepriseInfo}
                            onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                updateEntrepriseInfo();
                            }
                            }}
                        />
                        ) : (
                        <p className="text-sm font-light text-[var(--text-primary)]">
                            {infoEntreprise?.description}
                        </p>
                        )}
                        <button onClick={() => setEditDescription(!editDescription)}>
                        <PencilLine />
                        </button>
                    </div>
                    </div>
                    {error.error && <ErrorMessage error={error.error} message={error.message} />}
                    <div className='flex items-center justify-end w-full mt-6'>
                        <button
                        type='button'
                        onClick={createPortailManager}
                        className="text-[var(--text-primary)] hover:bg-[var(--hover)] hover:border-[var(--hover)] no-underline text-center font-semibold uppercase bg-[var(--accent)] cursor-pointer py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 border-[var(--accent)]"
                        >
                        {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Enregistrer"}
                        </button>
                    </div>
                </div>
                        
                <h1 className="w-full text-left text-[var(--text-primary)] text-2xl font-semibold">
                    Hob's Space des collaborateurs
                </h1>
                {hobsManager ? (
                    <div
                        className="w-full relative p-5 bg-[var(--card-background)] border border-[var(--border)] rounded-3xl"
                        style={{ boxShadow: "0px 0 5px rgba(0, 0, 0, 0.1)" }}
                    >
                        <h2 className="w-full text-left text-lg text-[var(--text-primary)] font-medium">
                        Configuration initial
                        </h2>
                        {/* Color, Images */}
                        <div className="flex flex-col w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
                            <h3 className="text-left w-full text-base text-[var(--text-primary)] mb-2 font-medium">
                                Style du profil
                            </h3>
                            <div className="w-full flex flex-row items-start justify-start gap-2">
                                <button
                                className="p-2 border border-solid border-[var(--text-secondary)] rounded-full"
                                style={{
                                    backgroundColor: hobspaceDefault?.backgroundColor,
                                    color: hobspaceDefault?.colorFont,
                                }}
                                onClick={() => document.getElementById('colorInput').click()}
                                >
                                <PaintBucket color={hobspaceDefault?.colorFont} size={20} />
                                </button>
                                <input
                                id="colorInput"
                                type="color"
                                className="sr-only"
                                onChange={(e) => handleChangeBackgroundColor(e.target.value)}
                                />
                                <button
                                className="p-2 border border-solid border-[var(--text-secondary)] rounded-full"
                                style={{
                                    backgroundColor: hobspaceDefault?.colorFont,
                                    color: hobspaceDefault?.backgroundColor,
                                }}
                                onClick={() => document.getElementById('colorInputFont').click()}
                                >
                                <Baseline color={hobspaceDefault?.backgroundColor} size={20} />
                                </button>
                                <input
                                id="colorInputFont"
                                type="color"
                                className="sr-only"
                                onChange={(e) => handleChangeFontColor(e.target.value)}
                                />
                                <div className="relative flex items-center justify-center">
                                <ButtonEditImage
                                    manager={true}
                                    title={"Charger une photo de fond"}
                                    folder={"backgroundImage"}
                                    nameFile={"background-image"}
                                    style={{
                                    height: "40px",
                                    width: "40px",
                                    padding: "0px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    }}
                                    email={email}
                                    ctaModalClassName={
                                    "p-2 border border-solid border-[var(--text-secondary)] rounded-full"
                                    }
                                    ctaModalStyle={{
                                    backgroundColor: hobspaceDefault?.backgroundColor,
                                    color: hobspaceDefault?.colorFont,
                                    }}
                                    upload={true}
                                    ctaModalTitle={
                                    bgdImage === undefined ? (
                                        <Image color={hobspaceDefault?.colorFont} size={20} />
                                    ) : (
                                        <img
                                        src={bgdImage}
                                        className="w-full h-full object-cover object-center rounded-full"
                                        alt={"image de fond"}
                                        />
                                    )
                                    }
                                />
                                {bgdImage !== undefined && (
                                    <button
                                    className="bg-red-600 absolute top-[-5px] right-[-5px] rounded-full w-5 h-5 flex items-center justify-center"
                                    onClick={() => deleteBackgroundImage()}
                                    >
                                    <Trash color={"#FFF"} size={14} />
                                    </button>
                                )}
                                </div>
                                <div className="relative flex items-center justify-center">
                                <ButtonEditImage
                                    manager={true}
                                    title={"Charger une bannière"}
                                    folder={"banner"}
                                    nameFile={"banner"}
                                    style={{
                                    height: "40px",
                                    width: "80px",
                                    padding: "0px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "10px",
                                    overflow: "hidden",
                                    }}
                                    email={email}
                                    ctaModalClassName={"p-2 border border-solid border-[var(--text-secondary)]"}
                                    ctaModalStyle={{
                                    backgroundColor: hobspaceDefault?.backgroundColor,
                                    color: hobspaceDefault?.colorFont,
                                    }}
                                    upload={true}
                                    ctaModalTitle={
                                    bannerImage === undefined ? (
                                        <p
                                        style={{
                                            color: hobspaceDefault?.colorFont,
                                            fontSize: 12,
                                            fontWeight: 900,
                                        }}
                                        >
                                        Bannière
                                        </p>
                                    ) : (
                                        <img
                                        src={bannerImage}
                                        className="w-full h-full object-cover object-center"
                                        alt={"image de bannière"}
                                        />
                                    )
                                    }
                                />
                                {bannerImage !== undefined && (
                                    <button
                                    className="bg-red-600 absolute top-[-5px] right-[-5px] rounded-full w-5 h-5 flex items-center justify-center"
                                    onClick={() => deleteBannerImage()}
                                    >
                                    <Trash color={"#FFF"} size={14} />
                                    </button>
                                )}
                                </div>
                            </div>
                        </div>
                        {/* Adresse */}
                        <div className="flex flex-col w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
                            <h3 className="text-left w-full text-base text-[var(--text-primary)] mb-2 font-medium">
                                Coordonnées
                            </h3>
                            <div className="flex flex-col w-full items-center justify-between gap-1">
                                <div className='w-full flex flex-row justify-between items-center'>
                                    <p className='text-[var(--text-primary)] text-sm '>Afficher l'email sur le profil ?</p>
                                    <label className="switch">
                                        <input type="checkbox" checked={hobspaceDefault?.contactActive?.email} onChange={() => handleChangeHobsaceDefault("contactActive",{email: !hobspaceDefault?.contactActive?.email, phone: hobspaceDefault?.contactActive?.phone})} />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                <div className='w-full flex flex-row justify-between items-center'>
                                    <p className='text-[var(--text-primary)] text-sm '>Afficher le numéro de téléphone sur le profil ?</p>
                                    <label className="switch">
                                        <input type="checkbox" checked={hobspaceDefault?.contactActive?.phone} onChange={() => handleChangeHobsaceDefault("contactActive",{phone: !hobspaceDefault?.contactActive?.phone, email: hobspaceDefault?.contactActive?.email})} />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* Réseaux sociaux */}
                        <div className="flex flex-col relative w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
                            <h3 className="text-left w-full text-base text-[var(--text-primary)] font-medium">
                                Réseaux sociaux
                            </h3>
                            <div className='flex flex-col w-full items-center justify-between gap-1'>
                                <button className='flex hover:bg-[var(--hover)] absolute top-6 right-0 justify-center items-center w-7 h-7 bg-[var(--accent)] rounded-lg ' onClick={() => setOpenModalSocial(true)}>
                                    <div className='iconButton'>
                                        <i style={{ color: "var(--text-primary)" }} className={"fa-solid fa-plus"}></i>
                                    </div>
                                </button>
                                <DragAndDropLinks droppableId={"SocialListManager"} initialLinks={linkSocialNetwork} deleteLink={removeLinkhobsManager} onReorder={handleReorder} />
                            </div>
                        </div>
                        {/* Composants */}
                        <div className="flex flex-col relative w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
                            <h3 className="text-left w-full text-base text-[var(--text-primary)] font-medium">
                                Contenu
                            </h3>
                            <div className='flex flex-col w-full items-center justify-between gap-1'>
                                <button className='flex hover:bg-[var(--hover)] absolute top-6 right-0 justify-center items-center w-7 h-7 bg-[var(--accent)] rounded-lg ' onClick={() => setOpenModalBlocks(true)}>
                                    <div className='iconButton'>
                                        <i style={{ color: "var(--text-primary)" }} className={"fa-solid fa-plus"}></i>
                                    </div>
                                </button>
                                <DragAndDropBlocks droppableId={"BlockList"} initialContainers={components} deleteBlock={removeComponentHobsManager} onReorder={handleReorderComponent} />
                            </div>
                        </div>
                        <div className='flex items-center justify-end w-full mt-6'>
                            <button
                            type='button'
                            onClick={updateHobspaceDefault}
                            className="text-[var(--text-primary)] hover:bg-[var(--hover)] hover:border-[var(--hover)] no-underline text-center font-semibold uppercase bg-[var(--accent)] cursor-pointer py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 border-[var(--accent)]"
                            >
                            Enregistrer
                            </button>
                        </div>
                    </div>
                ):(
                    <div
                    className="w-full relative p-5 bg-[var(--card-background)] border border-[var(--border)] rounded-3xl"
                    style={{ boxShadow: "0px 0 5px rgba(0, 0, 0, 0.1)" }}
                    >
                        <h2 className="w-full text-center text-lg gap-2 flex flex-row items-center justify-center text-[var(--accent)] font-medium">
                            <i class="fa-solid fa-triangle-exclamation"></i>
                            Créez le profil de votre entreprise
                            <i class="fa-solid fa-triangle-exclamation"></i>
                        </h2>
                        {/* Adresse */}
                        <div className="flex flex-col w-full py-6 gap-[6px] border-b border-[var(--text-secondary)]">
                            <p className="text-center w-full text-base text-[var(--text-primary)] mb-2 font-medium">
                            Veuillez renseigner les informations de votre entreprise avant de pouvoir commencer à configurer l'Hob's Space de vos collaborateurs.
                            </p>
                        </div>
                    </div>
                )}
                
            </div>
            <div className="ContainerPreviewHobsSpace" style={{borderRadius: "24px"}}>
                <div className="containerHeaderPreviewHobsSpace">
                <p className="titleHeaderPreviewHobsSpace">Aperçu Hob's Space initial des collaborateurs</p>
                </div>
                <div className="lineHobsSpace"></div>
                <MobilePreview bgd={hobspaceDefault?.backgroundColor} img={bgdImage}>
                {hobspaceDefault && (
                    <>
                    <Banner image={bannerImage} />
                    <Logo user={hobspaceDefault} image={undefined} />
                    {hobspaceDefault?.contactActive?.email && (
                        <EmailButton
                        email={"exemple@hobs.space"}
                        subject="Mon Hob's Space"
                        body="Bonjour, venez jeter un oeil à mon Hob's Space !"
                        />
                    )}
                    {hobspaceDefault?.contactActive?.phone && <CallButton phoneNumber={hobspaceDefault?.phoneNumber} />}
                    <ReseauxSociaux socialNetworks={hobspaceDefault?.links} />
                    <Menu />
                    <div className="lineSeparation"></div>
                    </>
                )}
                </MobilePreview>
            </div>
        </div>
        <CustomModal isOpen={openModalSocial} onClose={() => setOpenModalSocial(false)} width="calc(100% - 230px)" height="100vh" margin="0px 0 0 230px" bgOverlay="transparent">
            <AddSocial setOpenModal={() => setOpenModalSocial(false)} addComponent={handleSaveLinks} />
        </CustomModal>
        <CustomModal isOpen={openModalBlocks} onClose={() => setOpenModalBlocks(false)} width="calc(100% - 230px)" height="100vh" margin="0px 0 0 230px" bgOverlay="transparent" >
            <AddContent user={hobsManager} linkSocials={linkSocialNetwork} setOpenModal={() => setOpenModalBlocks(false)} addComponent={handleSaveComponents} />    
        </CustomModal>
        </div>
    );
};

export default MonEntreprise;
