import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import LogoBlack from "../../assets/logo-black.png";
import Google from "../../assets/google.png";
import Apple from "../../assets/apple.png";
import '@fortawesome/fontawesome-free/css/all.css';
import { loginWithEmailOrHobsSpace } from '../../services/auth/authService';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import { emailIsValid, hobsSpaceIsValid, passwordIsValid } from '../../services/store/reducers/authSlice';
import MetaTags from '../../components/MetaTags/MetaTags';

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const passwordValid = useSelector((state) => state.auth.passwordIsValid);
    const emailValid = useSelector((state) => state.auth.emailIsValid);
    const errorMessage = useSelector((state) => state.auth.errorMessage);
    const errorHobs = useSelector((state) => state.auth.hobsSpaceIsValid);
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme') || 'dark');
    useEffect(() => {
      // Observer pour détecter les changements de l'attribut 'data-theme'
      const observer = new MutationObserver((mutationsList) => {
          mutationsList.forEach(mutation => {
              if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
                  const currentTheme = document.documentElement.getAttribute('data-theme') || 'dark';
                  setTheme(currentTheme);
              }
          });
      });

      observer.observe(document.documentElement, { attributes: true });
      
      return () => observer.disconnect();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        loginWithEmailOrHobsSpace(email, password, dispatch, navigate);
    };

    useEffect(() => {
       dispatch(passwordIsValid({isValid: null, message: ""}));
       dispatch(emailIsValid({isValid: null, message: ""}));
       dispatch(hobsSpaceIsValid({isValid: null, message: ""}));
    }, []);
 
    return (
        <>
    <MetaTags />
    <TitleTabNavigator title="Connexion" />
    <div className="flex items-center flex-col justify-start bg-[var(--background-secondary))] min-h-screen h-full gap-5">
      <div className="w-full min-h-[70px] bg-[var(--background-primary)] flex items-center justify-center ">
        <Link to="/" className="">
            <img className="h-[40px] object-contain object-center " src={theme === "light" ? LogoBlack : Logo} alt="logo hobs space retour page d'accueil" />
        </Link>
      </div>
      <div className='flex items-center justify-center w-full pb-20 max-[425px]:pb-10' style={{height: "calc(100vh - 90px)"}}>
      <div className="flex flex-col w-[70%] max-[425px]:min-w-[350px] max-[360px]:min-w-[95%] min-w-[420px] max-w-[700px] items-center justify-center bg-[var(--card-background)] p-5 border rounded-2xl border-[var(--border)]">
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center gap-3">
            <h1 className="text-center text-3xl max-[425px]:text-2xl">Bienvenue</h1>
            <p className="text-center px-4 py-2 rounded-full bg-[var(--accent)] max-[360px]:text-xs max-[425px]:text-sm">Connectez-vous à votre Hob's Space</p>
          </div>
          <div className="flex flex-col items-center justify-center mt-5 max-[360px]:mt-3">
            <form onSubmit={handleSubmit} className="flex flex-col items-center mt-3 justify-center gap-5 w-[90%]">
              <div className="w-full">
                <input
                  className="border caret-[var(--text-primary)] bg-[var(--card-background)] text-[var(--text-primary)] placeholder:text-[var(--text-secondary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]"
                  maxLength={256}
                  type="text"
                  placeholder="Nom d'utilisateur / Hob's Space"
                  id="username"
                  name="username"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {emailValid === false && (
                  <ErrorMessage message={errorMessage} error={!emailValid} />
                )}
              </div>
              <div className="w-full relative flex flex-col items-center justify-center gap-1">
              <div className="w-full relative flex items-center justify-center">
                <input
                  className="border relative bg-[var(--card-background)] caret-[var(--text-primary)] text-[var(--text-primary)] placeholder:text-[var(--text-secondary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]"
                  maxLength={256}
                  type={passwordIsVisible ? 'text' : 'password'}
                  placeholder="Mot de passe"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="absolute right-3 cursor-pointer"
                  onClick={() => setPasswordIsVisible(!passwordIsVisible)}
                >
                  {passwordIsVisible ? (
                    <i className="fa-solid fa-eye"></i>
                  ) : (
                    <i className="fa-solid fa-eye-slash"></i>
                  )}
                </button>
                {passwordValid === false && (
                  <ErrorMessage message={errorMessage} error={!passwordValid} />
                )}
                
              </div>
                <div className="w-full flex items-center justify-start">
                    <Link to="/forget-password" className='text-[var(--text-primary)] text-sm underline underline-offset-2 '>Mot de passe oublié ?</Link>
                </div>
              </div>
              
                <button
                type="submit"
                className="text-[var(--text-primary)] hover:bg-[var(--hover)] hover:border-[var(--hover)] no-underline text-center font-semibold uppercase bg-[var(--accent)] cursor-pointer py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 border-[#ff8500]"
                >
                Se connecter
                </button>
            </form>
            <div className="w-1/2 bg-[var(--text-secondary)] h-[1px] mx-auto my-5"></div>
            <div className="flex items-center justify-center">
              <p className='max-[425px]:text-sm text-center'>
                Vous n'avez pas de compte ?{' '}
                <Link to="/sign-up" className="underline underline-offset-2 text-nowrap hover:text-[var(--hover)]">
                  Inscrivez-vous
                </Link>
              </p>
            </div>
            <div className="text-[var(--text-primary)] w-[90%] mt-8 text-center text-xs">
              <p>
                Ce site est protégé par reCAPTCHA et les{' '}
                <Link to="" className="underline underline-offset-2 hover:text-[var(--hover)]">
                  règles de confidentialité
                </Link>{' '}
                et les{' '}
                <Link to="" className="underline underline-offset-2 hover:text-[var(--hover)]">
                  conditions d'utilisation de Google
                </Link>{' '}
                s'appliquent.
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    </>
    );
}

export default Login;