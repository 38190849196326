import React, { useState } from 'react';
import './ButtonEditImage.css';
import '@fortawesome/fontawesome-free/css/all.css';
import CustomModal from '../Modal/CustomModal/CustomModal';
import { useDispatch } from "react-redux";
import { uploadImage } from '../../services/storage/storageService';
import { setImages, setBackgroundImage, setBannerImage } from '../../services/store/reducers/userSlice';
import { setEntrepriseImages, setEntrepriseBackgroundImage, setEntrepriseBannerImage } from '../../services/store/reducers/hobsManagerSlice';

function ButtonEditImage(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(undefined);
    const [imageSrc, setImageSrc] = useState(undefined);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false); 

    const handleButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setFile(undefined);
        setImageSrc(undefined);
        setError(false);
        setIsLoading(false);
    };

    const handleFileSelected = (files) => {
        const selectedFile = files[0];

        if (validateFile(selectedFile)) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
                setFile(selectedFile);
            };
            reader.readAsDataURL(selectedFile);
            setError(false);
        } else {
            setError(true);
        }
    };

    const validateFile = (file) => {
        return file.type.startsWith('image/') && file.size <= 16 * 1024 * 1024; // 16MB
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        handleFileSelected(files);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleModalClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = (event) => handleFileSelected(event.target.files);
        input.click();
    };

    const uploadFile = async () => {
        if (file) {
            setIsLoading(true);
            try {
                if(props.folder === "profile"){
                    dispatch(setImages(URL.createObjectURL(file)));
                }
                else if(props.folder === "banner") {
                    dispatch(setBannerImage(URL.createObjectURL(file)));
                }
                else {
                    dispatch(setBackgroundImage(URL.createObjectURL(file)));
                }
                if(props.upload === true) {
                    uploadImage(file, props.email, props.folder, props.nameFile);
                }
                handleCloseModal();
            } catch (error) {
                console.error('Error uploading image:', error);
            } finally {
                setIsLoading(false); // Désactiver le loader
            }
        } else {
            setError(true);
        }
    };

    const uploadFileEntreprise = async () => {
        if (file) {
            setIsLoading(true);
            try {
                if(props.nameFile === "entreprise-image"){
                    dispatch(setEntrepriseImages(URL.createObjectURL(file)));
                }
                else if(props.nameFile === "entreprise-background-image") {
                    dispatch(setEntrepriseBannerImage(URL.createObjectURL(file)));
                }
                else {
                    dispatch(setEntrepriseBackgroundImage(URL.createObjectURL(file)));
                }
                if(props.upload === true) {
                    uploadImage(file, props.email, props.folder, props.nameFile);
                }
                handleCloseModal();
            } catch (error) {
                console.error('Error uploading image:', error);
            } finally {
                setIsLoading(false); // Désactiver le loader
            }
        } else {
            setError(true);
        }
    };
    
    return (
        <>
            <button
                className={props.ctaModalClassName || 'ButtonEditImage'}
                style={{
                    ...props.ctaModalStyle,
                    color: `rgba(${props.color}, 1)`,
                    borderColor: `rgba(${props.color}, 1)`,
                    ...props.style,
                }}
                onClick={handleButtonClick}
            >
                {props.ctaModalTitle ? props.ctaModalTitle : "Sélectionner une image"}
            </button>
            <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
                <div className='containerSelectImgProfil'>
                    <p className='titleModalSettings'>{props.title}</p>
                    <div
                        className='dropzone'
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={handleModalClick}
                    >
                        {imageSrc !== undefined ?
                            <img className='imgUploadProfil' src={imageSrc} alt="Aperçu" />
                            :
                            <div className='iconButtonImgProfil'>
                                <i className={"fa-solid fa-image"}></i>
                            </div>
                        }

                        <p style={{ color: error ? "red" : "black" }}>
                            {error ? "Fichier invalide ou trop volumineux." : "Déposez une image ici ou cliquez pour sélectionner un fichier"}
                        </p>
                    </div>
                    <div className='containerButtonImgProfil'>
                        <button className='text-red-600 no-underline font-semibold uppercase text-center py-2 px-6 text-base rounded-[50px] hover:border-red-600 hover:bg-red-600 hover:text-[var(--text-primary)] border-2 border-[var(--text-primary)] bg-[var(--text-primary)] cursor-pointer' onClick={handleCloseModal}>Annuler</button>
                        <button className='text-[var(--text-primary)] no-underline text-center font-semibold uppercase bg-[var(--accent)] hover:bg-[var(--hover)] hover:border-[var(--hover)] cursor-pointer py-2 px-6 text-base rounded-[50px] border-2 border-[var(--accent)]' onClick={file !== undefined ? props.manager ? uploadFileEntreprise : uploadFile : () => setError(true)}>{isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Enregistrer"}</button>
                    </div>
                </div>
            </CustomModal>
        </>
    );
}

export default ButtonEditImage;

