import React from 'react'
import styles from './MusicAccount.module.css';
import Title from '../Title/Title';
import MusicProfil from '../MusicProfil/MusicProfil';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

function MusicAccount(props) {
  const {hobspace} = useHobspace();

    return (
      <div className={styles.MusicAccount}>
        {props.title &&
          <Title id={props.ancre} color={hobspace.colorFont} title={props.title} marginBottom={true} />
        }
        <MusicProfil description={props.description} />
      </div>
    );
}

export default MusicAccount;