import axios from 'axios';
import { normalizeSearchTerm } from '../../utils';
const baseUrl = process.env.REACT_APP_API_URL;

const APPLE_MUSIC_API_URL = 'https://api.music.apple.com/v1';
let developerToken = process.env.REACT_APP_APPLE_DEVELOPER_TOKEN; // le changer tous les 6 mois 31/01/2025 => 30/07/2025 

// Fonction générique pour appeler l'API Apple Music
const fetchFromAppleMusic = async (endpoint, userToken = null) => {
    try {
        const token = developerToken; // Assure que le token est disponible
        if (!token) {
            throw new Error('Impossible de récupérer le token Apple Music.');
        }

        const headers = {
            Authorization: `Bearer ${token}`, // Inclure le token développeur
        };

        if (userToken) {
            headers['Music-User-Token'] = userToken; // Inclure le token utilisateur si nécessaire
        }

        const response = await axios.get(`${APPLE_MUSIC_API_URL}/${endpoint}`, { headers });
        return response.data;
    } catch (error) {
        console.error(`Erreur lors de l'appel à l'API Apple Music (${endpoint}):`, error);
        return null;
    }
};

// Fonctions pour les fonctionnalités spécifiques

export const searchAppleMusicTrack = async (query, limit = 5) => {
    const token = developerToken;
    const response = await fetch(
        `https://api.music.apple.com/v1/catalog/us/search?term=${encodeURIComponent(query)}&types=songs&limit=${limit}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    const data = await response.json();
    // Normalisation des résultats
    return data.results.songs.data.map(song => ({
        title: song.attributes.name,
        artists: [song.attributes.artistName],
        image: song.attributes.artwork?.url.replace('{w}x{h}', '200x200') || '', // URL adaptée
        link: song.attributes.url,
        id: song.id,
        preview: song.attributes.previews[0]?.url || null, // Lien de prévisualisation
        platform: 'applemusic',
    }));
};

export const getAppleMusicTrackById = async (id) => {
    const token = developerToken; // Fonction pour obtenir le token Apple Music
    const response = await fetch(
        `https://api.music.apple.com/v1/catalog/us/songs/${id}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    const data = await response.json();

    const song = data.data[0]?.attributes;

    return {
        title: song.name,
        artists: [song.artistName],
        image: song.artwork?.url.replace('{w}x{h}', '200x200') || '',
        link: song.url,
        preview: song.previews[0]?.url || null, // Pas de prévisualisation disponible
        platform: 'applemusic',
    };
};

export const getAppleMusicArtistInfo = async (artistId) => {
    return fetchFromAppleMusic(`catalog/us/artists/${artistId}`);
};

export const getAppleMusicTopTracks = async (artistId, limit = 10) => {
    return fetchFromAppleMusic(`catalog/us/artists/${artistId}/top-songs?limit=${limit}`);
};

export const getAppleMusicAlbums = async (artistId, limit = 10) => {
    return fetchFromAppleMusic(`catalog/us/artists/${artistId}/albums?limit=${limit}`);
};

const fetchFromItunes = async (endpoint) => {
    try {
        // Assurez-vous que `endpoint` ne commence pas par `/api/deezer`
        const url = `${baseUrl}${endpoint}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(`Erreur lors de l'appel à l'API Itunes (${endpoint}):`, error.message);
        throw new Error('Erreur lors de la récupération des données Itunes.');
    }
};

export const searchAppleMusicPodcasts = async (searchTerm, limit = 5) => {
    try {
        // Séparer le terme de recherche en deux parties : le podcast et l'épisode (séparés par une virgule)
        const [podcastSearchTerm, episodeSearchTerm] = searchTerm.split(',').map(str => str.trim());
        console.log(searchTerm, "------", episodeSearchTerm);

        // Normaliser le terme de recherche pour le podcast
        const normalizedPodcastSearchTerm = normalizeSearchTerm(podcastSearchTerm);
        console.log("Normalized Podcast Search Term:", normalizedPodcastSearchTerm);

        // Rechercher d'abord le podcast avec le terme de recherche
        const podcastResponse = await fetchFromItunes(`/api/applemusic/search/podcast?q=${encodeURIComponent(normalizedPodcastSearchTerm)}&limit=${limit}`);
        const podcastData = podcastResponse;
        console.log(podcastData);

        if (podcastData.resultCount > 0) {
            const podcast = podcastData.results[0];
            const podcastId = podcast.collectionId; // Utiliser l'ID du podcast trouvé
            const episodes = await getPodcastEpisodesById(podcastId, podcast.trackCount, podcast.artistName);
            console.log(episodes);

            if (episodeSearchTerm) {
                // Normaliser le terme de recherche pour l'épisode
                const normalizedEpisodeSearchTerm = normalizeSearchTerm(episodeSearchTerm);

                // Filtrer les épisodes par le terme de recherche
                const filteredEpisodes = episodes.filter(episode => {
                    // Normaliser le titre de l'épisode
                    const normalizedEpisodeTitle = normalizeSearchTerm(episode.title);
                    // Vérifier si le terme de recherche est inclus dans le titre normalisé de l'épisode
                    return normalizedEpisodeTitle.includes(normalizedEpisodeSearchTerm);
                });

                console.log(filteredEpisodes);

                // Trier les épisodes en fonction de la pertinence de la correspondance
                const sortedEpisodes = filteredEpisodes.sort((a, b) => {
                    const aIndex = a.title.toLowerCase().indexOf(normalizedEpisodeSearchTerm.toLowerCase());
                    const bIndex = b.title.toLowerCase().indexOf(normalizedEpisodeSearchTerm.toLowerCase());
                    return bIndex - aIndex;  // Trier par la pertinence de la position de la correspondance
                });

                console.log(sortedEpisodes);

                // Retourner les épisodes filtrés et triés
                return sortedEpisodes.slice(0, limit);
            }

            console.log(episodes);
            // Si aucun titre d'épisode n'est donné, retourner les épisodes les plus récents
            return episodes;
        }

        return []; // Si aucun podcast n'est trouvé
    } catch (error) {
        console.error('Failed to fetch podcasts or episodes:', error);
        return [];
    }
};

// Fonction pour récupérer les épisodes d'un podcast par son ID
const getPodcastEpisodesById = async (podcastId, limit, artist) => {
    try {
        const response = await fetchFromItunes(`/api/applemusic/episode?q=${podcastId}&limit=${limit}`);
        const data = response;
        console.log(podcastId,data)
        if (data.resultCount > 0) {
            const episodes = data.results.slice(1).map(item => {
                return {
                    title: item.trackName,
                    image: item.artworkUrl160 || '',
                    link: item.trackViewUrl,
                    platform: 'applemusic',
                    preview: item.previewUrl,
                    duration: item.trackTimeMillis,
                    id: [item.collectionId ,item.trackId],
                    releaseDate: item.releaseDate,
                    podcastTitle: item.collectionName, // Nom du podcast
                    podcastCreator: artist,
                };
            });

            // Trier les épisodes par date de publication, du plus récent au plus ancien
            const sortedEpisodes = episodes.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));

            // Limiter le nombre d'épisodes à `limit`
            return sortedEpisodes;
        }

        return []; // Retourner un tableau vide si aucun épisode n'est trouvé
    } catch (error) {
        console.error('Failed to fetch episodes:', error);
        return [];
    }
};

export const getAppleMusicPodcastEpisodeById = async (id) => {
    try {
        // Effectuer la requête vers l'API iTunes Lookup pour récupérer les épisodes du podcast
        const response = await fetchFromItunes(`/api/applemusic/episode?q=${id[0]}`);
        const responsePodcast = await fetchFromItunes(`/api/applemusic/podcast?q=${id[0]}`);
        // Récupérer les données JSON
        const data = response;
        const podcast = responsePodcast;
        // Vérifier si des épisodes ont été trouvés
        if (data.resultCount > 0) {
            // Filtrer les épisodes pour trouver celui qui correspond à l'ID de l'épisode
            const episode = data.results.slice(1).find(item => item.trackId === id[1]); // Ignorer le premier objet (détails du podcast)

            if (episode) {
                // Retourner les informations de l'épisode
                return {
                    title: episode.trackName,
                    releaseDate: episode.releaseDate,
                    id: [id[0], id[1]], // ID de l'épisode
                    image: episode.artworkUrl160 || '',
                    link: episode.trackViewUrl,
                    platform: 'applemusic',
                    preview: episode.previewUrl,
                    duration: episode.trackTimeMillis,
                    releaseDate: episode.releaseDate,
                    podcastTitle: episode.collectionName, 
                    podcastCreator: podcast.results[0].artistName,
                };
            } else {
                return null; // Si l'épisode n'est pas trouvé
            }
        }

        return null; // Si aucun épisode n'est trouvé
    } catch (error) {
        console.error('Failed to fetch episode:', error);
        return null;
    }
};

export const getAppleMusicCreatorPodcasts = async (creatorId) => {
    return fetchFromAppleMusic(`catalog/us/artists/${creatorId}/podcasts`);
};

export const getAppleMusicLatestPodcasts = async (creatorId, limit = 10) => {
    return fetchFromAppleMusic(`catalog/us/artists/${creatorId}/podcasts?sort=releaseDate&limit=${limit}`);
};

export const getAppleMusicTopPodcasts = async (creatorId, limit = 10) => {
    return fetchFromAppleMusic(`catalog/us/artists/${creatorId}/podcasts?sort=playCount&limit=${limit}`);
};
