import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import Logo from "../../assets/logo.png";
import LogoBlack from "../../assets/logo-black.png";
import '@fortawesome/fontawesome-free/css/all.css';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import MetaTags from '../../components/MetaTags/MetaTags';
import { checkPasswordStrength } from '../../services/auth/authService';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../services/firebase/firebase.config';
import { setNotify } from '../../services/store/reducers/notifySlice';

function ResetPassword() {
    // Récupération des paramètres depuis l'URL (email et oobCode)
    const [searchParams] = useSearchParams();
    const emailFromUrl = searchParams.get('email');
    const oobCode = searchParams.get('oobCode'); // Code de réinitialisation envoyé par Firebase
    const email = emailFromUrl || '';

    // Récupération de l'erreur depuis Redux avec une valeur par défaut
    const error = useSelector((state) => state.auth.passwordIsValid) || { isValid: false, message: '' };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Vérifier la force du mot de passe avant de poursuivre
        const passwordVerif = checkPasswordStrength(password, dispatch);
        if (!passwordVerif) return;

        // Vérifier que l'oobCode est présent
        if (!oobCode) {
            dispatch(setNotify({ type: false, message: 'Code de réinitialisation manquant.' }));
            return;
        }

        try {
            await confirmPasswordReset(auth, oobCode, password);
            dispatch(setNotify({ type: true, message: 'Mot de passe réinitialisé avec succès.' }));
            navigate('/login');
        } catch (err) {
            console.error('Erreur lors de la réinitialisation du mot de passe:', err);
            dispatch(setNotify({ type: false, message: 'Erreur lors de la réinitialisation du mot de passe.' }));
        }
    };

    const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme') || 'dark');
    useEffect(() => {
        // Observer pour détecter les changements de l'attribut 'data-theme'
        const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach(mutation => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
                    const currentTheme = document.documentElement.getAttribute('data-theme') || 'dark';
                    setTheme(currentTheme);
                }
            });
        });

        observer.observe(document.documentElement, { attributes: true });
        
        return () => observer.disconnect();
    }, []);

    return (
        <>
    <MetaTags />
    <TitleTabNavigator title="Reset password" />
    <div className="flex items-center flex-col justify-start bg-[var(--background-secondary))] min-h-screen h-full gap-5">
      <div className="w-full min-h-[70px] bg-[var(--background-primary)] flex items-center justify-center ">
        <Link to="/" className="">
            <img className="h-[40px] object-contain object-center " src={theme === "light" ? LogoBlack : Logo} alt="logo hobs space retour page d'accueil" />
        </Link>
      </div>
      <div className='flex items-center justify-center w-full pb-20 max-[425px]:pb-10' style={{height: "calc(100vh - 90px)"}}>
        <div className="flex flex-col w-[70%] max-[425px]:min-w-[350px] max-[360px]:min-w-[95%] min-w-[420px] max-w-[700px] items-center justify-center bg-[var(--card-background)] p-5 border rounded-2xl border-[var(--border)]">
          <Link to="/login" className="flex flex-row items-center justify-center gap-3">
            <i className="fa-solid fa-arrow-left" style={{ fontSize: '24px' }}></i>
            <h1 className="text-center text-3xl max-[425px]:text-2xl">Réinitialisation du mot de passe</h1>
          </Link>
          <div className="flex w-full flex-col items-center justify-center mt-5 max-[360px]:mt-3">
            <form onSubmit={handleSubmit} className="flex flex-col items-center mt-3 justify-center gap-5 w-[90%]">
              <div className="w-full">
                <input
                  className="border caret-[var(--text-primary)] bg-[var(--card-background)] text-[var(--text-primary)] placeholder:text-[var(--text-secondary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]"
                  type="text"
                  placeholder="Email"
                  id="username"
                  name="username"
                  value={email}
                  readOnly
                />
              </div>
              <div className="w-full relative flex items-center justify-center">
                <input
                  className="border relative caret-[var(--text-primary)] bg-[var(--card-background)] text-[var(--text-primary)] placeholder:text-[var(--text-secondary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]"
                  type={passwordIsVisible ? 'text' : 'password'}
                  placeholder="Nouveau mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="absolute right-3 cursor-pointer"
                  onClick={() => setPasswordIsVisible(!passwordIsVisible)}
                >
                  {passwordIsVisible ? (
                    <i className="fa-solid fa-eye"></i>
                  ) : (
                    <i className="fa-solid fa-eye-slash"></i>
                  )}
                </button>
                {error && error.message && (
                  <ErrorMessage message={error.message} error={!error.isValid} />
                )}
              </div>
              <button type="submit" className="text-[var(--text-primary)] hover:bg-[var(--hover)] hover:border-[var(--hover)] no-underline text-center font-semibold uppercase bg-[var(--accent)] cursor-pointer py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 border-[#ff8500]">
                Réinitialiser le mot de passe
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
    );
}

export default ResetPassword;
