import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import LogoBlack from "../../assets/logo-black.png"
import '@fortawesome/fontawesome-free/css/all.css';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import MetaTags from '../../components/MetaTags/MetaTags';
// Import de la fonction et de l'objet d'authentification depuis firebase/auth et votre config
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../services/firebase/firebase.config';

function PasswordForgotten() {
    const [emailUser, setEmailUser] = useState('');
    const [error, setError] = useState({ active: false, message: "" });
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Réinitialisation du message
        setError({ active: false, message: "" });

        // Vérification du format de l'email
        const emailPattern = /\S+@\S+\.\S+/;
        if (!emailPattern.test(emailUser)) {
            setError({ active: true, message: "Veuillez saisir un email valide." });
            return;
        }
        auth.languageCode = navigator.language || 'fr';

        sendPasswordResetEmail(auth, emailUser)
            .then(() => {
                // Email de réinitialisation envoyé avec succès (message en vert)
                setError({ active: false, message: "Un email de réinitialisation a été envoyé." });
            })
            .catch((err) => {
                // Gestion des erreurs retournées par Firebase (message en rouge)
                if (err.code === "auth/user-not-found") {
                    setError({ active: true, message: "Aucun utilisateur trouvé pour cet email." });
                } else if (err.code === "auth/invalid-email") {
                    setError({ active: true, message: "Email invalide." });
                } else {
                    setError({ active: true, message: "Une erreur est survenue. Veuillez réessayer." });
                }
            });
    };

    const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme') || 'dark');
    useEffect(() => {
        // Observer pour détecter les changements de l'attribut 'data-theme'
        const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach(mutation => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
                    const currentTheme = document.documentElement.getAttribute('data-theme') || 'dark';
                    setTheme(currentTheme);
                }
            });
        });

        observer.observe(document.documentElement, { attributes: true });
        
        return () => observer.disconnect();
    }, []);

    return (
        <>
    <MetaTags />
    <TitleTabNavigator title="Forget password" />
    <div className="flex items-center flex-col justify-start bg-[var(--background-secondary))] min-h-screen h-full gap-5">
      <div className="w-full min-h-[70px] bg-[var(--background-primary)] flex items-center justify-center ">
        <Link to="/" className="">
            <img className="h-[40px] object-contain object-center " src={theme === "light" ? LogoBlack : Logo} alt="logo hobs space retour page d'accueil" />
        </Link>
      </div>
      <div className='flex items-center justify-center w-full pb-20 max-[425px]:pb-10' style={{height: "calc(100vh - 90px)"}}>
        <div className="flex flex-col w-[70%] max-[425px]:min-w-[350px] max-[360px]:min-w-[95%] min-w-[420px] max-w-[700px] items-center justify-center bg-[var(--card-background)] p-5 border rounded-2xl border-[var(--border)]">
          <Link to="/login" className="flex flex-row items-center justify-center gap-3">
            <i className="fa-solid fa-arrow-left" style={{ fontSize: '24px' }}></i>
            <h1 className="text-center text-3xl max-[425px]:text-2xl">Mot de passe oublié ?</h1>
          </Link>
          <div className="flex flex-col items-center justify-center mt-5 max-[360px]:mt-3 w-full">
            <form onSubmit={handleSubmit} className="flex flex-col items-center mt-3 justify-center gap-5 w-[90%]">
              <div className="w-full">
                <input
                  className="border caret-[var(--text-primary)] bg-[var(--card-background)] text-[var(--text-primary)] placeholder:text-[var(--text-secondary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]"
                  type="text"
                  placeholder="Email"
                  id="username"
                  name="username"
                  value={emailUser}
                  onChange={(e) => setEmailUser(e.target.value)}
                  required
                />
                {/* Affichage conditionnel du message */}
                {!error.active && error.message && (
                  <ErrorMessage message={error.message} error={false} />
                )}
                {error.active && error.message && (
                  <ErrorMessage message={error.message} error={true} />
                )}
              </div>
              <button type="submit" className="text-[var(--text-primary)] hover:bg-[var(--hover)] hover:border-[var(--hover)] no-underline text-center font-semibold uppercase bg-[var(--accent)] cursor-pointer py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 border-[#ff8500]">
                Réinitialiser le mot de passe
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
    );
}

export default PasswordForgotten;
