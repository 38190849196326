import React, { useState } from 'react';
import CustomModal from '../Modal/CustomModal/CustomModal';
import './ButtonEditImage.css';
import { CircleCheck } from 'lucide-react';

function ChooseSubscription(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };



    return (
        <>
            <div
                className={`rounded-full p-[1px] ${props.user.subscription.type === 'pro'
                        ? 'bg-gradient-to-r from-gray-500 via-gray-600 to-gray-700'
                        : 'bg-gradient-to-r from-purple-500 via-blue-500 to-red-500'
                    }`}
            >
                <button
                    className={`py-2 px-4 text-[var(--text-primary)] text-base font-bold rounded-full ${props.user.subscription.type === 'pro' ? 'bg-gray-500 cursor-not-allowed' : 'bg-[var(--background-secondary)]'
                        }`}
                    onClick={props.user.subscription.type === 'pro' ? null : handleButtonClick}
                    aria-disabled={props.user.subscription.type === 'pro'}
                >
                    {props.user.subscription.type === 'pro' ? '🤑 Déjà Membre Pro !' : `↗️ Membre ${props.user.subscription.type === 'basic' ? 'Basic' : 'Hobs Space +'}`}
                </button>
            </div>
            <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
                <div className='w-[90%] relative bg-[var(--background-secondary)] gap-3 border border-[var(--border)] flex flex-col p-5 rounded-2xl items-center justify-center  '>
                    <button onClick={handleCloseModal} className='absolute top-3 right-5 text-[var(--text-primary)] text-lg'><i class="fa-solid fa-xmark"></i></button>
                    <p className='text-xs px-2 py-1 rounded-full bg-[var(--accent)] text-[var(--text-primary)] '>Pricing</p>
                    <div className='w-full flex flex-col items-center justify-center'>
                        <p className="text-3xl max-[425px]:text-2xl w-[80%] text-center font-bold text-[var(--text-primary)]">{props.title}</p>
                        <p className="mt-2 text-[var(--text-primary)] text-center w-[60%] font-semibold">{props.subTitle}</p> 
                    </div>
                    <div className="p-2 mt-6 flex flex-row gap-8 flex-wrap">
                        <div className="bg-[var(--text-primary)] gap-5 flex flex-col items-start h-fit justify-start border border-[var(--border)] rounded-2xl w-[31%] p-6 transform hover:scale-105 transition duration-300">
                            <div className="w-full">
                                <h3 className="text-base w-full text-left text-[var(--background-primary)]">Hobs Free</h3>
                                <p className="text-xs w-full text-left text-[var(--background-primary)] ">Découvrez l'ecosystème Hobs, gratuitement !</p>
                            </div>
                            <div className="w-full">
                                <span className="text-3xl font-bold text-[var(--background-primary)] w-full text-left">Free</span>
                            </div>
                            <a
                                target="_blank"
                                className={`text-[var(--text-primary)] no-underline text-center font-semibold uppercase bg-[var(--accent)] mx-auto cursor-not-allowed py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 ${props.user.subscription.type !== '' ? 'bg-[var(--text-secondary)] border border-[var(--text-secondary)]' : 'bg-black hover:bg-gray-800'}`}
                                onClick={props.user.subscription.type !== '' ? null : handleButtonClick}
                                aria-disabled={props.user.subscription.type !== ''}
                            >
                                Déjà Abonné !
                            </a>
                            <div className='w-[95%] h-[2px] bg-[var(--text-secondary)] rounded-full '></div>
                            <p className='text-sm font-semibold w-full text-left text-[var(--background-primary)] '>Ce que vous obtiendrez</p>
                            <ul class="flex flex-col gap-2 w-full">
                                <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--background-primary)] text-left ">
                                    <CircleCheck size={16} color='var(--background-primary)' />
                                    <span>1 Utilisateur.</span>
                                </li>
                                <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--background-primary)] text-left  ">
                                    <CircleCheck size={16} color='var(--background-primary)' />
                                    <span>Permission de tester tout les composants.</span>
                                </li>
                                <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--background-primary)] text-left ">
                                    <CircleCheck size={16} color='var(--background-primary)' />
                                    <span>Hobs Space limité à 3 composants maximum.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="bg-[var(--card-background)] overflow-hidden relative gap-5 h-fit flex flex-col items-start justify-start border border-[var(--border)] rounded-2xl w-[31%] p-6 transform hover:scale-105 transition duration-300">
                            <div className="w-full">
                                <h3 className="text-base font-bold w-full text-left text-[var(--text-primary)]">Hobs Starter</h3>
                                <p className="text-xs text-[var(--text-primary)]">Allez au-delà avec le plan basique, pour vos profils basique</p>
                            </div>
                            <div class="w-full">
                                <span className="text-3xl font-bold text-[var(--accent)]">4.99€</span>
                                <span className="text-xl font-medium text-[var(--text-primary)]">/mois</span>
                            </div>
                            <div className='flex items-center justify-center w-full'>
                                {props.user.subscription.type === 'basic' || props.user.subscription.type === 'pro' ? (
                                    <a
                                        className="text-[var(--text-primary)] no-underline text-center font-semibold uppercase mx-auto py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 bg-[var(--text-secondary)] border-[var(--text-secondary)] cursor-not-allowed"
                                        aria-disabled="true"
                                    >
                                        Déjà Abonné !
                                    </a>
                                ) : (
                                    <a
                                        target="_blank"
                                        href={"https://buy.stripe.com/cN24hcchd8afc2keUW?prefilled_email="+props.email}
                                        className="text-[var(--text-primary)] hover:bg-[var(--hover)] hover:border-[var(--hover)] no-underline text-center font-semibold uppercase bg-[var(--accent)] cursor-pointer py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 border-[var(--accent)]"
                                    >
                                        Souscrire !
                                    </a>
                                )}
                            </div>
                            <div className='w-[95%] h-[2px] bg-[var(--border)] rounded-full '></div>
                            <ul class="flex flex-col gap-2 w-full">
                                <p className='text-sm font-semibold w-full text-left text-[var(--text-primary)] '>Personnalisations</p>
                                <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--text-primary)] text-left">
                                    <i class="fa-solid fa-circle-check text-[var(--accent)] "></i>
                                    <span>Ajoutez une photo de profil</span>
                                </li>
                                <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--text-primary)] text-left">
                                    <i class="fa-solid fa-circle-check text-[var(--accent)] "></i>
                                    <span>Intégrez une bannière et une photo de fond</span>
                                </li>
                            </ul>
                            <ul class="flex flex-col gap-2 w-full">
                                <p className='text-sm font-semibold w-full text-left text-[var(--text-primary)] '>Composants</p>
                                <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--text-primary)] text-left">
                                    <i class="fa-solid fa-circle-check text-[var(--accent)] "></i>
                                    <p className='flex flex-col items-start justify-center w-full'>
                                        Liens
                                        <span className='text-xs'>Ajoutez un nombre illimité de liens externes</span> 
                                    </p>
                                </li>
                                <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--text-primary)] text-left">
                                    <i class="fa-solid fa-circle-check text-[var(--accent)] "></i>
                                    <p className='flex flex-col items-start justify-center w-full'>
                                        Vidéo
                                        <span className='text-xs'>Ajoutez des vidéos YouTube</span> 
                                    </p>
                                </li>
                                <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--text-primary)] text-left">
                                    <i class="fa-solid fa-circle-check text-[var(--accent)] "></i>
                                    <p className='flex flex-col items-start justify-center w-full'>
                                        Contenu
                                        <span className='text-xs'>Ajoutez des zones de texte personnalisées</span> 
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="bg-[var(--text-primary)] gap-5 flex flex-col items-start h-fit justify-start border border-[var(--border)] rounded-2xl w-[31%] p-6 transform hover:scale-105 transition duration-300">
                            <div clasNames="w-full">
                                <h3 className="text-base font-bold w-full text-left text-[var(--background-primary)]">Hobs Pro</h3>
                                <p className="text-xs w-full text-left text-[var(--background-primary)]">Vivez l'expérience complète Hobs Space</p>
                            </div>
                            <div className="w-full">
                                <span className="text-3xl font-bold text-[var(--background-primary)]">9.99€</span>
                                <span className="text-xl font-medium text-[var(--background-primary)]">/mois</span>
                            </div>
                            <div className='flex w-full items-center justify-center'>
                                {props.user.subscription.type === 'pro' ? (
                                    <a
                                        className={`text-[var(--text-primary)] no-underline text-center font-semibold uppercase mx-auto py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 bg-[var(--text-secondary)] border-[var(--text-secondary)] cursor-not-allowed`}
                                        aria-disabled="true"
                                    >
                                        Déjà Abonné !
                                    </a>
                                ) : (
                                    <a
                                        target="_blank"
                                        href={"https://buy.stripe.com/4gwbJE80X4Y3d6ofYZ?prefilled_email="+props.email}
                                        className="text-[var(--text-primary)] hover:bg-[var(--hover)] hover:border-[var(--hover)] no-underline text-center font-semibold uppercase bg-[var(--accent)] cursor-pointer py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 border-[var(--accent)]"
                                    >
                                        Souscrire !
                                    </a>
                                )}
                            </div>
                            <div className='w-[95%] h-[2px] bg-[var(--text-secondary)] rounded-full '></div>
                            <div className='w-full h-[284px] overflow-y-scroll hide-scrollbar flex flex-col gap-2'>
                                <ul className="flex flex-col gap-2 w-full">
                                    <p className='text-sm font-semibold w-full text-left text-[var(--background-primary)] '>Personnalisations</p>
                                    <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--background-primary)] text-left">
                                        <CircleCheck size={16} color='var(--background-primary)' />
                                        <span>Ajoutez une photo de profil</span>
                                    </li>
                                    <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--background-primary)] text-left">
                                        <CircleCheck size={16} color='var(--background-primary)' />
                                        <span>Intégrez une bannière et une photo de fond</span>
                                    </li>
                                    <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--background-primary)] text-left">
                                        <CircleCheck size={16} color='var(--background-primary)' />
                                        <span>Modifiez les couleurs et la typographie</span>
                                    </li>
                                </ul>
                                <ul className="flex flex-col gap-2 w-full">
                                    <p className='text-sm font-semibold w-full text-left text-[var(--background-primary)] '>Composants</p>
                                    <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--background-primary)] text-left">
                                        <CircleCheck size={16} color='var(--background-primary)' />
                                        <p className='flex flex-col items-start justify-center w-full'>
                                            Liens
                                            <span className='text-xs'>Ajoutez un nombre illimité de liens externes</span> 
                                        </p>
                                    </li>
                                    <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--background-primary)] text-left">
                                        <CircleCheck size={16} color='var(--background-primary)' />
                                        <p className='flex flex-col items-start justify-center w-full'>
                                            Galerie Média
                                            <span className='text-xs'>Importez et partagez vos photos & vidéos</span> 
                                        </p>
                                    </li>
                                    <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--background-primary)] text-left">
                                        <CircleCheck size={16} color='var(--background-primary)' />
                                        <p className='flex flex-col items-start justify-center w-full'>
                                            Boutique
                                            <span className='text-xs'>Intégrez votre boutique en ligne directement dans votre espace</span> 
                                        </p>
                                    </li>
                                    <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--background-primary)] text-left">
                                        <CircleCheck size={16} color='var(--background-primary)' />
                                        <p className='flex flex-col items-start justify-center w-full'>
                                            Audio
                                            <span className='text-xs'>Ajoutez vos musiques depuis vos plateformes de streaming Importez vos podcasts</span> 
                                        </p>
                                    </li>
                                    <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--background-primary)] text-left">
                                        <CircleCheck size={16} color='var(--background-primary)' />
                                        <p className='flex flex-col items-start justify-center w-full'>
                                            Video
                                            <span className='text-xs'>Ajoutez des vidéos YouTube
                                            <br/>Partagez vos Réels : TikTok, YouTube Shorts, Instagram</span> 
                                        </p>
                                    </li>
                                    <li class="flex items-center text-sm flex-row justify-start gap-2 text-[var(--background-primary)] text-left">
                                        <CircleCheck size={16} color='var(--background-primary)' />
                                        <p className='flex flex-col items-start justify-center w-full'>
                                            Contenu
                                            <span className='text-xs'>Ajoutez des zones de texte personnalisées</span> 
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </>
    );
}

export default ChooseSubscription;

