import React, { useState, useEffect, useRef } from 'react';
import styles from './LinkPodcast.module.css';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../../utils';
import { getSpotifyPodcastEpisodeById } from '../../../services/api/spotify';
import { getDeezerPodcastEpisodesById } from '../../../services/api/deezer';
import { getAppleMusicPodcastEpisodeById } from '../../../services/api/appleMusic';
import { getYouTubeMusicPodcastEpisodeById } from '../../../services/api/youtubeMusic';
import ProgressBar from '..//ProgressBar/ProgressBar';

function LinkPodcast({ idMedia }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [musicData, setMusicData] = useState(null); // Données principales
    const [platformData, setPlatformData] = useState([]); // Toutes les données des plateformes
    const [progress, setProgress] = useState(60000); // Avancement de la lecture
    const [currentTime, setCurrentTime] = useState(0); // Avancement de la lecture
    const bgdImage = useSelector((state) => state.hobspace.backgroundImage);
    const color = useSelector((state) => state.hobspace.colorFont);
    const { hobspace } = useHobspace();
    const audioRef = useRef(null);

    useEffect(() => {
        if (idMedia && Object.keys(idMedia).length > 0) {
            fetchAllPlatformData();
        }
    }, [idMedia, progress]);

    const renderIcon = (platform) => {
        switch (platform) {
            case 'spotify':
                return <i className='fa-brands fa-spotify' style={{ color: hobspace.colorFont }}></i>;
            case 'deezer':
                return <i className='fa-brands fa-deezer' style={{ color: hobspace.colorFont }}></i>;
            case 'applemusic':
                return <i className='fa-brands fa-itunes-note' style={{ color: hobspace.colorFont }}></i>;
            case 'youtubemusic':
                return <i className='fa-regular fa-circle-play' style={{ color: hobspace.colorFont }}></i>;
            default:
                return null;
        }
    };

    const fetchMusicData = async (platform, id) => {
        try {
            switch (platform) {
                case 'spotify':
                    return await getSpotifyPodcastEpisodeById(id);
                case 'deezer':
                    return await getDeezerPodcastEpisodesById(id);
                case 'applemusic':
                    return await getAppleMusicPodcastEpisodeById(id);
                case 'youtubemusic':
                    return await getYouTubeMusicPodcastEpisodeById(id);
                default:
                    return null;
            }
        } catch (error) {
            console.error(`Erreur lors de la récupération des données pour ${platform}:`, error);
            return null;
        }
    };

    const fetchAllPlatformData = async () => {
        const platforms = Object.entries(idMedia);
        const results = await Promise.all(
            platforms.map(async ([platform, id]) => {
                const data = await fetchMusicData(platform, id);
                return data ? { platform, ...data } : null;
            })
        );
    
        const validResults = results.filter(Boolean);
        setPlatformData(validResults);
    
        // Définit les données principales à partir de la première plateforme avec une préview valide
        if (validResults.length > 0) {
            // Logique pour sélectionner la plateforme avec la durée la plus longue
            let longestPreviewDuration = 0;
            let previewPlatform = null;
    
            validResults.forEach(platformData => {
                if (platformData?.preview && platformData?.duration !== null) {
                    // Comparer les durées des préviews (si la durée est définie)
                    if (platformData.duration > longestPreviewDuration) {
                        longestPreviewDuration = platformData.duration;
                        previewPlatform = platformData;
                    }
                }
            });
    
            // Utilise la plateforme avec la plus longue préview valide
            if (previewPlatform) {
                setMusicData([previewPlatform]);
                setProgress(longestPreviewDuration); // Mettre à jour la durée de la progress bar en fonction de la durée la plus longue
            } else {
                setMusicData(validResults); // Sinon, utiliser toutes les plateformes
            }
        }
    };
       

    const playPreview = () => {
        const trackWithPreview = musicData.find(music => music?.preview);
        if (trackWithPreview) {
            audioRef.current.src = trackWithPreview.preview;
            audioRef.current.play();
            setIsPlaying(true);
        } else {
            const firstTrackWithLink = musicData.find(music => music?.link);
            if (firstTrackWithLink) {
                window.open(firstTrackWithLink.link, '_blank', 'noopener,noreferrer');
            }
        }
    };    

    const stopPreview = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    };

    const handlePreviewEnd = () => {
        setIsPlaying(false);
    };

    const skip = (seconds) => {
        if (audioRef.current) {
            // Modifie la position de l'audio et mets à jour la progress bar
            audioRef.current.currentTime += seconds;
        }
    };    

    return (
        <>
            {musicData && musicData.length > 0 ? (
                <a href={musicData[0]?.link} target="_blank" className={styles.LinkPodcast} style={{ backgroundColor: bgdImage ? hexToRgb(hobspace.backgroundColor) : 'rgba(255,255,255,0.15)', color }}>
                    <div className='w-full flex flex-row-reverse items-start justify-center gap-4'>
                        <div className={styles.containerInfoMusic}>
                            <div className='w-full flex flex-row items-center justify-end gap-2 mb-2 max-425:mb-1'>
                                {platformData.map(({ platform }) => renderIcon(platform))}
                            </div>
                            <p className={`${styles.titlePodcast} truncate-two-lines`} style={{ color: hobspace.colorFont }}>{musicData[0]?.title}</p>
                            <p className={`${styles.singer} truncate`} style={{ color: hexToRgb(hobspace.colorFont, 0.6) }}>{musicData[0]?.podcastTitle} - {musicData[0]?.podcastCreator}</p>
                        </div>
                        {musicData[0]?.image &&
                            <div className={styles.musicImageContainer}>
                                <img className={styles.music} src={musicData[0].image} alt="music" />
                            </div>
                        }
                    </div>
                    {musicData[0]?.preview &&
                    <>
                        <ProgressBar isPlaying={isPlaying} onPlay={playPreview} currentDuration={currentTime} onPause={stopPreview} duration={progress} onSkip={skip} audio={audioRef} />
                        <audio ref={audioRef} onEnded={handlePreviewEnd} />
                    </>}
                </a>
            ) : (
                <div className={styles.LinkPodcast} style={{ backgroundColor: bgdImage ? hexToRgb(hobspace.backgroundColor) : 'rgba(255,255,255,0.15)', color }}>
                    <div className='w-full flex flex-row-reverse items-start justify-center gap-4'>
                        <div className={styles.containerInfoMusic}>
                            <div className='w-full flex flex-row items-center justify-end gap-2 mb-2 max-425:mb-1'>
                                <i className='fa-brands fa-spotify' style={{ color: hobspace.colorFont }}></i>
                                <i className='fa-brands fa-deezer' style={{ color: hobspace.colorFont }}></i>
                                <i className='fa-brands fa-itunes-note' style={{ color: hobspace.colorFont }}></i>
                                <i className='fa-regular fa-circle-play' style={{ color: hobspace.colorFont }}></i>
                            </div>
                            <p className={`${styles.titlePodcast} truncate`} style={{ color: hobspace.colorFont }}>Titre de la musique</p>
                            <p className={`${styles.singer} truncate`} style={{ color: hexToRgb(hobspace.colorFont, 0.6) }}>Nom du chanteur</p>
                        </div>
                        <div className={styles.containerImageDefaultMusic}>
                            <i className="fa-solid fa-image" style={{ color }}></i>
                        </div>
                    </div>
                    <div className="w-full flex flex-row gap-2">
                        <div className="w-[75%] max-sm:w-[70%] flex flex-row gap-1 items-center justify-center">
                            <button className="p-1 bg-transparent rounded">
                                <svg data-encore-id="icon" role="img" aria-hidden="true" className="h-4 max-350:h-[14px]" fill={hobspace.colorFont} viewBox="0 0 16 16">
                                    <path d="M2.464 4.5h1.473a.75.75 0 0 1 0 1.5H0V2.063a.75.75 0 0 1 1.5 0v1.27a8.25 8.25 0 1 1 10.539 12.554.75.75 0 0 1-.828-1.25A6.75 6.75 0 1 0 2.464 4.5Z"></path>
                                    <path d="M0 10.347V9.291c.697-.051 1.199-.18 1.507-.385.315-.205.51-.51.583-.913h1.32v7.81H1.903v-5.456H0Zm7.322 5.643c-.543 0-1.03-.099-1.463-.297a2.46 2.46 0 0 1-1.023-.869c-.25-.389-.382-.85-.396-1.386h1.518c.007.242.066.455.176.638.11.183.26.326.45.43.191.102.411.153.66.153.257 0 .48-.059.672-.176.198-.117.348-.282.45-.495.11-.213.166-.454.166-.726 0-.271-.055-.51-.165-.715a1.135 1.135 0 0 0-.451-.495 1.254 1.254 0 0 0-.671-.176c-.286 0-.536.07-.748.21a1.23 1.23 0 0 0-.462.516H4.56L5 7.993h4.642V9.39H6.207l-.211 2.134c.057-.108.136-.214.237-.319a1.77 1.77 0 0 1 .616-.407c.249-.103.52-.154.814-.154.454 0 .861.103 1.22.308.367.206.653.499.859.88.205.381.308.825.308 1.331 0 .528-.11 1.008-.33 1.441-.22.426-.54.763-.957 1.012-.411.25-.891.374-1.441.374Z"></path>
                                </svg>
                            </button>
                            <div className="relative w-full h-[4px] max-350:h-[3px] rounded-full cursor-pointer" style={{ background: bgdImage ? "rgba(0,0,0,0.3)" : hobspace.backgroundColor }}>
                                <div className="absolute top-0 left-0 h-[4px] max-350:h-[3px] rounded-full" style={{ background: hobspace.colorFont, width: `${(0 / 60000) * 100}%` }} />
                            </div>
                            <button className="p-1 bg-transparent rounded">
                                <svg data-encore-id="icon" role="img" aria-hidden="true" className="h-4 max-350:h-[14px]" fill={hobspace.colorFont} viewBox="0 0 16 16">
                                    <path d="M13.536 4.488h-1.473a.75.75 0 1 0 0 1.5H16V2.051a.75.75 0 0 0-1.5 0v1.27A8.25 8.25 0 1 0 3.962 15.876a.75.75 0 0 0 .826-1.252 6.75 6.75 0 1 1 8.747-10.136Z"></path>
                                    <path d="M11.81 15.681c.433.198.921.297 1.464.297.55 0 1.03-.124 1.44-.374.419-.25.738-.586.958-1.012.22-.432.33-.913.33-1.44 0-.507-.103-.95-.308-1.332a2.156 2.156 0 0 0-.858-.88 2.416 2.416 0 0 0-1.221-.308c-.294 0-.565.052-.814.154a1.77 1.77 0 0 0-.616.407c-.1.105-.18.211-.237.319l.211-2.134h3.436V7.981h-4.642l-.44 4.61h1.474a1.24 1.24 0 0 1 .462-.518c.212-.14.462-.209.748-.209.256 0 .48.059.67.176.199.118.349.283.452.495.11.206.165.444.165.715 0 .272-.055.514-.165.726a1.135 1.135 0 0 1-.451.495 1.25 1.25 0 0 1-.671.176c-.25 0-.47-.051-.66-.154a1.155 1.155 0 0 1-.451-.429 1.295 1.295 0 0 1-.176-.638h-1.518c.014.536.146.998.396 1.386a2.46 2.46 0 0 0 1.023.87Zm-5.858-5.346V9.28c.697-.051 1.199-.18 1.507-.385.315-.205.51-.51.583-.913h1.32v7.81H7.855v-5.456H5.952Z"></path>
                                </svg>
                            </button>
                        </div>
                        <div className="flex flex-row justify-between w-[25%] max-sm:w-[30%]">
                            <div className="text-base max-425:text-sm max-350:text-xs flex flex-row items-center justify-center" style={{ color: hobspace.colorFont }}>
                                - <span>1:00</span>
                            </div>
                            <div className={styles.playPausePodcast}>
                                    <button className={styles.playPodcast} style={{ color: "#FFF", backgroundColor: `${hexToRgb("#000", 0.6)}`}}>
                                        <i className="fa-solid fa-play" style={{ color: "#FFF" }}></i>
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default LinkPodcast;
