import React, { useState, useEffect } from 'react'
import './Home.css';
import NavigationHome from '../../Layouts/Navigation/NavigationHome/NavigationHome';
import { Link } from "react-router-dom";
import Footer from '../../Layouts/Footer/Footer';
import Logo from "../../assets/logo.png";
import LogoBlack from "../../assets/logo-black.png";
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import GetStarted from '../../Layouts/GetStarted/GetStarted';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from "../../utils/contexts/userContext";
import FAQ from "../../Layouts/FAQ/FAQ";
import Img from "../../assets/img.png";
import MetaTags from '../../components/MetaTags/MetaTags';
import { useNavigate } from 'react-router-dom';

function Home() {

    const dispatch = useDispatch();
    const email = useSelector((state) => state.user.email);
    const { user, loading } = useUser();
    const image = useSelector((state) => state.user.images);
    const navigate = useNavigate();
    const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme') || 'dark');

    useEffect(() => {
        // Observer pour détecter les changements de l'attribut 'data-theme'
        const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach(mutation => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
                    const currentTheme = document.documentElement.getAttribute('data-theme') || 'dark';
                    setTheme(currentTheme);
                }
            });
        });

        observer.observe(document.documentElement, { attributes: true });
        
        return () => observer.disconnect();
    }, []);
 
    return (
        <div className="text-center w-full h-full flex overflow-x-hidden justify-start items-center flex-col bg-[var(--background-secondary)]">
            <MetaTags />
            <TitleTabNavigator title="Accueil" />
            <NavigationHome />
            <div className="w-full flex items-center justify-center bg-[var(--background-secondary)]">
                <img
                    src={theme === "light" ? LogoBlack : Logo}
                    alt="Logo"
                    className="rounded-3xl object-contain object-center w-3/5"
                    style={{ height: '94vh' }}
                />
            </div>
            {/* Le reste de votre composant */}
        </div>
    );
}

export default Home;
