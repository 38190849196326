import React, { useState, useEffect, useRef } from 'react';
import styles from './LinkMusic.module.css';
import CustomModal from '../Modal/CustomModal/CustomModal';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../../utils';
import { getSpotifyTrackById } from '../../../services/api/spotify';
import { getDeezerTrackById } from '../../../services/api/deezer';
import { getAppleMusicTrackById } from '../../../services/api/appleMusic';
import { getYoutubeTrackById } from '../../../services/api/youtubeMusic';
import AppleMusic from '../../../assets/applemusic-color.png';
import Deezer from '../../../assets/deezer-color.png';
import Spotify from '../../../assets/spotify-color.png';
import YoutubeMusic from '../../../assets/youtubemusic-color.png';

function LinkMusic({ idMedia }) {
    const [showModal, setShowModal] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [musicData, setMusicData] = useState(null); // Données principales
    const [platformData, setPlatformData] = useState([]); // Toutes les données des plateformes
    const bgdImage = useSelector((state) => state.hobspace.backgroundImage);
    const { hobspace } = useHobspace();
    const audioRef = useRef(null);

    useEffect(() => {
        if (idMedia && Object.keys(idMedia).length > 0) {
            fetchAllPlatformData();
        }
    }, [idMedia]);

    const renderLogo = (platform) => {
        switch (platform) {
            case 'spotify':
                return {logo: Spotify, color: "#1cd05d", title: "Spotify"};
            case 'deezer':
                return {logo: Deezer, color: "#9e34f7", title: "Deezer"};
            case 'applemusic':
                return {logo: AppleMusic, color: "#f64659", title: "Apple Music"};
            case 'youtubemusic':
                return {logo: YoutubeMusic, color: "#ff1512", title: "Youtube Music"};
            default:
                return null;
        }
    };

    const fetchMusicData = async (platform, id) => {
        try {
            switch (platform) {
                case 'spotify':
                    return await getSpotifyTrackById(id);
                case 'deezer':
                    return await getDeezerTrackById(id);
                case 'applemusic':
                    return await getAppleMusicTrackById(id);
                case 'youtubemusic':
                    return await getYoutubeTrackById(id);
                default:
                    return null;
            }
        } catch (error) {
            console.error(`Erreur lors de la récupération des données pour ${platform}:`, error);
            return null;
        }
    };

    const fetchAllPlatformData = async () => {
        const platforms = Object.entries(idMedia);
        const results = await Promise.all(
            platforms.map(async ([platform, id]) => {
                const data = await fetchMusicData(platform, id);
                return data ? { platform, ...data } : null;
            })
        );

        const validResults = results.filter(Boolean);
        setPlatformData(validResults);

        // Définit les données principales à partir de la première plateforme ajoutée
        if (validResults.length > 0) {
            setMusicData(validResults);
        }
    };

    const playPreview = () => {
        const trackWithPreview = musicData.find(music => music?.preview);

        if (trackWithPreview) {
            audioRef.current.src = trackWithPreview.preview;
            audioRef.current.play();
            setIsPlaying(true);
        } else {
            const firstTrackWithLink = musicData.find(music => music?.link);
            if (firstTrackWithLink) {
                window.open(firstTrackWithLink.link, '_blank', 'noopener,noreferrer');
            }
        }
    };

    const stopPreview = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    };

    const handlePreviewEnd = () => {
        setIsPlaying(false);
    };

    return (
        <>
        {musicData && musicData.length > 0 ? (
        <a
            href={musicData[0]?.link || "#"}
            target="_blank"
            className={styles.LinkMusic}
            style={{
                backgroundColor: bgdImage
                    ? hexToRgb(hobspace.backgroundColor)
                    : 'rgba(255,255,255,0.15)',
                color: hobspace.colorFont,
            }}
            rel="noopener noreferrer"
        >
            <div className={styles.containerInfoMusic}>
                <p className={`${styles.title} truncate`} style={{ color: hobspace.colorFont }}>
                    {musicData[0]?.title || 'Titre de la musique'}
                </p>
                <p
                    className={`${styles.singer} truncate`}
                    style={{ color: hexToRgb(hobspace.colorFont, 0.6) }}
                >
                    {musicData[0]?.artists?.join(', ') || 'Nom du chanteur'}
                </p>
                <div className={styles.containerButtonLinkMusic}>
                    {platformData.length > 1 && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setShowModal(true);
                            }}
                            className={styles.more}
                            style={{
                                backgroundColor: hobspace.colorFont,
                                border: `1px solid ${hobspace.colorFont}`,
                                color: hobspace.backgroundColor,
                            }}
                        >
                            More
                        </button>
                    )}
                </div>
            </div>
            {musicData[0]?.image ? (
                <div className="music-image-container">
                    <img className={styles.music} src={musicData[0].image} alt="music" />
                    <div className={styles.playPauseButton}>
                        {isPlaying ? (
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    stopPreview();
                                }}
                                className={styles.pause}
                                style={{
                                    color: "#000",
                                    backgroundColor: `${hexToRgb("#FFFFFF", 1)}`,
                                }}
                            >
                                <i className="fa-solid fa-pause" style={{color: "#000"}}></i>
                            </button>
                        ) : (
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    playPreview();
                                }}
                                className={styles.play}
                                style={{
                                    color: "#FFF",
                                    backgroundColor: `${hexToRgb("#000", 0.6)}`,
                                }}
                            >
                                <i className="fa-solid fa-play" style={{color: "#FFF"}}></i>
                            </button>
                        )}
                    </div>
                </div>
            ) : (
                <div className={styles.containerImageDefaultMusic}>
                    <i className="fa-solid fa-image" style={{ color: hobspace.colorFont }}></i>
                </div>
            )}
            <audio ref={audioRef} onEnded={handlePreviewEnd} />
            {musicData &&
            <CustomModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
            >
                <div className={`flex items-center flex-col justify-center relative w-full mt-14`} style={{marginTop: "50px"}}>
                    <div className='relative flex flex-row items-center justify-around w-[500px] max-sm:w-[400px] max-425:w-[300px] h-32 max-sm:h-28 max-425:h-20 gap-5 max-425:gap-3 rounded-t-[10px]' style={{ backgroundColor: hobspace.backgroundColor }}>
                        <img className={`rounded-[10px] object-cover object-center ml-7 mb-16 max-sm:mb-14 max-425:mb-10 max-425:ml-3 max-sm:ml-5 h-[140px] w-[140px] max-sm:w-[120px] max-425:w-[90px] max-425:h-[90px] max-sm:h-[120px]`} src={musicData[0]?.image || null} alt="music"/>
                        <div className={styles.playPauseButtonModal}>
                            {isPlaying ? (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        stopPreview();
                                    }}
                                    className={styles.pause}
                                    style={{
                                        color: "#000",
                                        backgroundColor: `${hexToRgb("#FFFFFF", 1)}`,
                                    }}
                                >
                                    <i className="fa-solid fa-pause" style={{color: "#000"}}></i>
                                </button>
                            ) : (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        playPreview();
                                    }}
                                    className={styles.play}
                                    style={{
                                        color: "#FFF",
                                        backgroundColor: `${hexToRgb("#000", 0.6)}`,
                                    }}
                                >
                                    <i className="fa-solid fa-play" style={{color: "#FFF"}}></i>
                                </button>
                            )}
                        </div>
                        <div className={`text-left mr-5 w-[290px] max-sm:w-[220px] max-425:w-[175px] max-425:mr-3`}>
                            <p className={`text-[20px] capitalize text-left font-semibold overflow-hidden max-sm:text-lg max-425:text-sm w-full m-0 truncate`} style={{ color: hobspace.colorFont }}>
                            {musicData[0]?.title || 'Titre de la musique'}
                            </p>
                            <p className={`capitalize text-white text-opacity-60 font-medium text-[18px] max-sm:text-base max-425:text-xs mt-[2px] mb-[10px] truncate`}>
                            {musicData[0]?.artists?.length > 1
                                ? musicData[0].artists.join(', ')
                                : musicData[0]?.artists?.[0] || 'Nom du chanteur'}
                            </p>
                        </div>
                    </div>
                    <div className={`flex flex-col w-[500px] max-sm:w-[400px] max-425:w-[300px] items-center justify-center rounded-b-[10px] bottom-[0px] gap-0 px-5 max-425:px-3 max-425:py-2 py-4`} style={{ backgroundColor: hobspace.colorFont }}>
                        {platformData.map(({ platform, link }, index) => (
                            <div className='w-full' key={platform}>
                                <a className={`flex items-center justify-between flex-row cursor-pointer no-underline`} href={link || '#'} target="_blank" rel="noreferrer">
                                    <div className={`flex items-center justify-start gap-2`}>
                                        <img className={`object-contain object-center h-[28px] w-auto max-sm:h-6 max-425:h-[18px]`} src={renderLogo(platform).logo} alt={`${platform} logo`}/>
                                        <p className='text-lg font-bold text-left max-sm:text-base max-425:text-sm' style={{color: renderLogo(platform).color}}>{renderLogo(platform).title} </p>
                                    </div>
                                    
                                    <p className={`rounded-[20px] no-underline leading-[12px] cursor-pointer border border-black bg-transparent capitalize text-black font-medium text-[16px] max-sm:text-sm max-425:text-xs max-sm:py-[3px] max-425:py-[2px] max-425:px-[8px] px-[10px] py-[5px]`}>Play</p>
                                </a>
                                {index !== platformData.length - 1 &&
                                   <div className={`h-[1px] w-full bg-black bg-opacity-10 rounded-[50px] my-[8px]`}></div> 
                                }
                                
                            </div>
                        ))}
                    </div>
                </div>
            </CustomModal>
            }
        </a>
        ) : (
        <div className={styles.LinkMusic} style={{backgroundColor: bgdImage ? hexToRgb(hobspace.backgroundColor) : 'rgba(255,255,255,0.15)', color: hobspace.colorFont}} rel="noopener noreferrer">
            <div className={styles.containerInfoMusic}>
                <p className={`${styles.title} truncate`} style={{ color: hobspace.colorFont }}>Titre de la musique</p>
                <p className={`${styles.singer} truncate`} style={{ color: hexToRgb(hobspace.colorFont, 0.6) }}>Nom du chanteur</p>
            </div>
            <div className={styles.containerImageDefaultMusic}>
                <i className="fa-solid fa-image" style={{ color: hobspace.colorFont }}></i>
            </div>
        </div>
        )}</>
    );
}

export default LinkMusic;
