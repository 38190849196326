import React, { useEffect, useState } from 'react';
import './SignUp.css';
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import LogoBlack from "../../assets/logo-black.png";
import { setFirstname, setImages, setLastname } from '../../services/store/reducers/userSlice';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { useSelector, useDispatch } from "react-redux";
import { isValidString, isValidUrl, links } from '../../utils';
import '@fortawesome/fontawesome-free/css/all.css';
import ButtonEditImage from '../../components/ButtonEditImage/ButtonEditImage';
import InputFieldText from '../../components/InputField/InputFieldText';
import { useUser } from '../../utils/contexts/userContext';

function ConfigProfile({ createUser }) {
  const [step, setStep] = useState(1);
  const firstname = useSelector((state) => state.user.firstname);
  const lastname = useSelector((state) => state.user.lastname);
  const hobspace = useSelector((state) => state.user.hobsSpace);
  const offers = useSelector((state) => state.user.offers);
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [website, setWebsite] = useState("");
  const images = useSelector((state) => state.user.images);
  const [error, setError] = useState({ message: "", status: false });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated, loading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirige vers le Dashboard dès que le UserContext est mis à jour et l'utilisateur est authentifié
    if (!loading && isAuthenticated) {
      navigate("/dashboard");
    }
  }, [loading, isAuthenticated, navigate]);

  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme') || 'dark');
  useEffect(() => {
      // Observer pour détecter les changements de l'attribut 'data-theme'
      const observer = new MutationObserver((mutationsList) => {
          mutationsList.forEach(mutation => {
              if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
                  const currentTheme = document.documentElement.getAttribute('data-theme') || 'dark';
                  setTheme(currentTheme);
              }
          });
      });

      observer.observe(document.documentElement, { attributes: true });
      
      return () => observer.disconnect();
  }, []);

  async function urlToFile(url, fileName) {
    try {
      const response = await fetch(url);
      const data = await response.blob();
      const mimeType = data.type || "application/octet-stream";
      const extension = mimeType.split('/')[1];
      const newFileName = fileName || `example.${extension}`;
      return new File([data], newFileName, { type: mimeType });
    } catch (error) {
      console.error("Erreur lors de la conversion de l'URL en fichier :", error);
    }
  }

  const handleChangeFirstname = (newValue) => {
    if (isValidString(newValue)) {
      setError({ message: "", status: false });
      dispatch(setFirstname(newValue));
    } else {
      setError({ message: "Le prénom contient des caractères indésirables", status: true });
    }
  };

  const handleChangeLastname = (newValue) => {
    if (isValidString(newValue)) {
      setError({ message: "", status: false });
      dispatch(setLastname(newValue));
    } else {
      setError({ message: "Le nom contient des caractères indésirables", status: true });
    }
  };

  const handleLinkBlur = (id, value) => {
    if (!value) {
      setError({ message: "", status: false });
      return;
    }
    if (!isValidUrl(value)) {
      setError({ message: `L'URL entrée pour ${id} n'est pas valide.`, status: true });
    } else {
      setError({ message: "", status: false });
      switch (id) {
        case "instagram":
          setInstagram(value);
          break;
        case "facebook":
          setFacebook(value);
          break;
        case "website":
          setWebsite(value);
          break;
        default:
          break;
      }
    }
  };

  const addLink = (name, url) => {
    const linkTemplate = links.find(link => link.name === name);
    if (linkTemplate && url) {
      return { ...linkTemplate, active: true, link: url };
    }
    return null;
  };

  const handleEnd = async () => {
    const updatedLinks = [
      addLink("instagram", instagram),
      addLink("facebook", facebook),
      addLink("site web", website)
    ].filter(link => link !== null);
    
    const userData = {
      offer: offers,
      nameSpace: hobspace,
      firstname: firstname,
      description: "Entrer une description de 140 caractères maximum",
      lastname: lastname,
      phoneNumber: "",
      country: { code: "FR", name: "France" },
      colorFont: "#FFFFFF",
      backgroundColor: "#000000",
      links: updatedLinks,
      page: [],
      pageInit: [{ name: "Profile", vertical: true, logo: false }],
      subscription: {
        type: "free",
        howLong: "",
        createAt: new Date(),
        clientIdStripe: "",
      },
      contactActive: {
        email: false,
        phone: false,
      },
    };
    try {
      setIsLoading(true);
      if (images) {
        const file = await urlToFile(images, "example");
        console.log(images);
        dispatch(setImages(images));
        await createUser(userData, file);
      } else {
        await createUser(userData);
      }
    } catch (error) {
      console.error('Error create user', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(false);
    setInstagram("");
    setFacebook("");
    setWebsite("");
    setError({ message: "", status: false });
    dispatch(setLastname(""));
    dispatch(setFirstname(""));
    dispatch(setImages(undefined));
  }, [dispatch]);

  return (
    <div className='flex items-center flex-col justify-between bg-[var(--background-secondary))] min-h-screen h-full pb-20 max-[425px]:pb-10 gap-5'>
      <div className="w-full min-h-[70px] bg-[var(--background-primary)] flex items-center justify-center ">
        <Link to="/" className="containerLogoLogin">
          <img className="h-[40px] object-contain object-center " src={theme === "light" ? LogoBlack : Logo} alt="logo hobs space retour page d'accueil" />
        </Link>
      </div>
      <div className='flex flex-col w-[70%] max-[425px]:min-w-[350px] max-[360px]:min-w-[95%] min-w-[420px] max-w-[700px] items-center justify-center bg-[var(--card-background)] p-5 border rounded-2xl border-[var(--border)] '>
        <div className='flex flex-col items-center justify-center gap-3"'>
          <h1 className='text-center text-3xl max-[425px]:text-2xl'>Configurez votre profil</h1>
          <p className="text-center px-4 py-2 max-[425px]:text-sm">Nous avons juste besoin de quelques éléments pour terminer la mise en place de votre page.</p>
        </div>
        <div className='containerConfigProfile'>
          <div className='containerStep'>
            <div className={`step ${step >= 1 ? "active" : ""}`}>
              <span className={step >= 1 ? "active" : ""}>Profil</span>
              <p>1</p>
            </div>
            <div className={`stepLine ${step >= 2 ? "active" : ""}`}></div>
            <div className={`step ${step >= 2 ? "active" : ""}`}>
              <span className={step >= 2 ? "active" : ""}>Liens</span>
              <p>2</p>
            </div>
            <div className={`stepLine ${step >= 3 ? "active" : ""}`}></div>
            <div className={`step ${step >= 3 ? "active" : ""}`}>
              <span className={step >= 3 ? "active" : ""}>Contenu</span>
              <p>3</p>
            </div>
          </div>
          <div className='sectionLayoutConfig'>
            <div className='containerLayoutConfig'>
              {images !== undefined ? (
                <img className='ImgConfigProfil' width={100} height={125} src={images} alt="Aperçu de l'image" />
              ) : (
                <div className='ImgConfigProfil'>
                  <i style={{ color: "#fff" }} className="fa-regular fa-user"></i>
                </div>
              )}
              <div className='containerNameConfig'>
                <p style={{ color: lastname !== "" ? "#fff" : "rgba(255,255,255,0.3)" }}>
                  {lastname !== "" ? lastname : "Nom"}
                </p>
                <p style={{ color: firstname !== "" ? "#fff" : "rgba(255,255,255,0.3)" }}>
                  {firstname !== "" ? firstname : "Prénom"}
                </p>
              </div>
              {step >= 2 && (
                <div className='containerNameConfig'>
                  {instagram !== "" ? (
                    <i className="fa-brands fa-instagram"></i>
                  ) : (
                    <div className='socialLinkDash'></div>
                  )}
                  {facebook !== "" ? (
                    <i className="fa-brands fa-square-facebook"></i>
                  ) : (
                    <div className='socialLinkDash'></div>
                  )}
                  {website !== "" ? (
                    <i className="fa-solid fa-earth-europe"></i>
                  ) : (
                    <div className='socialLinkDash'></div>
                  )}
                </div>
              )}
            </div>
          </div>
          {step === 1 && (
            <>
              <div className='containerTextConfigProfile'>
                <h1 className='titleLogin text-center'>Ajouter une image d'en-tête et votre nom et prénom</h1>
                <p className='text-center'>Faites ressortir votre page - cette image ainsi que votre nom et prénom peuvent être modifiée ultérieurement.</p>
              </div>
              <div className='containerEditProfilImg'>
                <ButtonEditImage manager={false} title="Charger une photo de profile" folder="profile" nameFile="profile-image" upload={false} color={""} email={null} />
              </div>
              <div className='containerInputFirstnameLastname'>
                <InputFieldText
                  value={lastname}
                  onChange={(e) => handleChangeLastname(e.target.value)}
                  placeholder="Nom"
                  type={true}
                  maxLength={50}
                />
                <InputFieldText
                  value={firstname}
                  onChange={(e) => handleChangeFirstname(e.target.value)}
                  placeholder="Prénom"
                  type={true}
                  maxLength={50}
                />
                {error.status && <ErrorMessage message={error.message} error={error.status} />}
              </div>
              <div className='containerButtonConfigProfile'>
                <button
                  className={`text-[var(--text-primary)] cursor-pointer no-underline text-center font-semibold uppercase bg-[var(--accent)] py-2 px-6 text-base rounded-[50px] border-2 border-[var(--accent)] hover:bg-[var(--hover)] hover:border-[var(--hover)] buttonConfigProfile ${((lastname !== "" || firstname !== "") && !error.status) ? "next" : "disable"}`}
                  onClick={((lastname !== "" || firstname !== "") && !error.status) ? () => setStep(2) : () => {}}
                >
                  Suivant
                </button>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className='containerTextConfigProfile'>
                <h1 className='titleLogin'>Entrez les liens de vos réseaux sociaux</h1>
                <p>Ajoutez des liens sociaux pour que les gens puissent suivre vos comptes - vous pourrez en ajouter plus tard.</p>
              </div>
              <div className='containerInputFirstnameLastname'>
                <div>
                  <p className='flex flex-row gap-1 items-center justify-start'>
                    <i className="fa-brands fa-instagram text-[var(--text-primary)] text-lg "></i>
                    Instagram
                  </p>
                  <InputFieldText
                    maxLength={2048}
                    value={instagram}
                    onBlur={(e) => handleLinkBlur("instagram", e.target.value)}
                    onChange={(e) => setInstagram(e.target.value)}
                    placeholder="https://instagram.com/hobs.space"
                    title=""
                    type={true}
                  />
                </div>
                <div>
                  <p className='flex flex-row gap-1 items-center justify-start'>
                    <i className="fa-brands fa-square-facebook text-[var(--text-primary)] text-lg"></i>
                    Facebook
                  </p>
                  <InputFieldText
                    maxLength={2048}
                    value={facebook}
                    onBlur={(e) => handleLinkBlur("facebook", e.target.value)}
                    onChange={(e) => setFacebook(e.target.value)}
                    placeholder="https://facebook.com/hobs.space"
                    title=""
                    type={true}
                  />
                </div>
                <div>
                  <p className='flex flex-row gap-1 items-center justify-start'>
                    <i className="fa-solid fa-earth-europe text-[var(--text-primary)] text-lg"></i>
                    Site web
                  </p>
                  <InputFieldText
                    maxLength={2048}
                    value={website}
                    onBlur={(e) => handleLinkBlur("website", e.target.value)}
                    onChange={(e) => setWebsite(e.target.value)}
                    placeholder="https://hobs.space"
                    title=""
                    type={true}
                  />
                </div>
                {error.status && <ErrorMessage message={error.message} error={error.status} />}
              </div>
              <div className='containerButtonConfigProfile'>
                <button className='text-[var(--accent)] no-underline font-semibold uppercase text-center py-2 px-6 text-base rounded-[50px] hover:text-[var(--hover)] border-2 border-[var(--text-primary)] cursor-pointer' onClick={() => setStep(1)}>Précédent</button>
                <button
                  className={`text-[var(--text-primary)] no-underline text-center font-semibold uppercase bg-[var(--accent)] hover:bg-[var(--hover)] hover:border-[var(--hover)] cursor-pointer py-2 px-6 text-base rounded-[50px] border-2 border-[var(--accent)] buttonConfigProfile ${!error.status ? "next" : "disable"}`}
                  onClick={!error.status ? () => setStep(3) : () => {}}
                >
                  Suivant
                </button>
              </div>
              <div className='flex w-[80%] items-center justify-end '>
                <button className='text-[var(--text-primary)] hover:text-[var(--hover)] cursor-pointer mt-4 no-underline text-center font-semibold uppercase text-sm' onClick={() => setStep(3)}>Passer</button>
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <div className='containerTextConfigProfile'>
                <h1 className='titleLogin'>Votre page est presque terminée</h1>
                <p>Continuez à personnaliser votre page et partagez-la sur vos médias sociaux.</p>
              </div>
              <br />
              <div className='containerButtonConfigProfile'>
                <button
                  className={`text-[var(--text-primary)] no-underline text-center font-semibold uppercase bg-[var(--accent)] py-2 px-6 text-base rounded-[50px] border-2 border-[var(--accent)] hover:bg-[var(--hover)] hover:border-[var(--hover)] cursor-pointer buttonConfigProfile ${!error.status ? "next" : "disable"}`}
                  onClick={!error.status ? handleEnd : () => {}}
                >
                  {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Créer mon compte"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className='containerImgLogin'>
        <div className='backgroundImgLogin'></div>
      </div>
    </div>
  );
}

export default ConfigProfile;
