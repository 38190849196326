import React from 'react'
import ButtonCardAdd from '../ButtonCardAdd/ButtonCardAdd';

function SectionButtonAdd({ title, items }) {
 
    return (
        <div className='flex flex-col w-full gap-3 pb-3'>
            <p className='w-full text-left text-[var(--text-primary)] uppercase text-xs'>{title}</p>
            <div className='flex flex-row w-full items-center justify-start gap-4 flex-wrap'>
            {items.map((item, index) => (
                <ButtonCardAdd key={index} imgLogo={item.imgLogo} img={item.imgSrc} title={item.title} description={item.description} onClick={item.onClick} active={item.active} disabled={item.disabled} disabledSub={item.disabledSub}/>
            ))}
            </div>
        </div>
    );
}

export default SectionButtonAdd;