import axios from 'axios';

const IdClient = process.env.REACT_APP_CLIENT_ID_SPOTIFY;
const clientSecret = process.env.REACT_APP_SECRET_SPOTIFY;

// Configuration pour l'authentification Spotify
const authData = {
    grant_type: 'client_credentials',
    client_id: IdClient,
    client_secret: clientSecret,
};

// Fonction pour obtenir le token d'accès
const getSpotifyAccessToken = async () => {
    try {
        const res = await axios.post('https://accounts.spotify.com/api/token', new URLSearchParams(authData), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        return res.data.access_token;
    } catch (error) {
        console.error('Erreur lors de la récupération du token Spotify:', error);
        throw new Error('Impossible de récupérer le token Spotify.');
    }
};

// Fonction pour récupérer l'ID d'un artiste à partir de l'URL personnalisée
const getSpotifyArtistIdFromCustomUrl = (user) => {
    const customUrl = user.links.find(link => link.name === "spotify")?.link;
    if (customUrl && customUrl.includes('/artist/')) {
        return customUrl.split('/artist/')[1].split('?')[0]; // Extraire l'ID après '/artist/'
    }
    return null;
};

// Fonction pour appeler l'API Spotify
const fetchFromSpotify = async (endpoint, accessToken) => {
    try {
        const response = await axios.get(`https://api.spotify.com/v1/${endpoint}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    } catch (error) {
        console.error(`Erreur lors de l'appel à l'API Spotify (${endpoint}):`, error);
        return null;
    }
};

// Fonction pour rechercher une musique
export const searchSpotifyTrack = async (query, limit = 5) => {
    const token = await getSpotifyAccessToken(); // Fonction pour récupérer le token
    const response = await fetch(
        `https://api.spotify.com/v1/search?q=${encodeURIComponent(query)}&type=track&limit=${limit}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    const data = await response.json();
    // Normalisation des résultats
    return data.tracks.items.map(track => ({
        title: track.name,
        artists: track.artists.map(artist => artist.name),
        image: track.album.images[0]?.url || '', // Utilise la première image disponible
        link: track.external_urls.spotify,
        id: track.id,
        preview: track.preview_url || null, // Lien de prévisualisation
        platform: 'spotify',
    }));
};

export const getSpotifyTrackById = async (id) => {
    const token = await getSpotifyAccessToken(); // Fonction pour obtenir le token Spotify
    const response = await fetch(
        `https://api.spotify.com/v1/tracks/${id}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    const data = await response.json();
    return {
        title: data.name,
        artists: data.artists.map(artist => artist.name),
        image: data.album.images[0]?.url || '',
        link: data.external_urls.spotify,
        preview: data.preview_url || null, // Ajout du lien de prévisualisation
        platform: 'spotify',
    };
};

// Fonction pour récupérer les informations d'un artiste
export const getSpotifyArtistInfo = async (user) => {
    const accessToken = await getSpotifyAccessToken();
    const artistId = getSpotifyArtistIdFromCustomUrl(user);
    if (!artistId) return null;
    return fetchFromSpotify(`artists/${artistId}`, accessToken);
};

// Fonction pour récupérer les musiques d'un artiste (top 10)
export const getSpotifyTopTracks = async (user) => {
    const accessToken = await getSpotifyAccessToken();
    const artistId = getSpotifyArtistIdFromCustomUrl(user);
    if (!artistId) return null;
    return fetchFromSpotify(`artists/${artistId}/top-tracks?market=FR`, accessToken);
};

// Fonction pour récupérer les albums d'un artiste
export const getSpotifyAlbums = async (user, limit = 10) => {
    const accessToken = await getSpotifyAccessToken();
    const artistId = getSpotifyArtistIdFromCustomUrl(user);
    if (!artistId) return null;
    return fetchFromSpotify(`artists/${artistId}/albums?limit=${limit}`, accessToken);
};

// Fonction pour récupérer les musiques d'un album
export const getSpotifyAlbumTracks = async (albumId, limit = 10) => {
    const accessToken = await getSpotifyAccessToken();
    return fetchFromSpotify(`albums/${albumId}/tracks?limit=${limit}`, accessToken);
};

// Fonction pour rechercher des podcasts
export const searchSpotifyPodcasts = async (searchTerm, limit = 5) => {
    const token = await getSpotifyAccessToken();

    // Séparer le terme de recherche en deux parties : le podcast et l'épisode (séparés par une virgule)
    const [podcastSearchTerm, episodeSearchTerm] = searchTerm.split(',', 2).map(str => str.trim());
    console.log(searchTerm);

    // Rechercher d'abord le podcast avec le terme de recherche
    const podcastResponse = await fetch(
        `https://api.spotify.com/v1/search?q=${encodeURIComponent(podcastSearchTerm)}&type=show&limit=10`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    
    if (!podcastResponse.ok) {
        throw new Error('Error fetching podcasts');
    }

    // Récupérer les données JSON pour les podcasts
    const podcastData = await podcastResponse.json();
    console.log(podcastData);

    if (podcastData.shows.items.length > 0) {
        // Récupérer le premier podcast trouvé
        const podcast = podcastData.shows.items[0];
        const podcastId = podcast.id;
        console.log(podcast.total_episodes, podcast);

        // Récupérer tous les épisodes par pagination
        let allEpisodes = [];
        let offset = 0;
        const limitEpisodes = 50; // Limite par appel API (max 50)

        while (offset < podcast.total_episodes) {
            const episodesResponse = await fetch(
                `https://api.spotify.com/v1/shows/${podcastId}/episodes?limit=${limitEpisodes}&offset=${offset}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            const episodesData = await episodesResponse.json();

            allEpisodes = [...allEpisodes, ...episodesData.items];
            offset += limitEpisodes; // Passer au lot suivant
        }

        console.log(allEpisodes);

        // Ajouter les informations du podcast pour chaque épisode
        const sortedEpisodes = allEpisodes.map(item => ({
            title: item.name,
            image: item.images[0]?.url || '',
            link: item.external_urls.spotify,
            preview: item.audio_preview_url,
            platform: 'spotify',
            id: item.id,
            duration: null,
            releaseDate: item.release_date,
            podcastTitle: podcast.name, // Nom du podcast
            podcastCreator: podcast.publisher, // Créateur du podcast
        }));

        // Si un titre d'épisode est aussi recherché, filtrer les épisodes
        if (episodeSearchTerm) {
            const filteredEpisodes = sortedEpisodes.filter(episode =>
                episode.title.toLowerCase().includes(episodeSearchTerm.toLowerCase())
            );

            // Trier les épisodes par la pertinence du titre (basé sur la position du terme recherché)
            const sortedFilteredEpisodes = filteredEpisodes.sort((a, b) => 
                b.title.toLowerCase().indexOf(episodeSearchTerm.toLowerCase()) - a.title.toLowerCase().indexOf(episodeSearchTerm.toLowerCase())
            );
            console.log(sortedFilteredEpisodes);

            // Retourner les épisodes filtrés et triés
            return sortedFilteredEpisodes.slice(0, limit);
        }

        // Retourner les épisodes les plus récents si aucun titre d'épisode n'est donné
        return sortedEpisodes.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate)).slice(0, limit);
    }

    return []; // Retourner un tableau vide si aucun podcast n'est trouvé
};


export const getSpotifyPodcastEpisodeById = async (id) => {
    const token = await getSpotifyAccessToken();

    // Récupérer les détails de l'épisode
    const episodeResponse = await fetch(
        `https://api.spotify.com/v1/episodes/${id}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );

    if (!episodeResponse.ok) {
        throw new Error('Impossible de récupérer l\'épisode du podcast.');
    }

    const episodeData = await episodeResponse.json();

    // Récupérer le podcast associé à cet épisode
    const podcastResponse = await fetch(
        `https://api.spotify.com/v1/shows/${episodeData.show.id}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );

    if (!podcastResponse.ok) {
        throw new Error('Impossible de récupérer les informations du podcast.');
    }

    const podcastData = await podcastResponse.json();
    return {
        title: episodeData.name,
        image: episodeData.images[0]?.url || '',
        link: episodeData.external_urls.spotify,
        platform: 'spotify',
        id: episodeData.id,
        duration: null,
        preview: episodeData.audio_preview_url,
        description: episodeData.description, // Description de l'épisode
        releaseDate: episodeData.release_date, // Date de publication de l'épisode
        podcastTitle: podcastData.name, // Nom du podcast
        podcastCreator: podcastData.publisher, // Créateur (éditeur) du podcast
    };
};


export const getSpotifyPodcastById = async (id) => {
    const token = await getSpotifyAccessToken();
    const response = await fetch(
        `https://api.spotify.com/v1/shows/${id}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );

    if (!response.ok) {
        throw new Error('Impossible de récupérer le podcast.');
    }

    const data = await response.json();

    return {
        title: data.name,
        image: data.images[0]?.url || '',
        link: data.external_urls.spotify,
        platform: 'spotify',
        id: data.id,
    };
};

// Fonction pour récupérer les podcasts d'un créateur
export const getSpotifyCreatorPodcasts = async (creatorId) => {
    const accessToken = await getSpotifyAccessToken();
    return fetchFromSpotify(`shows/${creatorId}`, accessToken);
};

// Fonction pour récupérer les épisodes d'un podcast (dix derniers)
export const getSpotifyPodcastEpisodes = async (podcastId, limit = 10) => {
    const accessToken = await getSpotifyAccessToken();
    return fetchFromSpotify(`shows/${podcastId}/episodes?limit=${limit}`, accessToken);
};
