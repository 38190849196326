import React, { useState, useEffect } from 'react';
import './SignUp.css';
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import LogoBlack from "../../assets/logo-black.png";
import Google from "../../assets/google.png";
import Apple from "../../assets/apple.png";
import { setOffer, setHobsSpace } from '../../services/store/reducers/userSlice';
import { checkPasswordStrength, createUserWithEmailAndPassword, checkEmailExist, checkHobsSpaceExists, checkHobspaceIsValid } from '../../services/auth/authService';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import ConfigProfile from './ConfigProfile';
import '@fortawesome/fontawesome-free/css/all.css';
import { emailIsValid, hobsSpaceIsValid, passwordIsValid } from '../../services/store/reducers/authSlice';
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import MetaTags from '../../components/MetaTags/MetaTags';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hobsspace, setHobsspace] = useState("");
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const isChecked = useSelector((state) => state.user.offers);
  const error = useSelector((state) => state.auth.passwordIsValid);
  const errorEmail = useSelector((state) => state.auth.emailIsValid);
  const errorHobs = useSelector((state) => state.auth.hobsSpaceIsValid);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const [conf, setConf] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangeOffer = () => {
    dispatch(setOffer(!isChecked));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Vérifier l'email
    const emailVerif = await checkEmailExist(email, dispatch);
    if (!emailVerif) {
      return;
    }
    // Vérifier la robustesse du mot de passe
    const passwordVerif = checkPasswordStrength(password, dispatch);
    if (!passwordVerif) {
      return;
    }
    // Vérifier que le format du hobspace est valide
    const isHobspaceValid = checkHobspaceIsValid(hobsspace, dispatch);
    if (!isHobspaceValid) {
      return;
    }
    // Vérifier que le hobspace n'existe pas déjà
    const hobspaceExists = await checkHobsSpaceExists(hobsspace, dispatch);
    if (!hobspaceExists) {
      return;
    }
    // Si tout est bon, sauvegarder le hobspace et passer à l'étape suivante
    dispatch(setHobsSpace(hobsspace));
    setConf(true);
  };

  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme') || 'dark');
  useEffect(() => {
      // Observer pour détecter les changements de l'attribut 'data-theme'
      const observer = new MutationObserver((mutationsList) => {
          mutationsList.forEach(mutation => {
              if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
                  const currentTheme = document.documentElement.getAttribute('data-theme') || 'dark';
                  setTheme(currentTheme);
              }
          });
      });

      observer.observe(document.documentElement, { attributes: true });
      
      return () => observer.disconnect();
  }, []);

  // La fonction createUser est appelée depuis ConfigProfile
  const createUser = async (data, image) => {
    try {
      const success = await createUserWithEmailAndPassword(
        email, 
        password, 
        hobsspace, 
        data, 
        dispatch, 
        image
      );
      if (success) {
        console.log("Utilisateur créé avec succès:");
        const authInstance = getAuth();
        await signInWithEmailAndPassword(authInstance, email, password);
        // Pas de redirection immédiate ici.
        // La redirection s'effectuera dans ConfigProfile via le contexte.
      } else {
        throw new Error("La création de l'utilisateur a échoué.");
      }
    } catch (error) {
      console.error("Erreur lors de la création de l'utilisateur:", error.message);
      alert("Erreur : " + error.message);
    }
  };

  useEffect(() => {
    dispatch(passwordIsValid({ isValid: null, message: "" }));
    dispatch(emailIsValid({ isValid: null, message: "" }));
    dispatch(hobsSpaceIsValid({ isValid: null, message: "" }));
    dispatch(setOffer(false));
    dispatch(setHobsSpace(""));
  }, [dispatch]);

  return (
    <>
      <MetaTags />
      <TitleTabNavigator title={"Inscription"} />
      {conf ? (
        <ConfigProfile createUser={createUser} />
      ) : (
        <div className="flex items-center flex-col justify-start bg-[var(--background-secondary))] min-h-screen h-full gap-5">
          <div className="w-full min-h-[70px] bg-[var(--background-primary)] flex items-center justify-center ">
            <Link to="/" className="w-full min-h-[70px] bg-[var(--background-primary)] flex items-center justify-center">
              <img className="h-[40px] object-contain object-center " src={theme === "light" ? LogoBlack : Logo} alt="logo hobs space retour page d'accueil" />
            </Link>
          </div>
          <div className='flex items-center justify-center w-full pb-20 max-[425px]:pb-10' style={{height: "calc(100vh - 90px)"}}>
            <div className="flex flex-col w-[70%] max-[425px]:min-w-[350px] max-[360px]:min-w-[95%] min-w-[420px] max-w-[700px] items-center justify-center bg-[var(--card-background)] p-5 border rounded-2xl border-[var(--border)] ">
              <div className="flex flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center gap-3">
                  <h1 className="text-center text-3xl max-[425px]:text-2xl">Rejoindre Hob's Space</h1>
                  <p className="text-center px-4 py-2 rounded-full bg-[var(--accent)] max-[360px]:text-xs max-[425px]:text-sm">Inscrivez-vous gratuitement à Hob's Space</p>
                </div>
                <div className="flex flex-col items-center justify-center mt-5 max-[360px]:mt-3">
                  <form onSubmit={handleSubmit} className="flex flex-col items-center mt-3 justify-center gap-5 w-[90%]">
                    <div className="w-full">
                      <input
                        className="border caret-[var(--text-primary)] bg-[var(--card-background)] text-[var(--text-primary)] placeholder:text-[var(--text-secondary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]"
                        id="email"
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      {errorEmail === false && <ErrorMessage message={errorMessage} error={!errorEmail} />}
                    </div>
                    <div className="w-full relative flex items-center justify-center">
                      <input
                        className="border relative bg-[var(--card-background)] caret-[var(--text-primary)] text-[var(--text-primary)] placeholder:text-[var(--text-secondary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]"
                        type={passwordIsVisible ? "text" : "password"}
                        placeholder="Mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <button
                        type="button"
                        className="absolute right-3 cursor-pointer"
                        onClick={() => setPasswordIsVisible(!passwordIsVisible)}
                      >
                        {passwordIsVisible ? (
                          <i className="fa-solid fa-eye"></i>
                        ) : (
                          <i className="fa-solid fa-eye-slash"></i>
                        )}
                      </button>
                      {error === false && <ErrorMessage message={errorMessage} error={!error} />}
                    </div>
                    <div className="w-full">
                      <label className="labelSignUpHobs" htmlFor="hobs">
                        hobs-space/
                      </label>
                      <input
                        className="border-[1px] p-2 bg-[var(--card-background)] caret-[var(--text-primary)] rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]"
                        id="hobs"
                        type="text"
                        value={hobsspace}
                        onChange={(e) => setHobsspace(e.target.value.replace(/\s/g, '-'))}
                        required
                      />
                      {errorHobs === false && <ErrorMessage message={errorMessage} error={!errorHobs} />}
                    </div>
                    <div className="flex flex-row items-center cursor-pointer justify-start w-full gap-2" onClick={handleChangeOffer}>
                      <input
                        className="inputCheckboxSelectableComponent"
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => {}}
                      />
                      <span className={isChecked ? 'checkmark checked' : 'checkmark'} />
                      <span className="text-[var(--text-primary)] text-sm">
                        J'accepte de recevoir des offres, des nouvelles et des mises à jour de Hob's Space.
                      </span>
                    </div>
                    <button
                      type="submit"
                      className="text-[var(--text-primary)] hover:bg-[var(--hover)] hover:border-[var(--hover)] no-underline text-center font-semibold uppercase bg-[var(--accent)] cursor-pointer py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 border-[var(--accent)]"
                    >
                      Créez votre compte
                    </button>
                    <div className="flex flex-col items-center mt-2 justify-center gap-5 w-full">
                      <p className='text-sm w-full max-[425px]:text-xs text-center text-[var(--text-primary)]'>
                        En cliquant sur Créer un compte, vous acceptez les{' '}
                        <Link to="" className="font-bold underline underline-offset-2 hover:text-[var(--hover)] ">
                          termes et conditions générales
                        </Link>{' '}
                        de Hob's Space et confirmez que vous avez lu notre avis de{' '}
                        <Link to="" className="font-bold underline underline-offset-2 hover:text-[var(--hover)] ">
                          confidentialité
                        </Link>.
                      </p>
                    </div>
                  </form>
                  <div className="w-1/2 bg-[var(--text-secondary)] h-[1px] mx-auto my-5"></div>
                  <div className="flex items-center justify-center">
                    <p className='max-[425px]:text-sm text-center'>
                      Vous avez déjà un compte ?{' '}
                      <Link to="/login" className="underline underline-offset-2 text-nowrap hover:text-[var(--hover)]">
                        Connectez-vous
                      </Link>
                    </p>
                  </div>
                  <div className="text-[var(--text-primary)] w-[90%] mt-8 text-center text-xs">
                    <p>
                      Ce site est protégé par reCAPTCHA et les{' '}
                      <Link to="" className="underline underline-offset-2 hover:text-[var(--hover)]">
                        règles de confidentialité
                      </Link>{' '}
                      et les{' '}
                      <Link to="" className="underline underline-offset-2 hover:text-[var(--hover)]">
                        conditions d'utilisation de Google
                      </Link>{' '}
                      s'appliquent.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SignUp;
