import React, {useState} from 'react'


function ButtonCardAdd(props) {
    const social = props.title === "Linkedin" ? "LinkedIn" : "Youtube" 
    const grayBackground = props.disabled || props.disabledSub || !props.active ? true : false;
    const [theme] = useState(() => document.documentElement.getAttribute('data-theme') || 'dark');

    return (
        <button onClick={props.active && !props.disabled && !props.disabledSub ? props.onClick : () => {}} className={`relative flex flex-row w-72 h-24 justify-start items-center gap-3 bg-[var(--card-background)] border border-[var(--border)] drop-shadow-md p-3 rounded-xl hover:-translate-y-2 transition ease-in-out duration-300 ${grayBackground ? 'cursor-not-allowed bg-zinc-400 text-zinc-600' : 'cursor-pointer'}`}>
            <img src={props.img} className={`h-9 w-9 ${theme === "light" ? "" : !props.imgLogo ? "invert" : ""} object-center object-contain`} />
            <div className='flex flex-col justify-center items-start'>
            <p className='text-left text-md font-bold capitalize text-[var(--text-primary)] '>{props.title}</p>
            <p className='text-left text-xs text-[var(--text-primary)] '>{props.description}</p>
            </div>
            {!props.active &&
                <p className=' absolute py-1 px-2 text-[var(--text-primary)] rounded-full' style={{top: "-5px", right: "-10px", fontSize: "10px", backgroundColor: "#ff8500"}}>Bientôt !</p>
            }
            {props.disabled &&
                <p className=' absolute py-1 px-2 text-[var(--text-primary)] rounded-full' style={{top: "-5px", right: "-10px", fontSize: "10px", backgroundColor: "red"}}>Activez le réseau social "{social}"</p>
            }
            {props.disabled && props.title === "Plateformes Musicales" &&
                <p className=' absolute py-1 px-2 text-[var(--text-primary)] rounded-full' style={{top: "-5px", right: "-10px", fontSize: "10px", backgroundColor: "red"}}>Activez un réseau social musical</p>
            }
            {props.disabledSub &&
                <p className=' absolute py-1 px-2 text-[var(--text-primary)] rounded-full' style={{top: "-5px", right: "-10px", fontSize: "10px", backgroundColor: "#ff8500"}}>Pas dispo dans l'abonnement Basic !</p>
            }
        </button>
    );
}

export default ButtonCardAdd;