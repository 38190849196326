import React, { useState, useEffect } from 'react';
import InputFieldText from '../InputField/InputFieldText';
import { useUser } from '../../utils/contexts/userContext';
import "./OneVideo.css";
import { useDispatch } from 'react-redux';
import { setPage } from '../../services/store/reducers/userSlice';

const OneVideoEdit = ({ obj, handleCloseModal  }) => {

    const {user} = useUser();
    const [subtitleComponent, setSubtitleComponent] = useState("");
    const [boolComponent, setboolComponent] = useState(true);
    const dispatch = useDispatch();

    const handleChangeSubtitle = (newValue) => {
        setSubtitleComponent(newValue);
    };

    const handleChangeBool = () => {
        setboolComponent(!boolComponent);
    };

    const sentEditComponent = () => {
        user.updateObjectInPageById(obj.id, {idMedia: subtitleComponent, description: boolComponent});
        dispatch(setPage(user.page));
        handleCloseModal();    
    }

    useEffect(() => {
        setSubtitleComponent(obj.idMedia); 
        setboolComponent(obj.description);
    }, [user]);

    return (
        <>
        <div className='flex items-center flex-col justify-center w-full gap-6'>
            <InputFieldText value={subtitleComponent} maxLength={2048} onChange={(e) => handleChangeSubtitle(e.target.value)} placeholder={""} title={"Lien de la vidéo"} type={true} />
            <div className='containerDisplayEmail'>
                <p className='emailDisplayEmail' style={{textDecoration: boolComponent ? "" : "line-through"}}>Description</p>
                <label className="switch">
                    <input type="checkbox" checked={boolComponent} onChange={handleChangeBool} />
                    <span className="slider"></span>
                </label>
            </div>
        </div>
        <div className='containerButtonImgProfil'>
            <button className='text-red-600 no-underline font-semibold uppercase text-center py-2 px-6 text-base rounded-[50px] hover:border-red-600 hover:bg-red-600 hover:text-[var(--text-primary)] border-2 border-[var(--text-primary)] bg-[var(--text-primary)] cursor-pointer' onClick={handleCloseModal}>Annuler</button>
            <button className='text-[var(--text-primary)] no-underline text-center font-semibold uppercase bg-[var(--accent)] hover:bg-[var(--hover)] hover:border-[var(--hover)] cursor-pointer py-2 px-6 text-base rounded-[50px] border-2 border-[var(--accent)]' onClick={sentEditComponent}>Enregistrer</button>
        </div>
        </>
    );
};

export default OneVideoEdit;