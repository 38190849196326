import React, { useState, useEffect } from 'react'
import './LinksPodcast.css';
import Title from '../Title/Title';
import Slider from '../Slider/Slider';
import { useUser } from '../../utils/contexts/userContext';
import LinkPodcast from '../LinkPodcast/LinkPodcast';

function LinksPodcast(props) {
    const {user} = useUser();
    const [components, setComponents] = useState([]);

    useEffect(() => {
        const components = props.components.map((c, index) => (
            <LinkPodcast key={index} id={c.id} idMedia={c.idMedia} />
        ));
        setComponents(components);
    }, [props.components]); 
 
    return (
        <div className='LinksPodcast'>
            {props.title &&
                <Title id={props.ancre} color={user.colorFont} title={props.title} marginBottom={true} />
            }

            
            {components.length > 0 && components.length < 4 && components.map((component, index) => (
                <React.Fragment key={index}>
                    <div className='flex flex-col items-center justify-center gap-3 w-full mt-3'>
                        {component}
                    </div>
                </React.Fragment>
            ))}

            {components.length >= 4 && 
                <Slider components={components} column={true} />   
            }
        </div>
    );
}

export default LinksPodcast;