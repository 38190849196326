import React, { useEffect } from 'react';
import "./Dashboard.css";
import NavigationDashboard from '../../Layouts/Navigation/NavigationDashboard/NavigationDashboard';
import { useUser } from "../../utils/contexts/userContext";
import Loader from "../../components/Loader/Loader";
import { Link, useNavigate } from 'react-router-dom';
import useScreenWidth from '../../utils/hook/useScreenWidth';
import Notify from '../../components/Notify/Notify';
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import MetaTags from '../../components/MetaTags/MetaTags';
import Logo from "../../assets/logo.png";

function Dashboard() {
  const bgdContainer = "#fff";
  const colorFont = "255, 255, 255";
  const { user, loading, isAuthenticated } = useUser();
  const screenWidth = useScreenWidth();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirige si l'utilisateur n'est pas authentifié après chargement
    if (!loading && !isAuthenticated) {
      navigate("/");
    }
  }, [loading, isAuthenticated, navigate]);

  return (
    <>
      <MetaTags/>
      <TitleTabNavigator title={"Dashboard"}/>
      {screenWidth >= 1150 ? 
        <div className='Dashboard'>
          {!loading && isAuthenticated ? (
            <NavigationDashboard color={colorFont} bgd={bgdContainer} />
          ) : (
            <Loader />
          )}
          <Notify/>
        </div>
      : 
      <div className="flex items-center flex-col justify-start bg-[var(--background-secondary))] min-h-screen h-full gap-5">
          <div className="w-full min-h-[70px] bg-[var(--background-primary)] flex items-center justify-center ">
            <Link to="/" className="w-full min-h-[70px] bg-[var(--background-primary)] flex items-center justify-center">
              <img className="h-[40px] object-contain object-center " src={Logo} alt="logo hobs space retour page d'accueil" />
            </Link>
          </div>
          <div className='flex items-center justify-center w-full pb-20 max-[425px]:pb-10' style={{height: "calc(100vh - 90px)"}}>
            <div className="flex flex-col w-[70%] max-[425px]:min-w-[350px] max-[360px]:min-w-[95%] min-w-[420px] max-w-[700px] items-center justify-center bg-[var(--card-background)] p-5 border rounded-2xl border-[var(--border)] ">
              <div className="flex flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center gap-3">
                  <h1 className="text-center text-3xl max-[425px]:text-2xl">Dashboard bientôt sur mobile</h1>
                  <p className="text-center px-4 py-2 max-[360px]:text-xs max-[425px]:text-sm">Notre dashboard mobile est en cours de développement, veuillez vous connecter sur un ordinateur.</p>
                </div>
                  <div className="w-1/2 bg-[var(--text-secondary)] h-[1px] mx-auto my-5"></div>
                  <div className="flex items-center justify-center">
                    <p className='max-[425px]:text-sm text-center'>
                      Retouner sur la page d'
                      <Link to="/" className="underline underline-offset-2 text-nowrap hover:text-[var(--hover)]">
                        Accueil
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      }
    </>
  );
}

export default Dashboard;
