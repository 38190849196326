import React, { useState, useEffect } from 'react';
import InputFieldText from '../InputField/InputFieldText';
import { useUser } from '../../utils/contexts/userContext';
import './LinkTikTok.css';
import { useDispatch } from 'react-redux';
import { setPage } from '../../services/store/reducers/userSlice';
import { isValidUrl } from '../../utils';
import Short from '../../assets/youtube-short.png';
import Tiktok from '../../assets/tiktok-color.png';
import Reels from '../../assets/reels.png';

const LinkTikTokEdit = ({ obj, handleCloseModal }) => {
  const { user } = useUser();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [links, setLinks] = useState({});
  const dispatch = useDispatch();

  const platforms = [
    { name: 'tiktok', logo: Tiktok },
    { name: 'short', logo: Short },
    { name: 'reels', logo: Reels },
  ];

  useEffect(() => {
    if (obj.idMedia) {
      setSelectedPlatforms(Object.keys(obj.idMedia));
      setLinks(obj.idMedia);
    }
  }, [obj.idMedia]);

  const addPlatform = (platform) => {
    if (selectedPlatforms.includes(platform)) {
      // Si la plateforme est déjà sélectionnée, on la retire et on met à jour 'links'
      setSelectedPlatforms(prev => prev.filter(p => p !== platform));
      setLinks(prevLinks => {
        const { [platform]: removed, ...rest } = prevLinks;
        return rest;
      });
    } else {
      // Sinon, on l'ajoute et on initialise sa valeur à une chaîne vide
      setSelectedPlatforms(prev => [...prev, platform]);
      setLinks(prevLinks => ({ ...prevLinks, [platform]: '' }));
    }
  };

  const handleChange = (platform, value) => {
    setLinks((prev) => ({ ...prev, [platform]: value }));
    setErrors((prev) => ({ ...prev, [platform]: isValidUrl(value) ? '' : 'URL invalide' }));
  };

  const handleSave = () => {
    if (Object.values(errors).some((error) => error)) return;
    user.updateObjectInPageById(obj.id, { idMedia: links });
    dispatch(setPage(user.page));
    handleCloseModal();
  };

  return (
    <div className="flex items-center flex-col justify-center w-full">
      <p className="w-full text-left text-sm mb-3">Séléctionnez une ou plusieurs plateformes :</p>
      <div className="platformSelection">
        {platforms.map(({ name, logo }) => (
          <button
            key={name}
            onClick={() => addPlatform(name)}
            className={`platformButton ${selectedPlatforms.includes(name) ? 'selected' : ''}`}
          >
            <img className="w-5 h-5 object-contain object-center rounded" src={logo} alt={name} title={name} />
          </button>
        ))}
      </div>
      <div className="flex flex-col w-full gap-2 mt-3">
        {selectedPlatforms.map((platform) => (
          <InputFieldText
            key={platform}
            value={links[platform] || ''}
            onChange={(e) => handleChange(platform, e.target.value)}
            placeholder={`Entrez l'URL d'une vidéo ${platform}`}
            title={`${platform.charAt(0).toUpperCase() + platform.slice(1)}${errors[platform] ? " - " + errors[platform] : ""}`}
            type="text"
          />
        ))}
      </div>
      <div className="containerButtonImgProfil">
        <button className="text-red-600 no-underline font-semibold uppercase text-center py-2 px-6 text-base rounded-[50px] hover:border-red-600 hover:bg-red-600 hover:text-[var(--text-primary)] border-2 border-[var(--text-primary)] bg-[var(--text-primary)] cursor-pointer" onClick={handleCloseModal}>Annuler</button>
        <button className="text-[var(--text-primary)] no-underline text-center font-semibold uppercase bg-[var(--accent)] hover:bg-[var(--hover)] hover:border-[var(--hover)] cursor-pointer py-2 px-6 text-base rounded-[50px] border-2 border-[var(--accent)]" onClick={handleSave}>Enregistrer</button>
      </div>
    </div>
  );
};

export default LinkTikTokEdit;
