import React, { useState } from 'react';
import './ButtonEditImage.css';
import '@fortawesome/fontawesome-free/css/all.css';
import CustomModal from '../Modal/CustomModal/CustomModal';
import { uploadImage } from '../../services/storage/storageService';
import { useUser } from "../../utils/contexts/userContext";
import { useSelector } from "react-redux";
import { truncateFileName, getFileIcon } from "../../utils";

function ButtonUploadFile(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(undefined);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // État pour le loader
    const { user } = useUser();
    const email = useSelector((state) => state.user.email);

    const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200MB

    const validFileTypes = [
        'application/msword', // .doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'application/vnd.oasis.opendocument.text', // .odt
        'application/pdf', // .pdf
        'application/rtf', // .rtf
        'text/plain', // .txt
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'application/vnd.oasis.opendocument.spreadsheet', // .ods
        'text/csv', // .csv
        'application/vnd.ms-powerpoint', // .ppt
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
        'application/vnd.oasis.opendocument.presentation', // .odp
        'application/epub+zip', // .epub
        'application/x-mobipocket-ebook', // .mobi
        'application/vnd.amazon.ebook', // .azw, .azw3
        'application/x-ibooks+zip' // .ibooks
    ];

    const validFileExtensions = [
        '.doc', '.docx', '.odt', '.pdf', '.rtf', '.txt', 
        '.xls', '.xlsx', '.ods', '.csv', 
        '.ppt', '.pptx', '.odp', 
        '.epub', '.mobi', '.azw', '.azw3', '.ibooks'
    ];

    const handleButtonClick = () => setIsModalOpen(true);

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setFile(undefined);
        setError(false);
        setIsLoading(false); // Réinitialiser le loader
    };

    const handleFileSelected = (files) => {
        const selectedFile = files[0];
        if (validateFile(selectedFile)) {
            setFile(selectedFile);
            console.log(selectedFile);
            setError(false);
            if (props.setFile) {
                props.setFile(selectedFile);
                props.setTitle(selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.')));
            }
        } else {
            setError(true);
        }
    };

    const validateFile = (file) => {
        return validFileTypes.includes(file.type) && file.size <= MAX_FILE_SIZE;
    };

    const handleDrop = (event) => {
        event.preventDefault();
        handleFileSelected(event.dataTransfer.files);
    };

    const handleDragOver = (event) => event.preventDefault();

    const handleModalClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = validFileExtensions.join(', ');
        input.onchange = (event) => handleFileSelected(event.target.files);
        input.click();
    };

    const uploadFile = async () => {
        if (user && file) {
            setIsLoading(true); // Activer le loader
            try {
                await uploadImage(file, email, props.folder, props.nameFile);
                handleCloseModal();
            } catch (error) {
                console.error('Error uploading file:', error);
                setError(true);
            } finally {
                setIsLoading(false); // Désactiver le loader
            }
        } else {
            setError(true);
        }
    };

    return (
        <>
            <button
                className='border text-[var(--text-primary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]'
                onClick={handleButtonClick}
            >
                <i className="fa-solid fa-arrow-up-from-bracket" style={{ color: "var(--text-primary)", marginRight: "8px", fontSize: "16px" }}></i>
                Sélectionner un fichier
            </button>
            <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
                <div className='containerSelectImgProfil'>
                    <p className='titleModalSettings'>{props.title}</p>
                    <div
                        className='dropzone'
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={handleModalClick}
                    >
                        {file ? (
                            <div className='flex flew-row w-full items-center justify-center gap-3'>
                                <img className='flex items-center justify-center object-contain object-center w-14 h-14' src={getFileIcon(file)} alt="preview" />
                                <p className='fileName'>{truncateFileName(file.name)}</p>
                            </div>
                        ) : (
                            <div className='iconButtonImgProfil'>
                                <i className="fa-solid fa-cloud-arrow-up"></i>
                            </div>
                        )}
                        <p style={{ color: error ? "red" : "black" }}>
                            {error ? "Fichier invalide ou trop volumineux." : "Déposez un fichier ici ou cliquez pour sélectionner un fichier"}
                        </p>
                    </div>
                    <div className='containerButtonImgProfil'>
                        <button className='text-red-600 no-underline font-semibold uppercase text-center py-2 px-6 text-base rounded-[50px] hover:border-red-600 hover:bg-red-600 hover:text-[var(--text-primary)] border-2 border-[var(--text-primary)] bg-[var(--text-primary)] cursor-pointer' onClick={handleCloseModal}>Annuler</button>
                        <button 
                            className='text-[var(--text-primary)] no-underline text-center font-semibold uppercase bg-[var(--accent)] hover:bg-[var(--hover)] hover:border-[var(--hover)] cursor-pointer py-2 px-6 text-base rounded-[50px] border-2 border-[var(--accent)]' 
                            onClick={file ? uploadFile : () => setError(true)} 
                            disabled={isLoading}
                        >
                            {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Enregistrer"}
                        </button>
                    </div>
                </div>
            </CustomModal>
        </>
    );
}

export default ButtonUploadFile;
