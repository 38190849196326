import React from 'react'
import './MusicAccount.css';
import Title from '../Title/Title';
import MusicProfil from '../MusicProfil/MusicProfil';
import { useUser } from '../../utils/contexts/userContext';

function MusicAccount(props) {
  const {user} = useUser();

    return (
      <div className='MusicAccount'>
        {props.title &&
          <Title id={props.ancre} color={user.colorFont} title={props.title} marginBottom={true} />
        }
        <MusicProfil description={props.description} />
      </div>
    );
}

export default MusicAccount;