import { db, storage, auth } from '../firebase/firebase.config';
import { doc, setDoc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import User from "./user";
import { ref, listAll, uploadBytes, getDownloadURL, deleteObject, getMetadata } from 'firebase/storage';
import { setNotify } from '../store/reducers/notifySlice';
import { updateProfile, getAuth, deleteUser, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { setImages, setBackgroundImage, setBannerImage } from "../store/reducers/userSlice";
import { passwordIsValid } from '../store/reducers/authSlice';

const cleanData = (obj) => {
    if (Array.isArray(obj)) {
        return obj
            .map(cleanData) // Nettoie chaque élément
            .filter((item) => item !== undefined && item !== null); // Retire les éléments invalides
    } else if (obj && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const value = obj[key];
            if (value !== undefined && value !== null) {
                acc[key] = cleanData(value); // Applique le nettoyage récursivement
            }
            return acc;
        }, {});
    }
    return obj; // Retourne directement la valeur si ce n'est pas un tableau ou un objet
};

export const createUser = async (user, userInfo, image) => {
    try {
        if (!user.email) {
            throw new Error('Email utilisateur manquant ou invalide.');
        }
        // Crée un nouvel objet User avec les informations
        const newUser = new User(userInfo);

        if (userInfo.firstname || userInfo.lastname) {
            await updateProfile(user, { displayName: `${userInfo.firstname} ${userInfo.lastname}`});
        }

        await setDoc(doc(db, 'users', user.email), userInfo);
        await initializeUserStorage(user.email, image);
        console.log('Utilisateur créé avec succès.');
    } catch (error) {
        console.error("Erreur lors de la création de l'utilisateur:", error.message);
    }
};

export const updateUser = async (user, email, dispatch) => {
    try {
        const updatedData = user.toFirestore();
        console.log(updatedData);
        const filteredData = cleanData(updatedData);

        if (Object.keys(filteredData).length === 0) {
            console.log('Aucune modification détectée.', filteredData);
            dispatch(setNotify({ type: false, message: 'Aucune modification à mettre à jour.' }));
            return;
        }

        console.log('Données nettoyées:', JSON.stringify(filteredData, null, 2));

        // Utiliser l'utilisateur authentifié de Firebase pour mettre à jour le displayName
        updateUserProfile(user, dispatch);

        await setDoc(doc(db, 'users', email), filteredData, { merge: true });
        dispatch(setNotify({ type: true, message: 'Utilisateur mis à jour avec succès.' }));
        console.log('Utilisateur mis à jour avec succès:', filteredData);
    } catch (error) {
        console.error('Erreur lors de la mise à jour de l\'utilisateur:', error.message);
        dispatch(setNotify({ type: false, message: 'Erreur lors de la mise à jour de l\'utilisateur.' }));
    }
};

export const updateUserProfile = async(user, dispatch) => {
    try {
        const firebaseUser = getAuth().currentUser;
        if ((user.firstname || user.lastname) && firebaseUser) {
            await updateProfile(firebaseUser, { displayName: `${user.firstname} ${user.lastname}` });
            dispatch(setNotify({type: true, message: 'Profil utilisateur mis à jour avec succès.'}));
        }
    } catch (error) {
        console.error("Erreur lors de la mise à jour du profil utilisateur :", error);
        dispatch(setNotify({type: false, message: 'Erreur lors de la mise à jour du profil de l\'utilisateur.'}));
        throw error;
  }
}

export const saveHobsSpace = async (hobsSpaceName, email) => {
    try {
        // Enregistrer les données dans le document créé
        await setDoc(doc(db, "hobs-space", hobsSpaceName), { email: email });
        console.log(`Le hobs space '${hobsSpaceName}' a été enregistré avec succès avec l'email '${email}'.`);
    } catch (error) {
        console.error('Erreur lors de l\'enregistrement du hobs space avec email:', error.message);
        throw error; // Facultatif : relancer l'erreur pour la gérer à un niveau supérieur
    }
};

export const savePortailManager = async (email, data) => {
    try {
        // Enregistrer les données dans le document créé
        await setDoc(doc(db, "portail-manager", email), data);
        console.log(`Le portail manager de '${email}' a été enregistré avec succès avec les données '${data}'.`);
    } catch (error) {
        console.error('Erreur lors de l\'enregistrement du hobs space avec email:', error.message);
        throw error; // Facultatif : relancer l'erreur pour la gérer à un niveau supérieur
    }
};

export const updatePortailManager = async (email, updatedInfo, dispatch) => {
    try {
        await updateDoc(doc(db, 'portail-manager', email), updatedInfo);
        console.log(updatedInfo)
        dispatch(setNotify({ type: true, message: "Hob's Space initial des collaborateurs a été mis à jour." }));
    } catch (error) {
        console.error('Erreur lors de la mise à jour des informations de l\'utilisateur:', error.message);
        dispatch(setNotify({ type: false, message: "Erreur lors de la mise à jour du Hob's Space initial des collaborateurs." }));
    }
};

export const getUserInfo = async (email) => {
    try {
        const userDocRef = doc(db, 'users', email);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userInstance = User.fromFirestore(userData);
          console.log("Données de l'utilisateur récupérées:", userInstance, userData);
        } else {
          console.log("Aucune donnée d'utilisateur trouvée.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération ou de la création de l'utilisateur:", error);
      }
};

export const updateUserInfo = async (email, updatedInfo, dispatch) => {
    try {
        await updateDoc(doc(db, 'users', email), updatedInfo);
        console.log('Informations utilisateur mises à jour avec succès.');
        dispatch(setNotify({type: true, message: 'Informations utilisateur mises à jour avec succès.'}));
    } catch (error) {
        console.error('Erreur lors de la mise à jour des informations de l\'utilisateur:', error.message);
        dispatch(setNotify({type: false, message: 'Erreur lors de la mise à jour des informations de l\'utilisateur.'}));
    }
};

const deleteFolderContents = async (folderRef) => {
    const listResult = await listAll(folderRef);
    // Supprimer les fichiers du dossier courant
    const deleteFilesPromises = listResult.items.map(itemRef => deleteObject(itemRef));
    // Supprimer récursivement les fichiers des sous-dossiers
    const deleteSubfoldersPromises = listResult.prefixes.map(subfolderRef => deleteFolderContents(subfolderRef));
    await Promise.all([...deleteFilesPromises, ...deleteSubfoldersPromises]);
};

export const deleteUserInfo = async (email, password, hobspace, dispatch, navigate) => {
    try {
      // Récupérer l'utilisateur authentifié
      const currentUser = auth.currentUser;
      if (!currentUser || currentUser.email !== email) {
        dispatch(passwordIsValid({ type: false, message: "Aucun utilisateur authentifié correspondant à l'email trouvé." }));
        return;
      }
    
      // Tenter la ré-authentification avec le mot de passe fourni
      const credential = EmailAuthProvider.credential(currentUser.email, password);
      await reauthenticateWithCredential(currentUser, credential);
    
      // Si la ré-authentification réussit, on procède aux suppressions
      
      // Supprimer le document dans hobs-space
      await deleteDoc(doc(db, 'hobs-space', hobspace));
    
      // Supprimer le document dans users
      await deleteDoc(doc(db, 'users', email));
    
      // Supprimer le dossier de stockage correspondant à l'email de l'utilisateur de manière récursive
      const userStorageRef = ref(storage, email);
      await deleteFolderContents(userStorageRef);
    
      // Supprimer l'utilisateur authentifié
      await deleteUser(currentUser);
      navigate('/');
    } catch (error) {
      // Si l'erreur provient d'une ré-authentification échouée (mot de passe incorrect)
      if (error.code === "auth/wrong-password" || error.code === "auth/requires-recent-login") {
        dispatch(passwordIsValid({ type: false, message: "Mot de passe incorrect. Impossible de supprimer l'utilisateur." }));
      } else {
        dispatch(passwordIsValid({ type: false, message: "Erreur lors de la suppression de l'utilisateur: " + error.message }));
      }
    }
};

const initializeUserStorage = async (userEmail, image) => {
    try {
        console.log(image)
        if(image){
            await uploadImage(image, userEmail, "profile", "profile-image");
        }
        else {
            // Create a placeholder file (you can't directly create an empty folder in Firebase Storage)
            //const placeholderFile = new Blob(['This is a placeholder file.'], { type: 'text/plain' });
            const storagePath = `${userEmail}/`;
            const storageRef = ref(storage, storagePath);
            await uploadBytes(storageRef);
        }

        console.log('User storage initialized for:', userEmail);
    } catch (error) {
        console.error('Error initializing user storage:', error);
        throw error;
    }
};

export const uploadImage = async (file, userEmail, subfolder = '', newFileName = '') => {
    try {
        // Déterminez le nom du fichier, ajoutez un timestamp pour éviter les conflits de noms
        const baseFileName = newFileName || file.name.split('.')[0]; // Nom du fichier sans extension
        const fileExtension = file.type.split('/')[1]; 
        const fileName = `${baseFileName}.${fileExtension}`;
        const filePath = `${userEmail}/${subfolder}/${fileName}`;
        const storageRef = ref(storage, filePath);

        // Liste et supprime les fichiers existants avec des noms similaires
        const folderPath = `${userEmail}/${subfolder}`;
        const folderRef = ref(storage, folderPath);
        const fileList = await listAll(folderRef);

        const filesToDelete = fileList.items.filter(itemRef => {
            // Vérifie si le nom de base du fichier est contenu dans le nom du fichier existant
            return itemRef.name.includes(baseFileName) && itemRef.name !== fileName;
        });

        const deletePromises = filesToDelete.map(itemRef => deleteObject(itemRef));
        await Promise.all(deletePromises);

        // Télécharge le nouveau fichier
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.log('File uploaded successfully:', downloadURL);
        return downloadURL;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
};

export const getImages = async (userEmail, subfolder = '') => {
    try {
        const folderPath = `${userEmail}/${subfolder}`;
        const folderRef = ref(storage, folderPath);
        const fileList = await listAll(folderRef);
        // Vérifier s'il y a des images dans le dossier
        if (fileList.items.length === 0) {
            throw new Error("No images found in the folder.");
        }
        // Récupérer les URLs de téléchargement pour chaque élément dans la liste
        const urls = await Promise.all(fileList.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            return downloadURL;
        }));
        return urls;
    } catch (error) {
        console.error('Error fetching images:', error);
        throw error;
    }
};

export const deleteFile = async (userEmail, subfolder = '', fileNameWithoutExtension = '') => {
    try {
        const folderPath = `${userEmail}/${subfolder}`;
        console.log('Looking for file in folder:', folderPath);

        const folderRef = ref(storage, folderPath);

        // Lister tous les fichiers dans le dossier
        const fileList = await listAll(folderRef);

        // Rechercher le fichier correspondant au nom sans extension
        const fileToDelete = fileList.items.find((file) =>
            file.name.startsWith(fileNameWithoutExtension)
        );

        if (!fileToDelete) {
            console.warn(`No file found matching the name: ${fileNameWithoutExtension}`);
            return;
        }

        // Supprimer le fichier trouvé
        await deleteObject(fileToDelete);
        console.log('File deleted successfully:', fileToDelete.name);
    } catch (error) {
        console.error('Error deleting file:', error.message);
        throw error;
    }
};

export const getImageComponent = async (userEmail, folderName = '', componentId = '') => {
    try {
        // Créez le chemin vers le dossier
        const folderPath = `${userEmail}/${folderName}`;
        const folderRef = ref(storage, folderPath);
        
        // Récupérer tous les fichiers dans le dossier
        const fileList = await listAll(folderRef);
        
        // Récupérer les fichiers qui correspondent au componentId sans l'extension
        const matchingFiles = await Promise.all(
            fileList.items.map(async (itemRef) => {
                const fileName = itemRef.name.split('.')[0]; // Obtenez le nom du fichier sans l'extension
                if (fileName === componentId) {
                    const downloadURL = await getDownloadURL(itemRef);
                    return downloadURL; // Retourner l'URL du fichier correspondant
                }
                return null; // Si ce n'est pas un match, retourner null
            })
        );

        // Filtrer les résultats pour obtenir l'URL valide
        const downloadURLs = matchingFiles.filter(url => url !== null);

        if (downloadURLs.length > 0) {
            return downloadURLs[0]; // Retourner la première URL correspondante
        } else {
            throw new Error('File not found');
        }
    } catch (error) {
        console.error('Error fetching image/video:', error);
        throw error;
    }
};

export const getImageProfil = async (dispatch, email) => {
    try {
        const img = await getImages(email, "profile");
        if (img !== undefined) {
            dispatch(setImages(img));
        }
    } catch (error) {
        console.error("Error fetching profile image:", error.message);
    }

    try {
        const bgdImg = await getImages(email, "backgroundImage");
        if (bgdImg !== undefined) {
            dispatch(setBackgroundImage(bgdImg));
        }
    } catch (error) {
        console.error("Error fetching background image:", error.message);
    }

    try {
        const bannerImg = await getImages(email, "banner");
        if (bannerImg !== undefined) {
            dispatch(setBannerImage(bannerImg));
        }
    } catch (error) {
        console.error("Error fetching banner image:", error.message);
    }
};

export const renameDocument = async(docname, oldId, newId) => {
    const oldDocRef = doc(db, docname, oldId);
    const newDocRef = doc(db, docname, newId);
  
    // Récupérer les données du document existant
    const oldDocSnap = await getDoc(oldDocRef);
    if (oldDocSnap.exists()) {
      const data = oldDocSnap.data();
      // Créer le nouveau document avec les mêmes données
      await setDoc(newDocRef, data);
      // Supprimer l'ancien document
      await deleteDoc(oldDocRef);
      console.log("Document renommé avec succès !");
    } else {
      console.log("Le document n'existe pas !");
    }
}