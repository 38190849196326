import React from 'react'
import styles from './LinkConcert.module.css';
import { useSelector } from 'react-redux';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import { hexToRgb } from '../../../utils';

function LinkConcert(props) {
    const {hobspace} = useHobspace();
    const bgdImage = useSelector(state => state.hobspace.backgroundImage);

 
    return (
        
        <a className={styles.LinkConcert} style={{ border: bgdImage ? null : `${hobspace.backgroundColor} 1px solid`, backgroundColor: bgdImage ? hexToRgb(hobspace.backgroundColor) : `rgba(255,255,255,0.15)`, color: hobspace.colorFont }} href={props.type ? props.link : null} rel="noreferrer" target='_blank'>
            <div className={`containerInfoConcert`}>
                <p className={`${styles.event} text-xl max-md:text-lg max-425:text-base max-350:text-sm line-clamp-1 max-md:line-clamp-2`} style={{color: hobspace.colorFont}}>{props.title}</p>
                <p className={`${styles.where} text-lg max-md:text-base max-425:text-sm max-350:text-xs line-clamp-1 max-md:line-clamp-2`} style={{color: hobspace.colorFont}}>{props.where}</p>
                {props.type ? (
                    <a className={styles.ticketOk} href={props.type ? props.link : null} rel="noreferrer" target='_blank' style={{ border: `${hobspace.colorFont} 1px solid`, color: hobspace.colorFont }}>Tickets</a>
                ) : (
                    <p className={styles.ticketSoldout} style={{ border: `${hobspace.colorFont} 1px solid`, color: hobspace.colorFont }}>Sold out</p>
                )}
            </div>
            <div className={styles.separationTicket}>
                <div className={styles.bulleTop} style={{ backgroundColor: bgdImage ? hobspace.colorFont : hobspace.backgroundColor }}></div>
                <div className={styles.bulles}>
                    {[1, 2, 3, 4, 5].map((_, index) => (
                        <div key={index} className={styles.bul} style={{ backgroundColor: bgdImage ? hobspace.colorFont : hobspace.backgroundColor }}></div>
                    ))}
                </div>
                <div className={styles.bulleBottom} style={{ backgroundColor: bgdImage ? hobspace.colorFont : hobspace.backgroundColor }}></div>
            </div>
            <div className={styles.dateConcert}>
                <div className={styles.containerDateConcert} style={{ border: `${hobspace.colorFont} 1px solid`}}>
                    <p className={styles.mounth} style={{color: hobspace.colorFont }}>{props.mounth}</p>
                    <p className={styles.day} style={{color: hobspace.colorFont }}>{props.day}</p>
                    <p className={styles.year} style={{color: hobspace.colorFont }}>{props.year}</p>
                </div>
            </div>
        </a>
    );
}

export default LinkConcert;