import React, { useState, useEffect } from 'react'
import styles from './Links.module.css';
import Title from '../Title/Title';
import Slider from '../Slider/Slider';
import Article from '../Article/Article';
import Link from '../Link/Link';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

function Links(props) {
    const {hobspace} = useHobspace();
    const [components, setComponents] = useState([]);

    useEffect(() => {
        const components = props.components.map((c, index) => (
            <Link key={index} id={c.id} title={c.title} subtitle={c.subtitle} link={c.link} email={props.email} />
        ));
        setComponents(components);
    }, [props.components]); 
 
    return (
        <div className={styles.Links}>
            {props.title &&
                <Title id={props.ancre} color={hobspace.colorFont} title={props.title} marginBottom={true} />
            }
            
            {components.length > 0 && components.length < 4 && components.map((component, index) => (
                <React.Fragment key={index}>
                    <div className='flex flex-col items-center justify-center gap-6 w-full mt-6 max-350:gap-4 max-350:mt-4'>
                        {component}
                    </div>
                </React.Fragment>
            ))}
            
            {components.length >= 4 && 
                <Slider components={components} column={true} />   
            }
        </div>
    );
}

export default Links;