import React from "react";
import styles from "./Banner.module.css"; // Fichier CSS séparé

const Banner = ({ image }) => {
  return (
    <>
    <div className={styles.header}>
      <svg width="0" height="0">
        <defs>
          <clipPath id="minimal-01-mask" clipPathUnits="objectBoundingBox">
            <path d="M 0,1 L 0,0 L 1,0 L 1,1 C .65 .8, .35 .8, 0 1 Z" />
          </clipPath>
        </defs>
      </svg>

      <div className={styles.coverWrapper}>
        <img
          alt="Cover"
          src={image}
          className={styles.cover}
          style={{ clipPath: "url(#minimal-01-mask)" }} // Appliquer le clipPath
        />
      </div>

    </div>
    <div className={styles.divBanner}></div>
    </>
  );
};

export default Banner;
