import { isHexColor } from "../../utils";
import { BILLING_TYPE, TYPE_SUBSCRIPTION } from "../../utils/enums";

export default class User {
    constructor(data) {
        this.offer = data.offer;
        this.nameSpace = data.nameSpace;
        this.firstname = data.firstname;
        this.description = data.description;
        this.lastname = data.lastname;
        this.phoneNumber = data.phoneNumber;
        this.country = data.country;
        this.colorFont = data.colorFont;
        this.backgroundColor = data.backgroundColor;
        this.links = data.links;
        this.page = data.page;
        this.pageInit = data.pageInit;
        this.subscription = data.subscription;
        this.contactActive = data.contactActive;
        this.qrcode = data.qrcode;
        // Créer une copie de l'état actuel et l'attribuer à `previousState`
        // Object.defineProperty(this, 'previousState', {
        //     value: { ...data },
        //     writable: true,
        //     configurable: true,
        //     enumerable: false // Ne sera pas inclus lors de JSON.stringify ou d'autres sérialisations
        // });
    }

    updateSubscription(newType, billingType) {
        const now = new Date();
        // Check if newType is a valid subscription type
        if (!Object.values(TYPE_SUBSCRIPTION).includes(newType)) {
            console.error(`Invalid subscription type: ${newType}`);
            return;
        }

        // Check if billingType is a valid billing type
        if (!Object.values(BILLING_TYPE).includes(billingType)) {
            console.error(`Invalid billing type: ${billingType}`);
            return;
        }

        // Calculate howLong based on billing type
        let newHowLong;
        switch (billingType) {
            case BILLING_TYPE.MONTHLY:
                newHowLong = new Date(now.setMonth(now.getMonth() + 1)).toISOString();
                break;
            case BILLING_TYPE.ANNUALLY:
                newHowLong = new Date(now.setFullYear(now.getFullYear() + 1)).toISOString();
                break;
            case BILLING_TYPE.WEEKLY:
                newHowLong = new Date(now.setDate(now.getDate() + 7)).toISOString();
                break;
            default:
                console.error(`Unhandled billing type: ${billingType}`);
                return;
        }


        this.subscription.type = newType;
        this.subscription.howLong = newHowLong;
        console.log("Abonnement mis à jour:", this.subscription);
    }

    updateLink(links) {
        this.links = links;
    };
    
    updateQRCode(qrcode) {
        this.qrcode = qrcode;
    };

    deleteQRCode(id) {
        this.qrcode = this.qrcode.filter(qr => qr.id !== id);
    }

    reorderLink(newOrder) {
        this.links = newOrder;
    }

    removeLink(name) {
        this.links = this.links.filter(link => link.name !== name);
    }

    updateLinkByName(name, newLink) {
        const updateRecursive = (links) => {
            return links.map(link => {
                if (link.name === name) {
                    return { ...link, link: newLink }; // Met à jour uniquement les propriétés spécifiées
                }
                return link; // Lien non modifié
            });
        };

        this.links = updateRecursive(this.links);
    }

    updateColorFont(color) {
        if (isHexColor(color)) {
            this.colorFont = color;
        } else {
            console.error("Invalid color format. Please provide a valid hex color.");
        }
    };

    updateBackgroundColor(color) {
        if (isHexColor(color)) {
            this.backgroundColor = color;
        } else {
            console.error("Invalid color format. Please provide a valid hex color.");
        }
    };

    updateFirstname(firstname) {
        this.firstname = firstname;
    }

    updateLastname(lastname) {
        this.lastname = lastname;
    }

    updateNameSpace(nameSpace) {
        this.nameSpace = nameSpace;
    }

    updateDescription(description) {
        this.description = description;
    }

    updateCountry(country) {
        this.country = { code: country.code, name: country.name };
    };

    updateContactActive(email, phone) {
        this.contactActive = {
            email: email,
            phone: phone,
        }
    }

    updatePhoneNumber(number) {
        this.phoneNumber = number;
    }

    updatePage(page) {
        this.page = page;
    };

    reorderObjectToComponents(id, newOrder, component) {
        // Fonction récursive pour parcourir et mettre à jour les objets imbriqués
        const updateRecursive = (items) => {
            return items.map(item => {
                if (item.id === id) {
                    if (component === "Link" && item.componentTickets) {
                        console.log(newOrder, id);
                        return { ...item, componentTickets: newOrder };
                    } else if (component === "Article" && item.componentArticles) {
                        console.log(newOrder);
                        return { ...item, componentArticles: newOrder };
                    } else if (item.components) {
                        return { ...item, components: newOrder };
                    }
                }

                // Si l'objet contient des sous-listes, appliquer la fonction récursive
                if (item.components) {
                    return { ...item, components: updateRecursive(item.components) };
                }


                return item;
            });
        };

        this.page = updateRecursive(this.page);
    }

    addObjectToComponents(id, newComponent) {
        // Fonction récursive pour parcourir et mettre à jour les objets imbriqués
        const updateRecursive = (items) => {
            return items.map(item => {
                if (item.id === id) {
                    // Vérifier si l'objet a une propriété "components"
                    if (item.components) {
                        return { ...item, components: [...item.components, newComponent] };
                    }
                    if (item.componentTickets && newComponent.name === "Link") {
                        console.log(newComponent);
                        return { ...item, componentTickets: [...item.componentTickets, newComponent] };
                    }

                    if (item.componentArticles && newComponent.name === "Article") {
                        console.log(newComponent);
                        return { ...item, componentArticles: [...item.componentArticles, newComponent] };
                    }
                }

                // Si l'objet contient des sous-listes, appliquer la fonction récursive
                if (item.components) {
                    return { ...item, components: updateRecursive(item.components) };
                }

                return item;
            });
        };

        this.page = updateRecursive(this.page);
    }

    removeObjectById(id) {
        // Fonction récursive pour parcourir et mettre à jour les objets imbriqués
        const updateRecursive = (items) => {
            return items.map(item => {
                // Si l'objet a l'ID recherché, le supprimer de la liste
                if (item.id === id) {
                    return null;
                }

                // Si l'objet contient des sous-listes, appliquer la fonction récursive
                if (item.components) {
                    return { ...item, components: updateRecursive(item.components).filter(subItem => subItem !== null) };
                }
                return item;
            }).filter(item => item !== null);
        };

        this.page = updateRecursive(this.page);
        console.log(`Object with ID ${id} removed.`);
    }

    updateObjectInPageById(id, updates) {
        const updateRecursive = (items) => {
            return items.map(item => {
                if (item.id === id) {
                    return { ...item, ...updates };
                }
                if (item.components) {
                    return { ...item, components: updateRecursive(item.components) };
                }
                return item;
            });
        };

        this.page = updateRecursive(this.page);
    }

    updateImageById(id, newImage) {
        // Fonction récursive pour parcourir et mettre à jour les objets imbriqués
        const updateRecursive = (items) => {
            return items.map(item => {
                // Si l'objet a l'ID recherché, mettre à jour sa propriété image
                if (item.id === id) {
                    return { ...item, image: newImage };
                }

                // Si l'objet contient des sous-listes, appliquer la fonction récursive
                if (item.components) {
                    return { ...item, components: updateRecursive(item.components) };
                }

                return item;
            });
        };

        this.page = updateRecursive(this.page);
        console.log(`Image updated for ID ${id}: ${newImage}`);
    }

    hasYouTubeLink() {
        return this.links.some(link => link.name === 'youtube' && link.active && link.link.includes('youtube.com'));
    }

    hasMusicLinks() {
        const requiredLinks = ['deezer', 'spotify', 'applemusic'];
    
        return requiredLinks.every(requiredLink =>
            this.links.some(link =>
                link.name === requiredLink && link.active && link.link
            )
        );
    }

    // toFirestore() {
    //     const updatedFields = {};

    //     // Fonction récursive pour comparer les objets
    //     const compareObjects = (current, previous) => {
    //         const changes = {};
    //         for (const key in current) {
    //             // Vérifie si la valeur est un objet
    //             if (typeof current[key] === 'object' && current[key] !== null) {
    //                 if (Array.isArray(current[key])) {
    //                     // Compare les tableaux
    //                     const arrayChanges = compareArrays(current[key], previous[key] || []);
    //                     if (arrayChanges.length > 0) {
    //                         changes[key] = arrayChanges;
    //                     }
    //                 } else {
    //                     // Compare récursivement les objets
    //                     const nestedChanges = compareObjects(current[key], previous[key] || {});
    //                     if (Object.keys(nestedChanges).length > 0) {
    //                         changes[key] = nestedChanges;
    //                     }
    //                 }
    //             } else {
    //                 // Compare les valeurs simples
    //                 if (current[key] !== previous[key]) {
    //                     changes[key] = current[key];
    //                 }
    //             }
    //         }
    //         return changes;
    //     };

    //     // Fonction pour comparer deux tableaux
    //     const compareArrays = (currentArray, previousArray) => {
    //         const changes = [];

    //         currentArray.forEach((currentItem, index) => {
    //             const previousItem = previousArray[index] || null;

    //             if (typeof currentItem === 'object' && currentItem !== null) {
    //                 // Compare récursivement les objets dans les tableaux
    //                 const nestedChanges = compareObjects(currentItem, previousItem || {});
    //                 if (Object.keys(nestedChanges).length > 0) {
    //                     changes[index] = { ...previousItem, ...nestedChanges };
    //                 }
    //             } else if (currentItem !== previousItem) {
    //                 // Compare les valeurs simples dans les tableaux
    //                 changes[index] = currentItem;
    //             }
    //         });

    //         // Ajoute les éléments en trop dans le tableau précédent (si un élément a été supprimé)
    //         if (currentArray.length < previousArray.length) {
    //             for (let i = currentArray.length; i < previousArray.length; i++) {
    //                 changes[i] = null; // Ou autre logique pour signifier une suppression
    //             }
    //         }

    //         return changes;
    //     };

    //     // Compare l'état actuel avec l'état précédent
    //     const changes = compareObjects(this, this.previousState);

    //     // Met à jour `updatedFields` avec les changements détectés
    //     Object.assign(updatedFields, changes);

    //     // Met à jour l'état précédent
    //     this.previousState = JSON.parse(JSON.stringify(this));

    //     console.log('Changements détectés :', changes);
    //     return updatedFields;
    // }

    toFirestore() {
        return {
            offer: this.offer,
            nameSpace: this.nameSpace,
            firstname: this.firstname,
            description: this.description,
            lastname: this.lastname,
            phoneNumber: this.phoneNumber,
            country: this.country,
            colorFont: this.colorFont,
            backgroundColor: this.backgroundColor,
            links: this.links,
            page: this.page,
            pageInit: this.pageInit,
            subscription: this.subscription,
            contactActive: this.contactActive,
            qrcode: this.qrcode,
        };
    }

    static fromFirestore(data) {
        // Créer et retourner une nouvelle instance de User avec les données
        return new User(data);
    }

    // static resetUser() {
    //     this.offer = null;
    //     this.nameSpace = "";
    //     this.lastname = "Smith";
    //     this.firstname = "Carl";
    //     this.description = "Entrer une description de 140 caractères maximum";
    //     this.phoneNumber = "";
    //     this.country = {code: "FR", name: "France",};
    //     this.colorFont = "#ffffff";
    //     this.backgroundColor = "#000000";
    //     this.links = [];
    //     this.page = [];
    //     this.pageInit = [{name: "Profile", vertical: true, logo: false}];
    //     this.contactActive = {
    //         email: false,
    //         phone: false,
    //     };
    //     this.subscription = {
    //         type: "",
    //         howLong: "",
    //         createAt: "",
    //     };
    // }
}
