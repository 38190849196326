import React, { useState, useEffect } from 'react';
import { useUser } from '../../utils/contexts/userContext';
import "./LinksPodcast.css";
import InputFieldText from '../InputField/InputFieldText';
import DragAndDropContainer from '../DragAndDropContainer/DragAndDropContainer';
import { componentsSpace, generateUniqueId } from '../../utils';
import { Trash2, PencilLine } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { setPage, setMenu } from '../../services/store/reducers/userSlice';

const LinksPodcastEdit = ({ renderComponentContent, container, deleteBlock }) => {
    const {user} = useUser();
    const [visibility, setVisibility] = useState(true);
    const [edit, setEdit] = useState(false);
    const [title, setTitle] = useState(null);
    const dispatch = useDispatch();

    const handleChangeTitle = (newValue) => {
        setTitle(newValue);
    };

    const updateTitleComposant = (id) => {
        if(title !== null){
            user.updateObjectInPageById(id, { title: title });
            dispatch(setPage(user.page));
            const anchors = user.page.map(component => component.title);
            dispatch(setMenu(anchors));
        }
        setEdit(false);
    }

    const removeComponents = (id) => {
        user.removeObjectById(id);
        dispatch(setPage(user.page));
    };

    const reorderListById = (list, newOrder) => {
        const orderMap = new Map(newOrder.map((item, index) => [item.id, index]));
        return list.slice().sort((a, b) => orderMap.get(a.id) - orderMap.get(b.id));
    };

    const handleReorderComponents = (id, newOrder) => {
        // Trouver l'élément correspondant à l'ID
        const pageComponent = user.page.find((component) => component.id === id);
        
        // Si l'élément n'existe pas ou s'il n'a pas de composants, retourner pour éviter l'erreur
        if (!pageComponent || !pageComponent.components) {
            console.error("L'élément avec cet ID n'existe pas ou n'a pas de composants.");
            return;
        }
    
        // Réordonner les composants
        const newOrderComponents = reorderListById(pageComponent.components, newOrder);
        
        // Mettre à jour les composants réordonnés
        user.reorderObjectToComponents(id, newOrderComponents);
        dispatch(setPage(user.page));
    };   

    const updateComponentsUser = (componentToAdd, id) => {
        const newActiveComponents = componentsSpace
        .find(page => page.name === componentToAdd);
        if (newActiveComponents) {
            const updatedComponent = { ...newActiveComponents, id: generateUniqueId() };
            user.addObjectToComponents(id, updatedComponent);  
            dispatch(setPage(user.page));
        }
    };
    
    useEffect(() => {
        if(container) {
            setTitle(container.title);
        }
    }, [container]);

    return (
        <div className='flex h-auto mt-2 items-center'>
            {container &&
            <div className='flex flex-col w-full'>
                <div className='flex flex-row w-full justify-between gap-6'>
                {edit ? (
                    <InputFieldText 
                        value={title} 
                        maxLength={256}
                        onChange={(e) => handleChangeTitle(e.target.value)} 
                        placeholder={title} 
                        title={""} 
                        type={true}
                        onBlur={() => updateTitleComposant(container.id)} // Envoie la modification lorsque l'input perd le focus
                        onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault(); // Empêche le comportement par défaut (soumission de formulaire)
                            updateTitleComposant(container.id); // Envoie la modification lorsque l'utilisateur appuie sur "Enter"
                        }
                        }}
                    />    
                    ) : (
                    <p className="mr-2 text-lg">{title}</p>
                    )}

                <div className='flex flex-row gap-2'>
                    <button className='buttonDragAndDrop' onClick={() => setVisibility(!visibility)}>
                    {visibility ? (
                        <i className="fa-solid fa-eye" style={{color: "var(--text-primary)"}}></i>
                    ) : (
                        <i className="fa-solid fa-eye-slash" style={{color: "var(--text-primary)"}}></i>
                    )}
                    </button>
                    <button className='buttonDragAndDrop' onClick={() => setEdit(!edit)}><PencilLine /></button>
                    <button className='buttonDragAndDrop' onClick={() => deleteBlock(container.id)}>
                    <Trash2 />
                    </button>
                </div>
                </div>
                <div className='textDragAndDrop flex items-center justify-between mt-2'>
                    <div className="flex items-start flex-col w-full">
                        <button
                            onClick={() => updateComponentsUser("LinkPodcast", container.id)}
                            className="flex p-2 text-xs gap-1 text-[var(--text-primary)] items-center flex-start bg-[var(--accent)] rounded-full"
                        >
                            <div className="relative flex w-3 h-3 items-center justify-center">
                                <div className={`absolute w-3 h-0.5 rounded-full bg-[var(--text-primary)]`}></div>
                                <div className={`absolute w-0.5 h-3 rounded-full bg-[var(--text-primary)]`}></div>
                            </div>
                            Ajouter du contenu
                        </button>
                
                        <div className="flex flex-col mt-3 w-full max-h-[250px] overflow-y-auto p-2 bg-[var(--card-background)] border border-[var(--text-secondary)] rounded-lg">
                            {container.components && container.components.length > 0 ? (
                                <DragAndDropContainer
                                    droppableId={container.id}
                                    initialContainers={container.components.map((component, index) => ({
                                        ...component,
                                        renderContent: () => (
                                            <React.Fragment key={index}>
                                                {renderComponentContent("LinkPodcast", component)}
                                            </React.Fragment>
                                        )
                                    }))}
                                    deleteBlock={(id) => removeComponents(id)}
                                    onReorder={(reorderedComponents) =>
                                        handleReorderComponents(container.id, reorderedComponents)
                                    }
                                    editComponent={true}
                                />
                            ) : (
                                <p></p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

export default LinksPodcastEdit;
