import React, { useState, useEffect } from 'react';
import styles from './LinkTikTok.module.css';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../../utils';
import { getYoutubeShortOEmbedData } from '../../../services/api/youtubeShort';
import { getInstagramReelsOEmbedData } from '../../../services/api/instagram';
import { getTiktokOEmbedData } from '../../../services/api/tiktok';

function LinkTikTok({ idMedia, openModal }) {
  // Si on reçoit onClose, le composant est utilisé en mode "modal contrôlée" (géré par le parent)
  // Sinon, on gère notre propre modal via showModal.
  const [oEmbedData, setOEmbedData] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [activePlatform, setActivePlatform] = useState(null);

  const bgdImage = useSelector((state) => state.hobspace.backgroundImage);
  const { hobspace } = useHobspace();

  // 1. Extraire les plateformes valides et définir la première comme active par défaut.
  useEffect(() => {
    if (idMedia && typeof idMedia === 'object') {
      const entries = Object.entries(idMedia)
        .map(([platform, link]) => ({
          platform,
          link: (link || '').trim()
        }))
        .filter(item => item.link);
      if (entries.length > 0) {
        setActivePlatform(entries[0]);
      }
    }
  }, [idMedia]);

  // 2. À chaque changement de la plateforme active, récupérer les données oEmbed.
  useEffect(() => {
    async function fetchOEmbed() {
      setOEmbedData(null);
      if (!activePlatform) return;
      const { platform, link } = activePlatform;
      try {
        let data = null;
        switch (platform) {
          case 'short':
            data = await getYoutubeShortOEmbedData(link);
            break;
          case 'tiktok':
            data = await getTiktokOEmbedData(link);
            break;
          case 'reels':
            data = await getInstagramReelsOEmbedData(link, { maxwidth: 500 });
            break;
          default:
            data = null;
        }
        setOEmbedData(data);
        setThumbnailUrl(data?.thumbnail_url || '');
      } catch (err) {
        console.error('Erreur lors de la récupération oEmbed:', err);
        setOEmbedData(null);
      }
    }
    fetchOEmbed();
  }, [activePlatform]);

  // 3. Charger le script TikTok si nécessaire.
  useEffect(() => {
    if (activePlatform?.platform === 'tiktok' && oEmbedData?.html) {
      const script = document.createElement('script');
      script.src = 'https://www.tiktok.com/embed.js';
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [activePlatform, oEmbedData]);

  return (
      <div className={styles.LinkTikTok}>
        <button
          className="flex items-center justify-center rounded-xl relative overflow-hidden w-[180px] h-80"
          onClick={openModal}
          style={{
            backgroundColor: bgdImage ? hexToRgb(hobspace.backgroundColor) : 'rgba(255,255,255,0.15)'
          }}
        >
          {thumbnailUrl && (
            <img className={styles.LinkTikTokThumbnail} src={thumbnailUrl} alt="thumbnail" />
          )}
          <div
            className={styles.play}
            style={{
              color: '#FFF',
              backgroundColor: hexToRgb('#000', 0.6),
              position: 'absolute'
            }}
          >
            <i className="fa-solid fa-play" />
          </div>
        </button>
      </div>
  );
}

export default LinkTikTok;
