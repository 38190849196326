import React, { useState, useEffect } from 'react';
import { getLatestYoutubeVideos } from '../../services/api/youtube';
import { useUser } from '../../utils/contexts/userContext';
import "./VideoCollection.css";
import { Trash2, PencilLine } from 'lucide-react';
import InputFieldText from '../InputField/InputFieldText';
import ButtonEditUnderComponent from '../ButtonEditComponent/ButtonEditUnderComponent';
import { useDispatch } from 'react-redux';
import { setPage, setMenu } from '../../services/store/reducers/userSlice';

const VideoCollectionEditComponent = ({ container, renderComponentIcon, deleteBlock}) => {
    const [loading, setLoading] = useState(true);
    const {user} = useUser();
    const [visibility, setVisibility] = useState(true);
    const [edit, setEdit] = useState(false);
    const [title, setTitle] = useState(null);
    const [videos, setVideos] = useState(undefined);
    const dispatch = useDispatch();

    const handleChangeTitle = (newValue) => {
        setTitle(newValue);
    };

    const updateTitleComposant = (id) => {
        if(title !== null){
            user.updateObjectInPageById(id, { title: title });
            dispatch(setPage(user.page));
            const anchors = user.page.map(component => component.title);
            dispatch(setMenu(anchors));
        }
        setEdit(false);
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const v = await getLatestYoutubeVideos(user, container.nbVideo);
            if (v === null) {
                setLoading(false);
                return;
            }
            setVideos(v);
            setLoading(false); // Stop loading once the videos are set
        }
        
        fetchData();
        if(container) {
            setTitle(container.title);
        }
    }, [container.nbVideo, container, user]);
    console.log(videos);
    return (
        <div className="flex items-start flex-col w-full">
            <div className='flex flex-row w-full justify-between gap-6 my-2'>
                {edit ? (
                    <InputFieldText 
                        value={title} 
                        maxLength={256}
                        onChange={(e) => handleChangeTitle(e.target.value)} 
                        placeholder={title} 
                        title={""} 
                        type={true}
                        onBlur={() => updateTitleComposant(container.id)} // Envoie la modification lorsque l'input perd le focus
                        onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault(); // Empêche le comportement par défaut (soumission de formulaire)
                            updateTitleComposant(container.id); // Envoie la modification lorsque l'utilisateur appuie sur "Enter"
                        }
                        }}
                    />    
                    ) : (
                    <p className="mr-2 text-lg">{title}</p>
                    )}

                <div className='flex flex-row gap-2'>
                    <button className='buttonDragAndDrop' onClick={() => setVisibility(!visibility)}>
                    {visibility ? (
                        <i className="fa-solid fa-eye" style={{color: "var(--text-primary)"}}></i>
                    ) : (
                        <i className="fa-solid fa-eye-slash" style={{color: "var(--text-primary)"}}></i>
                    )}
                    </button>
                    <button className='buttonDragAndDrop' onClick={() => setEdit(!edit)}><PencilLine /></button>
                    <button className='buttonDragAndDrop' onClick={() => deleteBlock(container.id)}>
                    <Trash2 />
                    </button>
                </div>
            </div>
            <ButtonEditUnderComponent obj={container} textButton={"Modifier les paramètres"}/>
            <div className="flex flex-col mt-3 w-full">
                <div className="flex flex-row h-auto w-full justify-start items-center gap-1">
                    <img
                        className="w-7 h-7 object-contain"
                        src={renderComponentIcon('Video')}
                        alt="Video Icon"
                    />
                    <p className=''>
                        {!loading && user?.links?.find(link => link.name === "youtube")?.link
                            ? "@" + user.links.find(link => link.name === "youtube").link.replace("@", "").split("/").pop()
                            : "Chaîne inconnue"}
                    </p>
                </div>
                <div className="max-h-[250px] overflow-y-auto p-2 border border-[var(--text-secondary)] bg-[var(--card-background)] rounded-lg">
                    {loading && <p>Chargement...</p>}
                    {!loading && videos?.length > 0 ? (
                        <div className="flex flex-col space-y-4">
                            {videos?.map((video, index) => (
                                <div key={index} className="flex items-center bg-white rounded-lg p-2">
                                    <div className="flex flex-col h-16 w-16 rounded-lg bg-gray-800 mr-2 items-center justify-center">
                                        <img
                                            className="w-11 h-11 object-contain"
                                            src={renderComponentIcon('Video')}
                                            alt="Video Icon"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <strong className="w-full text-left text-lg truncate titleOverflow2">
                                            {video.title}
                                        </strong>
                                        <p className="truncate w-full text-left titleOverflow2 text-blue-500 text-sm font-bold">
                                            {video.url}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        !loading && <p>Aucune vidéo disponible.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VideoCollectionEditComponent;
