import React, { useState, useEffect } from 'react';
import "./File.css";
import InputFieldText from '../InputField/InputFieldText';
import { useUser } from '../../utils/contexts/userContext';
import { getImageComponent } from '../../services/storage/storageService';
import { useDispatch, useSelector } from 'react-redux';
import ButtonUploadFile from '../ButtonEditImage/ButtonUploadFile';
import { truncateFileName, getFileIcon, getFileExtension, formatAncre } from '../../utils';
import { setPage } from '../../services/store/reducers/userSlice';

const FileEdit = ({ obj, handleCloseModal }) => { 
    const { user } = useUser();
    const email = useSelector((state) => state.user.email);
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();
    const [titleComponent, setTitleComponent] = useState("");
    const [subtitleComponent, setSubtitleComponent] = useState("");

    const handleChangeTitle = (newValue) => {
        setTitleComponent(newValue);
    };

    const handleChangeSubtitle = (newValue) => {
        setSubtitleComponent(newValue);
    };

    const sentEditComponent = () => {
        user.updateObjectInPageById(obj.id, { title: titleComponent, subtitle: subtitleComponent });
        dispatch(setPage(user.page));
        handleCloseModal();
    };

    useEffect(() => {
        setTitleComponent(obj.title);
        setSubtitleComponent(obj.subtitle);
    }, [user, obj.title, obj.subtitle]);

    useEffect(() => {
        const fetchMedia = async () => {
            if (obj) {
                try {
                    // Récupération de l'URL du fichier depuis Firestore
                    const url = await getImageComponent(email, 'File', obj.id);

                    // Téléchargement du fichier en tant que Blob
                    const response = await fetch(url);
                    const blob = await response.blob();

                    // Transformation du Blob en objet File
                    const fileObject = new File([blob], `${formatAncre(obj.title)}${getFileExtension(blob)}`, { type: blob.type });
                    setFile(fileObject); // Mise à jour de l'état avec l'objet File
                } catch (error) {
                    console.error('Erreur lors de la récupération du média:', error);
                    setFile(null);
                }
            }
        };

        fetchMedia();
    }, [obj, email]);

    return (
        <>
            <div className='flex items-center flex-col justify-center w-full gap-6'>
                <InputFieldText 
                    value={titleComponent} 
                    maxLength={256} 
                    onChange={(e) => handleChangeTitle(e.target.value)} 
                    placeholder={""} 
                    title={"Titre"} 
                    type={true} 
                />
                <InputFieldText 
                    value={subtitleComponent} 
                    maxLength={256} 
                    onChange={(e) => handleChangeSubtitle(e.target.value)} 
                    placeholder={""} 
                    title={"Sous-titre"} 
                    type={true} 
                />
                <div className='flex flex-col justify-center items-center w-full gap-3'>
                    <p className='InputFieldTextTitle'>
                        Fichier <span className='text-xs text-[var(--text-secondary)] lowercase mr-1'>
                            (.doc, .docx, .pdf, .txt, .xls, .xlsx, .ppt, .pptx)
                        </span>
                    </p>
                    {file && (
                        <p className='text-base text-left text-black w-full'>
                            {truncateFileName(file.name)}
                        </p>
                    )}
                    <div className='containerEditImgComponent'>
                        {file ? 
                            <img className='ImgEditComponent' src={getFileIcon(file)} alt="File Icon" />
                        :
                            <div className='ImgEditComponent'>
                                <i className={"fa-solid fa-image"}></i>
                            </div>
                        }
                        <ButtonUploadFile 
                            title={"Charger un fichier"} 
                            folder={"File"} 
                            nameFile={obj.id} 
                            setFile={setFile} 
                            setTitle={setTitleComponent}
                        />
                    </div>
                </div>
            </div>
            <div className='containerButtonImgProfil'>
                <button className='text-red-600 no-underline font-semibold uppercase text-center py-2 px-6 text-base rounded-[50px] hover:border-red-600 hover:bg-red-600 hover:text-[var(--text-primary)] border-2 border-[var(--text-primary)] bg-[var(--text-primary)] cursor-pointer' onClick={handleCloseModal}>Annuler</button>
                <button className='text-[var(--text-primary)] no-underline text-center font-semibold uppercase bg-[var(--accent)] hover:bg-[var(--hover)] hover:border-[var(--hover)] cursor-pointer py-2 px-6 text-base rounded-[50px] border-2 border-[var(--accent)]' onClick={sentEditComponent}>Enregistrer</button>
            </div>
        </>
    );
};

export default FileEdit;
