// src/contexts/UserContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../../services/firebase/firebase.config';
import User from '../../services/storage/user';
import { useDispatch } from 'react-redux';
import { getImageProfil } from '../../services/storage/storageService';
import { setEmail } from '../../services/store/reducers/userSlice';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (userAuth) => {
      console.log("User authentifié:", userAuth);
      if (userAuth) {
        const userDocRef = doc(db, 'users', userAuth.email);
        // On écoute en temps réel le document utilisateur
        const unsubscribeSnapshot = onSnapshot(
          userDocRef,
          (docSnap) => {
            console.log("User document exists:", docSnap.exists());
            if (docSnap.exists()) {
              const userData = docSnap.data();
              const userInstance = User.fromFirestore(userData);
              setUser(userInstance);
              dispatch(setEmail(userAuth.email));
            } else {
              setUser(null);
            }
            setLoading(false);
          },
          (error) => {
            console.error("Erreur lors de l'écoute du document utilisateur:", error);
            setLoading(false);
          }
        );
        // Récupération de l'image de profil (les erreurs sont loguées si besoin)
        getImageProfil(dispatch, userAuth.email).catch((error) =>
          console.error("Erreur lors de la récupération de l'image utilisateur :", error.message)
        );
        return () => {
          unsubscribeSnapshot();
        };
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, [dispatch]);

  // L'utilisateur est authentifié si "user" est non nul et le chargement est terminé
  const isAuthenticated = !!user && !loading;

  return (
    <UserContext.Provider value={{ user, loading, isAuthenticated }}>
      {children}
    </UserContext.Provider>
  );
};
