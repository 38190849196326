import React, { useState, useEffect, useRef } from 'react';
import './NavigationHome.css';
import Logo from "../../../assets/logo.png";
import LogoBlack from "../../../assets/logo-black.png";
import { Link } from "react-router-dom";
import { useUser } from "../../../utils/contexts/userContext";
import { logout } from '../../../services/auth/authService';
import { useDispatch, useSelector } from "react-redux";
import UserProfileMenu from '../../../components/UserProfilMenu/UserProfilMenu';

function NavigationHome(props) {
  const [showUserProfileMenu, setShowUserProfileMenu] = useState(false);
  const images = useSelector((state) => state.user.images);
  const email = useSelector((state) => state.user.email);
  const { user, isAuthenticated } = useUser();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  // Gestion du thème : on initialise à partir de l'attribut data-theme de <html>
  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme') || 'dark');
  useEffect(() => {
      // Observer pour détecter les changements de l'attribut 'data-theme'
      const observer = new MutationObserver((mutationsList) => {
          mutationsList.forEach(mutation => {
              if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
                  const currentTheme = document.documentElement.getAttribute('data-theme') || 'dark';
                  setTheme(currentTheme);
              }
          });
      });

      observer.observe(document.documentElement, { attributes: true });
      
      return () => observer.disconnect();
  }, []);

  // Références pour le conteneur du profil en mobile et en desktop
  const mobileProfileRef = useRef(null);
  const desktopProfileRef = useRef(null);

  // Toggle du menu (mobile)
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setShowUserProfileMenu(!showUserProfileMenu);
  };

  const handleLogout = async () => {
    await logout(dispatch);
    setMenuOpen(false);
    setShowUserProfileMenu(false);
  };

  const toggleUserProfileMenu = () => {
    setShowUserProfileMenu(!showUserProfileMenu);
  };

  // Ferme le menu si le clic se fait en dehors des conteneurs mobile ou desktop
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (mobileProfileRef.current && mobileProfileRef.current.contains(event.target)) ||
        (desktopProfileRef.current && desktopProfileRef.current.contains(event.target))
      ) {
        return;
      }
      setShowUserProfileMenu(false);
      setMenuOpen(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="NavigationHome">
      <Link to="/" className="containerLogoNavigationHome">
        <img
          src={theme === "light" ? LogoBlack : Logo}
          alt="Hobs space logo"
          className="logo"
        />
      </Link>
      {/* Version mobile */}
      <div ref={mobileProfileRef} className="containerNavigationButtonMobile">
        <button className="containerHamburgermenu" onClick={toggleMenu}>
          <div className={`linehamburger ${menuOpen ? 'open' : ''}`}></div>
          <div className={`linehamburger ${menuOpen ? 'open' : ''}`}></div>
          <div className={`linehamburger ${menuOpen ? 'open' : ''}`}></div>
        </button>
        <UserProfileMenu
          isAuthenticated={isAuthenticated}
          user={user}
          showUserProfileMenu={showUserProfileMenu}
          toggleUserProfileMenu={toggleUserProfileMenu}
          images={images}
          handleLogout={handleLogout}
          mobileView={true}
        />
      </div>
      <div className="containerNavigationButton">
        <Link to="/support" className="linkNavigation">
          Support
        </Link>
        <Link to="/contact" className="linkNavigation">
          Contact
        </Link>
        {isAuthenticated ? (
          <div ref={desktopProfileRef} style={{ position: 'relative', display: 'inline-block' }}>
            <button className="linkNavigationProfil signup" onClick={toggleUserProfileMenu}>
              {images ? (
                <img src={images} alt="profil" className="imgProfilNavigation" />
              ) : (
                <div className="imgProfilNavigation">
                  <i className="fa-regular fa-user"></i>
                </div>
              )}
              <p className="nameProfilNavigation">{user?.nameSpace}</p>
            </button>
            <UserProfileMenu
              isAuthenticated={isAuthenticated}
              user={user}
              showUserProfileMenu={showUserProfileMenu}
              toggleUserProfileMenu={toggleUserProfileMenu}
              images={images}
              handleLogout={handleLogout}
              mobileView={false}
            />
          </div>
        ) : (
          <>
            <Link to="/login" className="linkNavigation">
              Se Connecter
            </Link>
            <Link to="/sign-up" className="linkNavigation signup">
              S'inscrire
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export default NavigationHome;
