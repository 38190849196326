import React, {useState, useEffect} from 'react';
import './Profil.css';
import { Link } from "react-router-dom";
import { copyToClipboard } from '../../utils';
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../services/firebase/firebase.config';
import { useUser } from "../../utils/contexts/userContext";
import { countries } from '../../utils';

function Profil(props) {
    const [visible, setVisible] = useState(false);
    const [open, setOpen] = useState(false);
    const images = useSelector((state) => state.user.images);
    const email = useSelector((state) => state.user.email);
    const { user } = useUser();

    const handleCopy = () => {
        copyToClipboard(`https://hobs.space/${user.nameSpace}`, setVisible);
    };

    const handleShareClick = async () => {
        if (navigator.share) {
          try {
            await navigator.share({
              title: "Découvrez mon Hob's Space !",
              url: `https://hobs.space/${user.nameSpace}`,
            });
          } catch (error) {
            console.error("Erreur lors du partage natif :", error);
          }
        } else {
          // Affiche le menu personnalisé si l'API Web Share n'est pas disponible
          setOpen(!open);
        }
    };      
    
      // Copier le lien dans le presse-papiers
    const handleCopyShare = async () => {
        try {
          await navigator.clipboard.writeText(`https://hobs.space/${user.nameSpace}`);
          alert("Lien copié dans le presse-papiers !");
        } catch (error) {
          console.error("Erreur lors de la copie du lien :", error);
        }
    };
    
    // Préparation des liens de partage pour chaque réseau
    const encodedUrl = encodeURIComponent(`https://hobs.space/${user.nameSpace}`);
    const encodedTitle = encodeURIComponent("Découvrez mon Hob's Space !");
    const shareLinks = [
        { name: "Facebook", url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}` },
        { name: "Twitter", url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}` },
        { name: "LinkedIn", url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}` },
        { name: "WhatsApp", url: `https://api.whatsapp.com/send?text=${encodedTitle}%20${encodedUrl}` },
        { name: "Email", url: `mailto:?subject=${encodedTitle}&body=${encodedUrl}` },
        // Vous pouvez ajouter d'autres réseaux si nécessaire
    ];

    return (
        <div className='Profil'>
            <div className='containerProfil'>
                <div className={`containerImgProfil bg-[var(--card-background)] ${images !== undefined ? "" : "active"}`}>
                    {images !== undefined ? 
                        <img src={images} className='imgUserProfil' alt='profile' />
                    :
                        <i style={{color: "var(--text-primary)"}} className={"fa-regular fa-user"}></i>
                    }
                </div>
                <div className='containerTextProfil'>
                    <div className='containerInfoUser'>
                        <div className='countryProfil bg-[var(--background-primary)] border border-[var(--border)] '>
                            <ReactCountryFlag
                                countryCode={user.country.code}
                                svg
                                style={{
                                    width: '15px',
                                    height: '10px',
                                    borderRadius: '3px',
                                    objectFif: "cover",
                                }}
                                title={user.country.name}
                            />
                            <p className='nameCountryProfil'>{user.country.name}</p>
                        </div>
                        <p className='nameUserProfil'>{user.firstname} {user.lastname}</p>
                        <p className='emailUserProfil'>{email}</p>
                    </div>
                    <div className='profilLine'></div>
                    <div className='containerHobsSpaceUserProfil'>
                        <div className='containerTopHobsSpaceUserProfil'>
                            <p className='titleHobsSpaceUserProfil'>Hob's Space</p>
                            <div className='containerCustomUrlProfil'>
                                <Link className='linkUrlProfil' to={'/me'}>Personnaliser l'url</Link>
                                <div className='iconButtonUrlProfil'>
                                    <i style={{color: "#ff8500"}} className={"fa-solid fa-arrow-right"}></i>
                                </div>
                            </div>
                        </div>
                        <div className='containerBttomHobsSpaceUserProfil'>
                            <button className='containerUrlUserProfil bg-[var(--background-primary)] border border-[var(--border)] ' onClick={handleCopy}>
                                <div className='iconButton'>
                                    <i style={{color: "var(--accent)"}} className={"fa-solid fa-link"}></i>
                                </div>
                                <p className='linkUrlHobsSpace'>{`https://hobs.space/${user.nameSpace}`}</p>
                                <p className={`textCopy ${visible ? 'visible' : ''}`}>Url Copié</p>
                            </button>
                            <button className='shareUrlHobsSpace' onClick={handleShareClick}>
                                <div className='iconButton'>
                                    <i style={{color: "#fff"}} className={"fa-solid fa-paper-plane"}></i>
                                </div>
                                <p className='textShareUrlHobsSpace'>partager l'url</p>
                            </button>
                            {open && (
                                <div
                                style={{
                                    position: "absolute",
                                    top: "100%",
                                    left: 0,
                                    background: "white",
                                    border: "1px solid #ccc",
                                    padding: "10px",
                                    zIndex: 1000,
                                }}
                                >
                                <h4>Partager sur</h4>
                                <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                                    {shareLinks.map((item) => (
                                    <li key={item.name} style={{ margin: "5px 0" }}>
                                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                        {item.name}
                                        </a>
                                    </li>
                                    ))}
                                    <li style={{ margin: "5px 0" }}>
                                    <button onClick={handleCopyShare}>Copier le lien</button>
                                    </li>
                                </ul>
                                <button onClick={() => setOpen(false)}>Fermer</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default Profil;
