import React, { useState, useRef, useEffect } from 'react';
import QRCodeStyling from 'qr-code-styling';
import styles from './QrCodeGenerator.module.css';
import "../DashboardPages.css";
import Profil from '../../Profil/Profil';
import { Download, Trash2, User } from "lucide-react";
import InputFieldText from '../../../components/InputField/InputFieldText';
import ButtonUploadFile from '../../../components/ButtonEditImage/ButtonUploadFile';
import html2canvas from "html2canvas";
import { jsPDF } from 'jspdf';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage } from '../../../services/storage/storageService';
import { useUser } from '../../../utils/contexts/userContext';
import { updateUser } from '../../../services/storage/storageService';
import { generateNewId } from '../../../utils/generate';

// Valeurs par défaut
const defaultStyleQrCode = {
    data: "https://example.com",
    dotColor: "#000000",
    dotStyle: "square",
    squareColor: "#000000",
    squareStyle: "square",
    bgColor: "#ffffff",
    logo: undefined,
};

const defaultStyleText = {
    text: "Scannez-moi !",
    radius: 10,
    bgColor: "#000000",
    color: "#FFFFFF",
};

function QrCodeGenerator() {
    const qrCodeRef = useRef(null);
    const qrCodeInstance = useRef(null);
    const qrCodeStyle = useRef(null);
    const { user } = useUser();
    const [downloadFormat, setDownloadFormat] = useState("png");
    // contentType true : à partir d'un lien, false : à partir d'un fichier
    const [contentType, setContentType] = useState(true);
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();
    const email = useSelector((state) => state.user.email);
    const [titleComponent, setTitleComponent] = useState("");
    const [titleQRcode, setTitleQRcode] = useState(`QR Code #${Object.keys(user?.qrcode || {}).length + 1}`);
    const qrcodeId = generateNewId(user);

    const [styleQrCode, setStyleQrCode] = useState(() => {
        const saved = localStorage.getItem("styleQrCode");
        return saved ? JSON.parse(saved) : defaultStyleQrCode;
    });

    const [styleText, setStyleText] = useState(() => {
        const saved = localStorage.getItem("styleText");
        return saved ? JSON.parse(saved) : defaultStyleText;
    });

    useEffect(() => {
        localStorage.setItem("styleQrCode", JSON.stringify(styleQrCode));
    }, [styleQrCode]);

    useEffect(() => {
        localStorage.setItem("styleText", JSON.stringify(styleText));
    }, [styleText]);

    // Lorsque l'utilisateur charge un fichier et que le mode "fichier" est activé,
    // on lit le fichier en data URL et on met à jour la donnée du QR code.
    useEffect(() => {
        if (!contentType && file) {
          const uploadFile = async () => {
            try {
              console.log("Uploading file...");
              const fileUrl = await uploadImage(file, email, "QRCode", qrcodeId);
              // Met à jour la donnée du QR code avec l'URL du fichier uploadé
              setStyleQrCode(prev => ({ ...prev, data: fileUrl }));
            } catch (error) {
              console.error("Erreur lors de l'upload du fichier :", error);
            }
          };
          uploadFile();
        }
    }, [file, contentType]);

    // Initialisation du QR code
    useEffect(() => {
        qrCodeInstance.current = new QRCodeStyling({
            width: 200,
            height: 200,
            data: styleQrCode.data !== "" ? styleQrCode.data : "https://example.com",
            dotsOptions: {
                color: styleQrCode.dotColor,
                type: styleQrCode.dotStyle,
            },
            cornersSquareOptions: {
                color: styleQrCode.squareColor,
                type: styleQrCode.squareStyle,
            },
            cornersDotOptions: {
                color: styleQrCode.squareColor,
                type: styleQrCode.squareStyle,
            },
            backgroundOptions: {
                color: styleQrCode.bgColor,
            },
            imageOptions: {
                crossOrigin: 'anonymous',
                margin: 10,
            },
            image: styleQrCode.logo,
        });
        if (qrCodeRef.current) {
            qrCodeInstance.current.append(qrCodeRef.current);
        }
    }, []);

    const handleGenerate = () => {
        qrCodeInstance.current.update({
            data: styleQrCode.data !== "" ? styleQrCode.data : "https://example.com",
            dotsOptions: {
                color: styleQrCode.dotColor,
                type: styleQrCode.dotStyle,
            },
            cornersSquareOptions: {
                color: styleQrCode.squareColor,
                type: styleQrCode.squareStyle,
            },
            cornersDotOptions: {
                color: styleQrCode.squareColor,
                type: styleQrCode.squareStyle,
            },
            backgroundOptions: {
                color: styleQrCode.bgColor,
            },
            image: styleQrCode.logo,
        });
    };

    useEffect(() => {
        handleGenerate();
    }, [styleQrCode]);

    const handleInputChange = (key, value) => {
        setStyleQrCode((prev) => ({ ...prev, [key]: value }));
    };

    const handleLogoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setStyleQrCode((prev) => ({ ...prev, logo: event.target.result }));
            };
            reader.readAsDataURL(file);
        }
    };

    const deleteLogoQrcode = () => {
        setStyleQrCode((prev) => ({ ...prev, logo: undefined }));
    };

    // Téléchargement avec le format choisi
    const handleDownloadStyledQR = async () => {
        try {
            const qrCodeElement = qrCodeStyle.current;
            if (!qrCodeElement) {
                console.error("L'élément contenant le QR code n'a pas pu être trouvé.");
                return;
            }
            const canvas = await html2canvas(qrCodeElement, {
                backgroundColor: null,
                useCORS: true,
            });

            if (downloadFormat === "png") {
                const imageDataUrl = canvas.toDataURL("image/png");
                const link = document.createElement("a");
                link.href = imageDataUrl;
                link.download = "qr-code.png";
                link.click();
            } else if (downloadFormat === "jpeg") {
                const imageDataUrl = canvas.toDataURL("image/jpeg");
                const link = document.createElement("a");
                link.href = imageDataUrl;
                link.download = "qr-code.jpeg";
                link.click();
            } else if (downloadFormat === "pdf") {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF({
                    orientation: "portrait",
                    unit: "pt",
                    format: [canvas.width, canvas.height]
                });
                pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
                pdf.save("qr-code.pdf");
            }
        } catch (error) {
            console.error("Erreur lors du téléchargement du QR code stylisé :", error);
        }
    };

    const handleReset = () => {
        setStyleQrCode(defaultStyleQrCode);
        setStyleText(defaultStyleText);
        localStorage.removeItem("styleQrCode");
        localStorage.removeItem("styleText");
    };

    // Calcule le nouvel identifiant et réinitialise les états et l'instance du QR code
    const handleNewQRCode = () => {
        // Calculer le nouvel id en se basant sur le nombre actuel de QR codes enregistrés
        const newId = generateNewId(user);
        // Mettre à jour le titre pour refléter le nouveau QR Code
        setTitleQRcode(`QR Code #${newId + 1}`);
        // Réinitialiser les états aux valeurs par défaut
        setStyleQrCode(defaultStyleQrCode);
        setStyleText(defaultStyleText);
        // Nettoyer le localStorage pour le style (si utilisé)
        localStorage.removeItem("styleQrCode");
        localStorage.removeItem("styleText");
        
        // Réinitialiser l'affichage en vidant le conteneur
        if (qrCodeRef.current) {
        qrCodeRef.current.innerHTML = "";
        }
        // Créer une nouvelle instance du QR code avec les valeurs par défaut
        qrCodeInstance.current = new QRCodeStyling({
        width: 200,
        height: 200,
        data: defaultStyleQrCode.data,
        dotsOptions: {
            color: defaultStyleQrCode.dotColor,
            type: defaultStyleQrCode.dotStyle,
        },
        cornersSquareOptions: {
            color: defaultStyleQrCode.squareColor,
            type: defaultStyleQrCode.squareStyle,
        },
        cornersDotOptions: {
            color: defaultStyleQrCode.squareColor,
            type: defaultStyleQrCode.squareStyle,
        },
        backgroundOptions: {
            color: defaultStyleQrCode.bgColor,
        },
        imageOptions: {
            crossOrigin: 'anonymous',
            margin: 10,
        },
        image: defaultStyleQrCode.logo,
        });
        if (qrCodeRef.current) {
        qrCodeInstance.current.append(qrCodeRef.current);
        }
    };

    const saveQRCode = async () => {
        let finalData = styleQrCode.data;
        // Si l'utilisateur a sélectionné un fichier et que l'on est en mode fichier,
        // on upload le fichier dans Firebase Storage pour récupérer son URL.
        if (!contentType && file) {
            try {
                console.log("Uploading file...");
                const fileUrl = await uploadImage(file, email, "QRCode", qrcodeId);
                finalData = fileUrl;
                // Met à jour la donnée du QR code avec l'URL du fichier uploadé
                setStyleQrCode(prev => ({ ...prev, data: fileUrl }));
            } catch (error) {
                console.error("Erreur lors de l'upload du fichier :", error);
                return;
            }
        }

        // Construction de l'objet QR code à enregistrer
        const newQRCode = {
            id: qrcodeId, // On utilise ici le nombre de QR codes déjà enregistrés
            data: finalData,
            title: titleQRcode,
            styling: {
                dotColor: styleQrCode.dotColor,
                dotStyle: styleQrCode.dotStyle,
                squareColor: styleQrCode.squareColor,
                squareStyle: styleQrCode.squareStyle,
                bgColor: styleQrCode.bgColor,
                logo: styleQrCode.logo,
            },
            text: {
                text: styleText.text,
                radius: styleText.radius,
                bgColor: styleText.bgColor,
                color: styleText.color,
            },
        };

        // Mise à jour de l'objet user en ajoutant ou en modifiant le QR code
        const updatedQRCodes = {
            ...user.qrcode,
            [qrcodeId]: newQRCode,
        };

        // Met à jour l'objet user via la méthode updateQRCode (définie dans votre classe User)
        user.updateQRCode(updatedQRCodes);

        // Enregistre l'utilisateur mis à jour dans Firebase
        await updateUser(user, email, dispatch);
    };

    return (
        <div className="DashboardPages">
            <div className={styles.sectionQrCodeGenerator}>
                <div className={styles.customQrCode}>
                    <p className='font-bold text-[var(--text-primary)] w-full text-left mb-2 text-xl mt-3'>Titre du QR Code</p>
                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-[var(--background-primary)] border border-[var(--border)] px-3 py-2 w-full'>
                    <div className='flex flex-row gap-1 items-center justify-center'>
                        <i className="fa-solid fa-heading" style={{ color: "var(--accent)" }}></i>
                        <p className='text-[var(--text-primary)]'>Entrez un titre</p>
                    </div>
                    <InputFieldText
                        value={titleQRcode}
                        maxLength={2048}
                        onChange={(e) => setTitleQRcode(e.target.value)}
                        placeholder={titleQRcode}
                        title={""}
                        type={true}
                    />
                    </div>
                    <p className='font-bold text-[var(--text-primary)] w-full text-left mb-2 text-xl mt-3'>Type de contenu du QR Code</p>
                    <div className='flex flex-row gap-4 w-full items-center justify-start'>
                        <button 
                            onClick={() => setContentType(true)} 
                            className={`cursor-pointer flex flex-row items-center justify-center gap-1 rounded-lg px-3 py-2 ${contentType ? "button-border-active text-[var(--text-primary)]" : "border border-[var(--text-secondary)] text-[var(--text-secondary)] "}`} 
                        >
                            <i className="fa-solid fa-link" style={{ color: contentType ? "var(--accent)" : "var(--text-secondary)" }}></i>
                            À partir d'un lien
                        </button>
                        <button 
                            onClick={() => setContentType(false)} 
                            className={`cursor-pointer flex flex-row items-center justify-center gap-1 rounded-lg px-3 py-2 ${!contentType ? "button-border-active text-[var(--text-primary)] " : "border border-[var(--text-secondary)] text-[var(--text-secondary)] "}`} 
                        >
                            <i className="fa-solid fa-file" style={{ color: !contentType ? "var(--accent)" : "var(--text-secondary)" }}></i>
                            À partir d'un fichier
                        </button>
                    </div>
                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-[var(--background-primary)] border border-[var(--border)] px-3 py-2 w-full'>
                        {contentType ? (
                            <>
                                <div className='flex flex-row gap-1 items-center justify-center'>
                                    <i className="fa-solid fa-link" style={{ color: "var(--accent)" }}></i>
                                    <p className='text-[var(--text-primary)]'>Changez le lien</p>
                                </div>
                                <InputFieldText
                                    value={styleQrCode.data}
                                    maxLength={2048}
                                    onChange={(e) => handleInputChange("data", e.target.value)}
                                    placeholder={styleQrCode.data}
                                    title={""}
                                    type={true}
                                />
                            </>
                        ) : (
                            <>
                                <div className='flex flex-row gap-1 items-center justify-center'>
                                    <i className="fa-solid fa-file" style={{ color: "var(--accent)" }}></i>
                                    <p className='text-black'>Chargez un fichier</p>
                                </div>
                                <ButtonUploadFile 
                                    title={"Charger un fichier"} 
                                    folder={"QRCode"} 
                                    nameFile={1} 
                                    setFile={setFile} 
                                    setTitle={setTitleComponent}
                                />
                                <a href={styleQrCode.data} target='_blank' className='text-sm underline'>{titleComponent}</a>
                            </>
                        )}
                    </div>
                    {/* Le reste du composant reste inchangé pour la personnalisation du style */}
                    <p className='font-bold text-[var(--text-primary)] w-full text-left mb-2 text-xl mt-3'>Style du QR Code</p>
                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-[var(--background-primary)] border border-[var(--border)] px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-solid fa-circle" style={{ color: "var(--accent)" }}></i>
                            <p className='text-[var(--text-primary)]'>Changez le style des points</p>
                        </div>
                        <div className='flex flex-row items-center justify-start gap-4'>
                            <button onClick={() => handleInputChange("dotStyle", "rounded")} className={`flex items-center ${styleQrCode.dotStyle === "rounded" ? "w-12 h-12" : "w-11 h-11"} justify-center rounded-lg bg-[var(--card-background)]`} style={{ border: styleQrCode.dotStyle === "rounded" ? "1px var(--accent) solid" : "1px var(--text-secondary) solid" }}>
                                <div className='w-6 h-6 rounded-md bg-[var(--text-primary)]'></div>
                            </button>
                            <button onClick={() => handleInputChange("dotStyle", "square")} className={`flex items-center ${styleQrCode.dotStyle === "square" ? "w-12 h-12" : "w-11 h-11"} justify-center rounded-lg bg-[var(--card-background)]`} style={{ border: styleQrCode.dotStyle === "square" ? "1px var(--accent) solid" : "1px var(--text-secondary) solid" }}>
                                <div className='w-6 h-6 bg-[var(--text-primary)]'></div>
                            </button>
                            <button onClick={() => handleInputChange("dotStyle", "dots")} className={`flex items-center ${styleQrCode.dotStyle === "dots" ? "w-12 h-12" : "w-11 h-11"} justify-center rounded-lg bg-[var(--card-background)]`} style={{ border: styleQrCode.dotStyle === "dots" ? "1px var(--accent) solid" : "1px var(--text-secondary) solid" }}>
                                <div className='w-6 h-6 rounded-full bg-[var(--text-primary)]'></div>
                            </button>
                            <button onClick={() => document.getElementById('dotColorInput').click()} className={`flex items-center w-11 h-11 justify-center rounded-lg relative`} style={{ border: "1px var(--text-secondary) solid", backgroundColor: styleQrCode.dotColor }}>
                                <input
                                    type="color"
                                    id="dotColorInput"
                                    value={styleQrCode.dotColor}
                                    onChange={() => handleInputChange("dotColor", document.getElementById('dotColorInput').value)}
                                    className="sr-only"
                                />
                                <i className="fa-solid fa-palette absolute" style={{ color: styleQrCode.bgColor, fontSize: "20px", transform: "rotate(-45deg)" }}></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-[var(--background-primary)] border border-[var(--border)] px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-regular fa-square" style={{ color: "var(--accent)" }}></i>
                            <p className='text-[var(--text-primary)]'>Changez le style des carrés</p>
                        </div>
                        <div className='flex flex-row items-center justify-start gap-4'>
                            <button onClick={() => handleInputChange("squareStyle", "extra-rounded")} className={`flex items-center ${styleQrCode.squareStyle === "extra-rounded" ? "w-12 h-12" : "w-11 h-11"} justify-center rounded-lg bg-[var(--card-background)]`} style={{ border: styleQrCode.squareStyle === "extra-rounded" ? "1px var(--accent) solid" : "1px var(--text-secondary) solid" }}>
                                <div className='w-6 h-6 rounded-md' style={{ border: "6px var(--text-primary) solid" }}></div>
                            </button>
                            <button onClick={() => handleInputChange("squareStyle", "square")} className={`flex items-center ${styleQrCode.squareStyle === "square" ? "w-12 h-12" : "w-11 h-11"} justify-center rounded-lg bg-[var(--card-background)]`} style={{ border: styleQrCode.squareStyle === "square" ? "1px var(--accent) solid" : "1px var(--text-secondary) solid" }}>
                                <div className='w-6 h-6' style={{ border: "6px var(--text-primary) solid" }}></div>
                            </button>
                            <button onClick={() => handleInputChange("squareStyle", "dot")} className={`flex items-center ${styleQrCode.squareStyle === "dot" ? "w-12 h-12" : "w-11 h-11"} justify-center rounded-lg bg-[var(--card-background)]`} style={{ border: styleQrCode.squareStyle === "dot" ? "1px var(--accent) solid" : "1px var(--text-secondary) solid" }}>
                                <div className='w-6 h-6 rounded-full' style={{ border: "6px var(--text-primary) solid" }}></div>
                            </button>
                            <button onClick={() => document.getElementById('squareColorInput').click()} className={`flex items-center w-11 h-11 justify-center rounded-lg relative`} style={{ border: "1px var(--text-secondary) solid", backgroundColor: styleQrCode.squareColor }}>
                                <input
                                    type="color"
                                    id="squareColorInput"
                                    value={styleQrCode.squareColor}
                                    onChange={() => handleInputChange("squareColor", document.getElementById('squareColorInput').value)}
                                    className="sr-only"
                                />
                                <i className="fa-solid fa-palette absolute" style={{ color: styleQrCode.bgColor, fontSize: "20px", transform: "rotate(-45deg)" }}></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-[var(--background-primary)] border border-[var(--border)] px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-solid fa-image" style={{ color: "var(--accent)" }}></i>
                            <p className='text-[var(--text-primary)]'>Changez la couleur de fond et ajoutez une image</p>
                        </div>
                        <div className='flex flex-row items-center justify-start gap-4'>
                            <button onClick={() => document.getElementById('bgColorInput').click()} className={`flex items-center w-11 h-11 justify-center rounded-lg relative`} style={{ border: "1px var(--text-secondary) solid", backgroundColor: styleQrCode.bgColor }}>
                                <input
                                    type="color"
                                    id="bgColorInput"
                                    value={styleQrCode.bgColor}
                                    onChange={() => handleInputChange("bgColor", document.getElementById('bgColorInput').value)}
                                    className="sr-only"
                                />
                                <i className="fa-solid fa-fill-drip absolute" style={{ color: styleQrCode.dotColor, fontSize: "20px" }}></i>
                            </button>
                            <button
                                onClick={() => document.getElementById('logoInput').click()}
                                className={`flex items-center w-11 h-11 justify-center rounded-lg relative`}
                                style={{
                                    border: "1px var(--text-secondary) solid",
                                    backgroundColor: "var(--card-background)",
                                }}
                            >
                                <input
                                    type="file"
                                    id="logoInput"
                                    accept="image/*"
                                    onChange={handleLogoUpload}
                                    className="sr-only"
                                />
                                {styleQrCode.logo ? (
                                    <img
                                        src={styleQrCode.logo}
                                        alt="Logo Preview"
                                        className="absolute inset-0 object-cover w-full h-full rounded-lg"
                                    />
                                ) : (
                                    <i
                                        className="fa-solid fa-image absolute"
                                        style={{ color: "var(--text-primary)", fontSize: "20px" }}
                                    ></i>
                                )}
                                {styleQrCode.logo && (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            deleteLogoQrcode();
                                        }}
                                        className=""
                                    >
                                        <Trash2
                                            size={10}
                                            color="#FFF"
                                            className="bg-red-600 p-1 rounded-full h-6 w-6 absolute top-[-10px] right-[-10px]"
                                        />
                                    </button>
                                )}
                            </button>
                        </div>
                    </div>

                    <p className='font-bold text-[var(--text-primary)] w-full text-left mb-2 text-xl mt-3'>Style du texte</p>
                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-[var(--background-primary)] px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-solid fa-font" style={{ color: "var(--accent)" }}></i>
                            <p className='text-[var(--text-primary)]'>Personnalisez le texte</p>
                        </div>
                        <InputFieldText
                            value={styleText.text}
                            maxLength={256}
                            onChange={(e) => setStyleText((prev) => ({ ...prev, text: e.target.value }))}
                            placeholder={styleText.text}
                            title={""}
                            type={true}
                        />
                    </div>

                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-[var(--background-primary)] px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-solid fa-paint-roller" style={{ color: "var(--accent)" }}></i>
                            <p className='text-[var(--text-primary)]'>Changez la couleur du texte et de fond</p>
                        </div>
                        <div className='flex flex-row items-center justify-start gap-4'>
                            <button onClick={() => document.getElementById('textColorInput').click()} className={`flex items-center w-11 h-11 justify-center rounded-lg relative`} style={{ border: "1px var(--text-secondary) solid", backgroundColor: styleText.bgColor }}>
                                <input
                                    type="color"
                                    id="textColorInput"
                                    value={styleText.color}
                                    onChange={(e) => setStyleText((prev) => ({ ...prev, color: document.getElementById('textColorInput').value }))}
                                    className="sr-only"
                                />
                                <i className="fa-solid fa-font absolute" style={{ color: styleText.color, fontSize: "20px" }}></i>
                            </button>
                            <button onClick={() => document.getElementById('textBgColorInput').click()} className={`flex items-center w-11 h-11 justify-center rounded-lg relative`} style={{ border: "1px var(--text-secondary) solid", backgroundColor: styleText.color }}>
                                <input
                                    type="color"
                                    id="textBgColorInput"
                                    value={styleText.bgColor}
                                    onChange={(e) => setStyleText((prev) => ({ ...prev, bgColor: document.getElementById('textBgColorInput').value }))}
                                    className="sr-only"
                                />
                                <i className="fa-solid fa-fill-drip absolute" style={{ color: styleText.bgColor, fontSize: "20px" }}></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-col mb-4 items-start justify-center rounded-lg gap-1 bg-[var(--background-primary)] px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-regular fa-circle-dot" style={{ color: "var(--accent)" }}></i>
                            <p className='text-[var(--text-primary)]'>Changez le rayon des bords</p>
                        </div>
                        <div className="PB-range-slider-div">
                            <input type="range" min="0" max="50" value={styleText.radius} onChange={(e) => setStyleText((prev) => ({ ...prev, radius: e.target.value }))} className="PB-range-slider" id="myRange" />
                            <p className="PB-range-slidervalue">{styleText.radius}px</p>
                        </div>
                    </div>
                    <div className='flex flex-row items-center justify-between gap-2'>
                        <button
                            onClick={handleReset}
                            className="text-[var(--text-primary)] hover:bg-[var(--hover)] hover:border-[var(--hover)] no-underline text-center font-semibold uppercase bg-[var(--accent)] cursor-pointer py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 border-[var(--accent)]"
                        >
                            Réinitialiser QR Code
                        </button>
                        <button
                            onClick={handleNewQRCode}
                            className="text-[var(--text-primary)] hover:bg-[var(--hover)] hover:border-[var(--hover)] no-underline text-center font-semibold uppercase bg-[var(--accent)] cursor-pointer py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 border-[var(--accent)]"
                        >
                            Nouveau QR Code
                        </button>
                    </div>
                </div>
                <div className={styles.previewQrCode}>
                    <div className='flex flex-col items-center justify-center gap-5' ref={qrCodeStyle}>
                        <div
                            className="qr-code-preview rounded-3xl p-6"
                            style={{ backgroundColor: styleQrCode.bgColor }}
                            ref={qrCodeRef}
                        ></div>
                        {styleText.text !== "" &&
                        <p
                            className="py-2 px-4"
                            style={{
                                borderRadius: `${styleText.radius}px`,
                                backgroundColor: styleText.bgColor,
                                color: styleText.color,
                                padding: '10px 20px',
                                fontSize: '16px',
                            }}
                        >
                            {styleText.text}
                        </p>}
                    </div>

                    <div style={{ backgroundColor: "var(--text-secondary)", width: "calc(100% + 20px)", height: "2px" }}></div>
                    <div className="flex flex-row items-center gap-2 mt-4">
                        <button
                            className="text-[var(--text-primary)] hover:bg-[var(--hover)] hover:border-[var(--hover)] no-underline text-center font-semibold uppercase bg-[var(--accent)] cursor-pointer py-2 px-2 text-sm max-[425px]:text-sm rounded-lg flex flex-row gap-1"
                            onClick={handleDownloadStyledQR}
                        >
                            Télécharger le QR code
                            <Download color={"var(--text-primary)"} size={20} />
                        </button>
                        <select
                            value={downloadFormat}
                            onChange={(e) => setDownloadFormat(e.target.value)}
                            className="px-2 py-2 rounded-lg border text-[var(--text-primary)] text-sm font-semibold cursor-pointer bg-[var(--background-primary)] border-[var(--border)] "
                        >
                            <option value="png">PNG</option>
                            <option value="jpeg">JPEG</option>
                            <option value="pdf">PDF</option>
                        </select>
                    </div>
                    <button
                        className="text-[var(--text-primary)] hover:bg-[var(--hover)] hover:border-[var(--hover)] no-underline text-center font-semibold uppercase bg-[var(--accent)] cursor-pointer py-2 px-6 text-base max-[425px]:text-sm rounded-[50px] border-2 border-[var(--accent)]"
                        onClick={saveQRCode}
                    >
                        ENREGISTRER
                    </button>
                </div>
            </div>
        </div>
    );
}

export default QrCodeGenerator;
