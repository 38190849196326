import React, { useState, useEffect, useRef } from 'react';
import "./ShareButton.css";
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

const ShareButton = ({ url = window.location.href, title = document.title }) => {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(true);
  const lastScrollY = useRef(window.scrollY);
  const { hobspace } = useHobspace();

  // Détection du scroll pour masquer/afficher le bouton
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY.current) {
        // L'utilisateur défile vers le bas : on masque le bouton
        setVisible(false);
      } else {
        // L'utilisateur défile vers le haut : on affiche le bouton
        setVisible(true);
      }
      lastScrollY.current = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Fonction de partage
  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({ title, url });
      } catch (error) {
        console.error("Erreur lors du partage natif :", error);
      }
    } else {
      // Affiche le menu personnalisé si l'API Web Share n'est pas disponible
      setOpen(!open);
    }
  };

  // Copier le lien dans le presse-papiers
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      alert("Lien copié dans le presse-papiers !");
    } catch (error) {
      console.error("Erreur lors de la copie du lien :", error);
    }
  };

  // Préparation des liens de partage pour chaque réseau
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);
  const shareLinks = [
    { name: "Facebook", url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}` },
    { name: "Twitter", url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}` },
    { name: "LinkedIn", url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}` },
    { name: "WhatsApp", url: `https://api.whatsapp.com/send?text=${encodedTitle}%20${encodedUrl}` },
    { name: "Email", url: `mailto:?subject=${encodedTitle}&body=${encodedUrl}` },
    // Vous pouvez ajouter d'autres réseaux si nécessaire
  ];

  return (
    <div 
      className='fixed flex items-center justify-center top-5 right-5 z-10 h-8 w-8 rounded-full'
      style={{
        opacity: visible ? 1 : 0,
        transition: "opacity 0.3s ease",
        pointerEvents: visible ? "auto" : "none",
        backgroundColor: hobspace.colorFont,
      }}
    >
      <button onClick={handleShareClick} style={{ background: "transparent", border: "none", cursor: "pointer" }}>
        <i className="fa-solid fa-share" style={{ color: hobspace.backgroundColor, fontSize: "18px" }}></i>
      </button>
      {open && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            background: "white",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: 1000,
          }}
        >
          <h4>Partager sur</h4>
          <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
            {shareLinks.map((item) => (
              <li key={item.name} style={{ margin: "5px 0" }}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.name}
                </a>
              </li>
            ))}
            <li style={{ margin: "5px 0" }}>
              <button onClick={handleCopy}>Copier le lien</button>
            </li>
          </ul>
          <button onClick={() => setOpen(false)}>Fermer</button>
        </div>
      )}
    </div>
  );
};

export default ShareButton;
