import React, { useState, useEffect } from 'react';
import { useUser } from '../../utils/contexts/userContext';
import './LinkTikTok.css';
import { Image } from 'lucide-react';
import Reels from '../../assets/reels.png';
import Short from '../../assets/youtube-short.png';
import Tiktok from '../../assets/tiktok-color.png';
import { getYoutubeShortOEmbedData } from '../../services/api/youtubeShort';
import { getInstagramReelsOEmbedData } from '../../services/api/instagram';
import { getTiktokOEmbedData } from '../../services/api/tiktok';

const LinkTikTokEditComponent = ({ container }) => {
    const [musicData, setMusicData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { user } = useUser();

    useEffect(() => {
        async function fetchMusicData() {
            if (container.idMedia && Object.keys(container.idMedia).length > 0) {
                try {
                    const platforms = Object.keys(container.idMedia);

                    // Récupère les données depuis la première plateforme disponible
                    for (const platform of platforms) {
                        const musicId = container.idMedia[platform];
                        const data = await fetchMusicFromPlatform(platform, musicId);
                        if (data) {
                            setMusicData(data);
                            break; // Stoppe la recherche dès qu'une musique est trouvée
                        }
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération des données musicales :", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }

        fetchMusicData();
    }, [container.idMedia]);

    const fetchMusicFromPlatform = async (platform, id, feed) => {
        if (platform === 'reels') return await getInstagramReelsOEmbedData(id);
        if (platform === 'tiktok') return await getTiktokOEmbedData(id);
        if (platform === 'short') return await getYoutubeShortOEmbedData(id);
        return null;
    };

    const renderLogo = (platform) => {
        switch (platform) {
            case 'tiktok':
                return Tiktok;
            case 'short':
                return Short;
            case 'reels':
                return Reels;
            default:
                return null;
        }
    };

    return (
        <div className="flex items-center">
            {loading ? (
                <p>Chargement...</p>
            ) : (
                <>
                    {musicData?.thumbnail_url ? (
                        <img
                            src={musicData.thumbnail_url}
                            alt="Music Cover"
                            className="mr-2 w-16 h-16 object-cover object-center rounded-lg"
                        />
                    ) : (
                        <Image className="mr-2 w-16 h-16" />
                    )}
                    <div className="flex flex-col">
                        <strong className="titleOverflow text-sm truncate">
                            {musicData?.title || "Titre de la vidéo"}
                        </strong>
                        <p className="titleOverflow text-sm truncate">
                            {musicData?.author_name ? `${musicData?.author_name}` : "Nom du créateur"}
                        </p>
                        <div className="flex gap-1 mt-1">
                            {Object.keys(container.idMedia).map((platform) => (
                                <img
                                    key={platform}
                                    src={renderLogo(platform)}
                                    alt={platform}
                                    title={platform}
                                    className="w-4 h-4"
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default LinkTikTokEditComponent;
