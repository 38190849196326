// src/contexts/hobsManagerContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../../services/firebase/firebase.config';
import HobsManager from '../../services/storage/hobsManager';
import { useDispatch } from 'react-redux';
import { getImageComponent } from '../../services/storage/storageService';
import { setEntrepriseImages, setEntrepriseBackgroundImage, setEntrepriseBannerImage } from '../../services/store/reducers/hobsManagerSlice';

const HobsManagerContext = createContext();

export const useHobsManager = () => useContext(HobsManagerContext);

export const HobsManagerProvider = ({ children }) => {
  const [hobsManager, setHobsManager] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (userAuth) => {
      console.log("User authentifié:", userAuth);
      if (userAuth) {
        const hobsManagerDocRef = doc(db, 'portail-manager', userAuth.email);
        // On écoute en temps réel le document utilisateur
        const unsubscribeSnapshot = onSnapshot(
          hobsManagerDocRef,
          (docSnap) => {
            console.log("Portail manager document exists:", docSnap.exists());
            if (docSnap.exists()) {
              const hobsManagerData = docSnap.data();
              const hobsManagerInstance = HobsManager.fromFirestore(hobsManagerData);
              console.log(hobsManagerInstance)
              setHobsManager(hobsManagerInstance);
            } else {
              setHobsManager(null);
            }
            setLoading(false);
          },
          (error) => {
            console.error("Erreur lors de l'écoute du document hobs manager:", error);
            setLoading(false);
          }
        );

        // Récupération des images avec gestion des exceptions
        (async () => {
          try {
            const entrepriseImage = await getImageComponent(userAuth.email, "entreprise", "entreprise-image");
            dispatch(setEntrepriseImages(entrepriseImage));
          } catch (error) {
            console.error("Erreur lors de la récupération de l'image entreprise:", error);
          }
          try {
            const entrepriseBackgroundImage = await getImageComponent(userAuth.email, "entreprise", "entreprise-background-image");
            dispatch(setEntrepriseBackgroundImage(entrepriseBackgroundImage));
          } catch (error) {
            console.error("Erreur lors de la récupération de l'image de fond entreprise:", error);
          }
          try {
            const entrepriseBanner = await getImageComponent(userAuth.email, "entreprise", "entreprise-banner");
            dispatch(setEntrepriseBannerImage(entrepriseBanner));
          } catch (error) {
            console.error("Erreur lors de la récupération de la bannière entreprise:", error);
          }
        })();

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        setHobsManager(null);
        setLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, [dispatch]);

  return (
    <HobsManagerContext.Provider value={{ hobsManager, loading }}>
      {children}
    </HobsManagerContext.Provider>
  );
};
