import React, {useState, useEffect} from 'react'
import './Article.css';
import { useUser } from '../../utils/contexts/userContext';
import { useSelector } from 'react-redux';
import { getImageComponent } from '../../services/storage/storageService';

function Article(props) {

    const {user} = useUser();
    const [image, setImage] = useState(null);
    const email = useSelector((state) => state.user.email);

    useEffect(() => {
        const fetchMedia = async () => {
            if (props.id) {
                try {
                    // Utilisation de la fonction pour récupérer le média
                    const url = await getImageComponent(email, 'Link', props.id);
                    setImage(url); // Mettre à jour la prévisualisation avec l'URL récupérée
                } catch (error) {
                    console.error('Erreur lors de la récupération du média:', error);
                    setImage(null); // Réinitialiser si une erreur se produit
                }
            }
        };
        fetchMedia();
    }, [props.id, user.page]); 
 
    return (
        <a className={`Article`} href={""} style={{color: user.colorFont}}>
            {image === null ?
                <div className='containerImageDefaultArticle'>
                    <i className="fa-solid fa-image"></i>
                </div>
                :
                <img className={`imgArticle`} src={image} alt="produit" />
            }
            
            <div className={`infoArticle`}>
                <p className={`titleArticle`}>{props.title}</p>
                <p className={`priceArticle`}>{props.subtitle}</p>
            </div>
        </a>
    );
}

export default Article;