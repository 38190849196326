import React, { useState } from 'react';
import './ButtonEditImage.css';
import '@fortawesome/fontawesome-free/css/all.css';
import CustomModal from '../Modal/CustomModal/CustomModal';
import { uploadImage } from '../../services/storage/storageService';
import { useUser } from "../../utils/contexts/userContext";
import { useSelector } from "react-redux";

function ButtonUploadMedia(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(undefined);
    const [previewSrc, setPreviewSrc] = useState(undefined);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // État pour le loader
    const { user } = useUser();
    const email = useSelector((state) => state.user.email);

    const MAX_IMAGE_SIZE = 16 * 1024 * 1024; // 16MB
    const MAX_VIDEO_SIZE = 200 * 1024 * 1024; // 200MB

    const handleButtonClick = () => setIsModalOpen(true);

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setFile(undefined);
        setPreviewSrc(undefined);
        setError(false);
        setIsLoading(false); // Réinitialiser le loader
    };

    const handleFileSelected = (files) => {
        const selectedFile = files[0];
        if (validateFile(selectedFile)) {
            setFile(selectedFile);
            if (props.setFile) {
                props.setFile(selectedFile);
                props.setTitle(selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.')));
            }
            if (selectedFile.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onloadend = () => setPreviewSrc(reader.result);
                reader.readAsDataURL(selectedFile);
            } else if (selectedFile.type.startsWith('video/')) {
                extractVideoThumbnail(selectedFile);
            }
            setError(false);
        } else {
            setError(true);
        }
    };

    const validateFile = (file) => {
        const isImage = file.type.startsWith('image/');
        const isVideo = file.type.startsWith('video/');
        return (isImage && file.size <= MAX_IMAGE_SIZE) || (isVideo && file.size <= MAX_VIDEO_SIZE);
    };

    const extractVideoThumbnail = (videoFile) => {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(videoFile);
        video.currentTime = 1;
        video.onloadeddata = () => {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            setPreviewSrc(canvas.toDataURL('image/png'));
        };
    };

    const handleDrop = (event) => {
        event.preventDefault();
        handleFileSelected(event.dataTransfer.files);
    };

    const handleDragOver = (event) => event.preventDefault();

    const handleModalClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*,video/*';
        input.onchange = (event) => handleFileSelected(event.target.files);
        input.click();
    };

    const uploadFile = async () => {
        if (user && file) {
            setIsLoading(true); // Activer le loader
            try {
                const downloadURL = await uploadImage(file, email, props.folder, props.nameFile);
                handleCloseModal();
            } catch (error) {
                console.error('Error uploading media:', error);
                setError(true);
            } finally {
                setIsLoading(false); // Désactiver le loader
            }
        } else {
            setError(true);
        }
    };

    return (
        <>
            <button
                className='border p-2 text-[var(--text-primary)] rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]'
                onClick={handleButtonClick}
            >
                <i className="fa-solid fa-arrow-up-from-bracket" style={{ color: "var(--text-primary)", marginRight: "8px", fontSize: "16px" }}></i>
                Sélectionner un média
            </button>
            <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
                <div className='containerSelectImgProfil'>
                    <p className='titleModalSettings'>{props.title}</p>
                    <div
                        className='dropzone'
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={handleModalClick}
                    >
                        {previewSrc ? (
                            <img className='imgUploadProfil' src={previewSrc} alt="preview" />
                        ) : (
                            <div className='iconButtonImgProfil'>
                                <i className="fa-solid fa-cloud-arrow-up"></i>
                            </div>
                        )}
                        <p style={{ color: error ? "red" : "black" }}>
                            {error ? "Fichier invalide ou trop volumineux." : "Déposez une image ou une vidéo ici ou cliquez pour sélectionner un fichier"}
                        </p>
                    </div>
                    <div className='containerButtonImgProfil'>
                        <button className='text-red-600 no-underline font-semibold uppercase text-center py-2 px-6 text-base rounded-[50px] hover:border-red-600 hover:bg-red-600 hover:text-[var(--text-primary)] border-2 border-[var(--text-primary)] bg-[var(--text-primary)] cursor-pointer' onClick={handleCloseModal}>Annuler</button>
                        <button className='text-[var(--text-primary)] no-underline text-center font-semibold uppercase bg-[var(--accent)] hover:bg-[var(--hover)] hover:border-[var(--hover)] cursor-pointer py-2 px-6 text-base rounded-[50px] border-2 border-[var(--accent)]' onClick={file ? uploadFile : () => setError(true)} disabled={isLoading}>
                            {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Enregistrer"}
                        </button>
                    </div>
                </div>
            </CustomModal>
        </>
    );
}

export default ButtonUploadMedia;
