export async function getTiktokOEmbedData(videoUrl) {
    try {
      const encodedUrl = encodeURIComponent(videoUrl);
  
      const endpoint = `https://www.tiktok.com/oembed?url=${encodedUrl}`;
  
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erreur lors de la récupération oEmbed TikTok :', error);
      throw error;
    }
}

export function extractTiktokVideoId(tiktokUrl) {
    try {
      const parts = tiktokUrl.split('/video/');
      if (parts.length > 1) {
        // Tout ce qui vient après "/video/" :
        const maybeId = parts[1].split('?')[0]; // enlever éventuels paramètres
        // On peut ajouter une validation ou un trim
        return maybeId;
      }
      return null;
    } catch (err) {
      console.error('Erreur extractTiktokVideoId:', err);
      return null;
    }
  }
  