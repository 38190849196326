import React, {useState, useEffect} from 'react'
import styles from './Article.module.css';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import { getImageComponent } from '../../../services/storage/storageService';

function Article(props) {

    const {hobspace} = useHobspace();
    const [image, setImage] = useState("");

    useEffect(() => {
        const fetchMedia = async () => {
            if (props.id) {
                try {
                    // Utilisation de la fonction pour récupérer le média
                    const url = await getImageComponent(props.email, 'Link', props.id);
                    setImage(url); // Mettre à jour la prévisualisation avec l'URL récupérée
                } catch (error) {
                    console.error('Erreur lors de la récupération du média:', error);
                    setImage(""); // Réinitialiser si une erreur se produit
                }
            }
        };

        fetchMedia();
    }, [props.id, props.email]); 
 
    return (
        <a className={styles.Article} href={props.link} target='_blank' style={{color: hobspace.colorFont}}>
            {image === "" ?
                <div className={styles.containerImageDefaultArticle}>
                    <i className="fa-solid fa-image"></i>
                </div>
                :
                <img className={styles.imgArticle} src={image} alt="produit" />
            }
            
            <div className={styles.infoArticle}>
                <p className={`${styles.titleArticle} line-clamp-2`}>{props.title}</p>
                <p className={styles.priceArticle}>{props.subtitle}</p>
            </div>
        </a>
    );
}

export default Article;