import React, { useEffect, useState } from 'react';
import "./Media.css";
import { Image } from 'lucide-react';
import { base64ToFile } from '../../utils';
import { getImageComponent } from '../../services/storage/storageService'; // Assurez-vous que le chemin est correct
import { useUser } from '../../utils/contexts/userContext'; // Assurez-vous que le contexte utilisateur est importé
import { useSelector } from 'react-redux';

const MediaEditComponent = ({ container }) => {
    const { user } = useUser(); // Récupération de l'utilisateur
    const [previewSrc, setPreviewSrc] = useState(null);
    const email = useSelector((state) => state.user.email);

    useEffect(() => {
        const fetchMedia = async () => {
            if (container) {
                try {
                    const mediaUrl = await getImageComponent(email, 'Media', container.id);
                    if (mediaUrl) {
                        // Vérifier si c'est une image ou une vidéo
                        if (container.type === "video") {
                            getVideoThumbnail(mediaUrl);
                        } else if (container.type === "image") {
                            setPreviewSrc(mediaUrl); 
                        }
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération des médias :", error);
                }
            }
        };

        fetchMedia();
    }, [container]);

    const getVideoThumbnail = (videoUrl) => {
        const video = document.createElement('video');
        video.crossOrigin = "anonymous";
        video.src = videoUrl;
        video.currentTime = 1;
    
        video.onloadeddata = () => {
            try {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                const thumbnail = canvas.toDataURL('image/png');
                setPreviewSrc(thumbnail);
            } catch (error) {
                console.error("Erreur lors de la création de la miniature vidéo :", error);
            }
        };
    
        video.onerror = () => {
            console.error("Erreur lors du chargement de la vidéo.");
        };
    };  

    return (
        <div className='flex items-center'>
            {previewSrc ? (
                <img src={previewSrc} alt="content" className='mr-2 min-w-16 h-16 object-cover object-center rounded-lg' />
            ) : (
                <Image className='mr-2 w-16 h-16' />
            )}
            <div className='flex flex-col'>
                <strong className='titleOverflow text-sm truncate'>{container.description}</strong>
                <a href={container.link} className='titleOverflow text-blue-500 text-sm text-left font-bold truncate'>{container.link}</a>
            </div>
        </div>
    );
};

export default MediaEditComponent;
