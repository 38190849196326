import React, { useState, useEffect } from 'react';
import { getYoutubeVideoId, getYoutubeVideo } from '../../services/api/youtube';
import { useUser } from '../../utils/contexts/userContext';
import "./OneVideo.css";

const OneVideoEditComponent = ({ container, renderComponentIcon }) => {
    const [videoTitle, setVideoTitle] = useState(""); // Valeur par défaut
    const [loading, setLoading] = useState(true);
    const {user} = useUser();

    useEffect(() => {
        async function fetchVideoData() {
            if (container.idMedia) {
                try {
                    const id = await getYoutubeVideoId(container.idMedia, user);
                    if (id) {
                        const video = await getYoutubeVideo(id);
                        if (video && video.snippet.title) {
                            setVideoTitle(video.snippet.title);
                        } else {
                            console.error("Vidéo non trouvée ou sans titre.");
                        }
                    } else {
                        console.error("Impossible de charger la vidéo. ID invalide.");
                    }
                } catch (error) {
                    console.error("Erreur lors du chargement de la vidéo:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }
        fetchVideoData();
    }, [container.idMedia, user]);

    return (
        <div className='flex items-center'>
            <div className='flex flex-col h-16 w-16 rounded-lg bg-slate-800 mr-2 items-center justify-center'>
                <img className='w-11 h-11 object-contain object-center' src={renderComponentIcon("Video")} alt="Video Icon" />
            </div>
            <div className='flex flex-col'>
                <strong className='titleOverflow text-lg truncate'>
                    {loading ? 'Chargement...' : videoTitle === "" ? "Entrer le lien d'une vidéo" : videoTitle}
                </strong>
                <p className='titleOverflow text-blue-500 text-sm text-left font-bold truncate'>
                    {container.idMedia === "" ? "https://youtube.com" : container.idMedia}
                </p>
            </div>
        </div>
    );
};

export default OneVideoEditComponent;