import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import './InputField.css';

function InputPhoneNumber({ 
    value, 
    onChange, 
    title, 
    placeholder, 
    required, 
    country, 
    onKeyPress, 
    onBlur: customOnBlur 
}) {
    const [isFocused, setIsFocused] = useState(false);
    
    const handlePhoneChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = (e) => {
        setIsFocused(false);
        if (customOnBlur) {
            customOnBlur(e);
        }
    };

    const handleKeyPress = (e) => {
        if (onKeyPress) {
            onKeyPress(e);
        }
    };

    return (
        <div className={`InputFieldText ${isFocused ? 'focused' : ''} text-xl`}>
            {title && <p className='InputFieldTextTitle'>{title}</p>}
            <PhoneInput
                country={country.toLowerCase()}
                value={value}
                onChange={handlePhoneChange}
                inputProps={{
                    name: 'phone',
                    required: required,
                    autoFocus: false,
                    placeholder: placeholder,
                    onKeyPress: handleKeyPress, // Passage du onKeyPress personnalisé
                }}
                onFocus={handleFocus}
                onBlur={handleBlur} // Utilisation de notre gestionnaire qui appelle customOnBlur
                containerClass="InputPhoneNumberContainer"
                inputClass="InputFieldTextInput"
            />
        </div>
    );
}

InputPhoneNumber.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    country: PropTypes.string,
    onKeyPress: PropTypes.func,
    onBlur: PropTypes.func,
};

InputPhoneNumber.defaultProps = {
    title: '',
    placeholder: '',
    value: '',
    required: false,
    country: 'us',
    onKeyPress: undefined,
    onBlur: undefined,
};

export default InputPhoneNumber;
