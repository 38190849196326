import React, { useState, useEffect } from "react";
import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./views/Dashboard/DashBoard";
import { Provider } from "react-redux";
import { store } from "./services/store/store";
import Home from "./views/Home/Home";
import Pricing from "./views/Pricing/Pricing";
import Support from "./views/Support/Support";
import Contact from "./views/Contact/Contact";
import Login from "./views/Login/Login";
import SignUp from "./views/SignUp/SignUp";
import PasswordForgotten from "./views/Login/PasswordForgotten";
import Partners from "./views/Partners/Partners";
import { UserProvider } from "./utils/contexts/userContext";
import ScrollToAnchor from "./utils/ScrollToAncor";
import { HobspaceProvider } from "./utils/contexts/hobspaceContext";
import Hobspace from "./hobs-space/Views/Home";
import { getAllHobsSpace } from "./services/storage/storageHobspaceService";
import DynamicManifest from "./DynamicManifest";
import { capitalizeFirstLetter } from "./utils";
import ResetPassword from "./views/Login/ResetPassword";
import Me from "./views/Me/Me";
import { HobsManagerProvider } from "./utils/contexts/hobsManagerContext";

function App() {
  const [hobspacesID, setHobspacesID] = useState(null);
  const [currentTitle, setCurrentTitle] = useState("Hob's Space"); // Titre par défaut
  const location = useLocation(); 

  // Récupération de tous les hobspaces pour générer dynamiquement les routes et titres
  const getHobspacesID = async () => {
    try {
      const hobspaces = await getAllHobsSpace();
      setHobspacesID(hobspaces);
    } catch (error) {
      console.error('Erreur lors de la récupération des Hobspaces:', error);
    }
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") setCurrentTitle("Accueil");
    else if (path === "/dashboard") setCurrentTitle("Dashboard");
    else if (path === "/pricing") setCurrentTitle("Tarifs");
    else if (path === "/partners") setCurrentTitle("Partenaires");
    else if (path === "/support") setCurrentTitle("Support");
    else if (path === "/contact") setCurrentTitle("Contact");
    else if (path === "/login") setCurrentTitle("Connexion");
    else if (path === "/sign-up") setCurrentTitle("Inscription");
    else if (path === "/forget-password") setCurrentTitle("Mot de passe oublié");
    else if (path === "/reset-password") setCurrentTitle("Réinitialisation");
    else if (path === "/me") setCurrentTitle("Mon profil");
    else if (hobspacesID && hobspacesID.some(h => `/${h.id}` === path)) {
      const hobspace = hobspacesID.find(h => `/${h.id}` === path);
      setCurrentTitle(capitalizeFirstLetter(hobspace.id));
    } else {
      setCurrentTitle("Hob's Space"); // Titre par défaut pour les autres cas
    }
  }, [location, hobspacesID]);

  useEffect(() => {
    getHobspacesID();
  }, []);

  return (
    <Provider store={store}>
      <UserProvider>
        <HobsManagerProvider>
        <div className="">
          <ScrollToAnchor />
          <DynamicManifest title={`Hob's Space - ${currentTitle}`} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/support" element={<Support />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forget-password" element={<PasswordForgotten />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/me" element={<Me />} />
            {Array.isArray(hobspacesID) &&
              hobspacesID.map((hobspace, index) => (
                <Route
                  key={index}
                  path={`/${hobspace.id}`}
                  element={
                    <HobspaceProvider>
                      <Hobspace hobspaceId={hobspace.id} />
                    </HobspaceProvider>
                  }
                />
              ))
            }
            {/* Optionnel : Route 404 */}
            {/* <Route path="*" element={<div>404 - Page non trouvée</div>} /> */}
          </Routes>
        </div>
        </HobsManagerProvider>
      </UserProvider>
    </Provider>
  );
}

export default App;
