import React, { useRef, useEffect, useState } from 'react';
import QRCodeStyling from 'qr-code-styling';
import html2canvas from "html2canvas";
import { jsPDF } from 'jspdf';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo } from '../../../services/storage/storageService';
import { useUser } from '../../../utils/contexts/userContext';
import { Download, Trash2 } from 'lucide-react';


const QRCodeCard = ({ qrCodeData, onDelete }) => {
    const containerRef = useRef(null);
    const qrInstanceRef = useRef(null);
    const qrCodeStyle = useRef(null);
    const [downloadFormat, setDownloadFormat] = useState("png");

    useEffect(() => {
        qrInstanceRef.current = new QRCodeStyling({
        width: 200,
        height: 200,
        data: qrCodeData.data,
        dotsOptions: {
            color: qrCodeData.styling.dotColor,
            type: qrCodeData.styling.dotStyle,
        },
        cornersSquareOptions: {
            color: qrCodeData.styling.squareColor,
            type: qrCodeData.styling.squareStyle,
        },
        cornersDotOptions: {
            color: qrCodeData.styling.squareColor,
            type: qrCodeData.styling.squareStyle,
        },
        backgroundOptions: {
            color: qrCodeData.styling.bgColor,
        },
        imageOptions: {
            crossOrigin: 'anonymous',
            margin: 10,
        },
        image: qrCodeData.styling.logo,
        });
        if (containerRef.current) {
        containerRef.current.innerHTML = "";
        qrInstanceRef.current.append(containerRef.current);
        }
    }, [qrCodeData]);

    const handleDownload = async () => {
        try {
            const qrCodeElement = qrCodeStyle.current;
            if (!qrCodeElement) {
                console.error("L'élément contenant le QR code n'a pas pu être trouvé.");
                return;
            }
            const canvas = await html2canvas(qrCodeElement, {
                backgroundColor: null,
                useCORS: true,
            });

            if (downloadFormat === "png") {
                const imageDataUrl = canvas.toDataURL("image/png");
                const link = document.createElement("a");
                link.href = imageDataUrl;
                link.download = "qr-code.png";
                link.click();
            } else if (downloadFormat === "jpeg") {
                const imageDataUrl = canvas.toDataURL("image/jpeg");
                const link = document.createElement("a");
                link.href = imageDataUrl;
                link.download = "qr-code.jpeg";
                link.click();
            } else if (downloadFormat === "pdf") {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF({
                    orientation: "portrait",
                    unit: "pt",
                    format: [canvas.width, canvas.height]
                });
                pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
                pdf.save("qr-code.pdf");
            }
        } catch (error) {
            console.error("Erreur lors du téléchargement du QR code stylisé :", error);
        }
    };

  return (
    <div className="group rounded-[20px] bg-[var(--card-background)] relative p-5 pt-10 border border-[var(--border)] transition duration-500 ease-out overflow-visible hover:border-[var(--accent)] hover:shadow-[0_4px_18px_0_rgba(0,0,0,0.25)]">
      <p className='w-full text-left font-bold absolute top-2 left-3'>#{qrCodeData.id}</p>
      <div className='flex flex-col items-center justify-center gap-5' ref={qrCodeStyle}>
        <div
            className="qr-code-preview rounded-3xl p-6"
            style={{ backgroundColor: qrCodeData.styling.bgColor }}
            ref={containerRef}
        ></div>
        {qrCodeData.text.text !== "" &&
        <p
            className="py-2 px-4"
            style={{
                borderRadius: `${qrCodeData.text.radius}px`,
                backgroundColor: qrCodeData.text.bgColor,
                color: qrCodeData.text.color,
                padding: '10px 20px',
                fontSize: '16px',
            }}
        >
            {qrCodeData.text.text}
        </p>}
    </div> 
        <button
            className="transform translate-x-[-50%] translate-y-[125%] rounded-full bg-[var(--accent)] text-[var(--text-primary)] text-sm py-1 px-2 absolute left-1/2 bottom-0 opacity-0 transition duration-300 ease-out group-hover:translate-y-[50%] group-hover:opacity-100"
            onClick={handleDownload}
        >
            {qrCodeData.title}
        </button>
      <div className='flex flex-row items-center justify-end gap-2 absolute top-2 right-2 w-full'>
        <button
            className=" bg-red-600 text-[var(--text-primary)] rounded-full p-1 text-xs"
            onClick={() => onDelete(qrCodeData.id)}
        >
            <Trash2 size={16} color='#FFF' />
        </button>
        <div className="flex flex-row items-center gap-1">
            <button
                className="p-1 rounded-full text-[var(--text-primary)] bg-[#var(--accent)] gap-2 flex flex-row items-center justify-center"
                style={{ backgroundColor: "var(--accent)" }}
                onClick={handleDownload}
            >
                <Download color={"#fff"} size={16} />
            </button>
            <select
                value={downloadFormat}
                onChange={(e) => setDownloadFormat(e.target.value)}
                className="p-1 rounded-full text-xs border border-[var(--border)] bg-[var(--background-primary)] text-[var(--text-primary)] "
            >
                <option value="png">PNG</option>
                <option value="jpeg">JPEG</option>
                <option value="pdf">PDF</option>
            </select>
        </div>
      </div>
        
    </div>
  );
};

const AllQRCode = () => {
    const { user } = useUser();
    const dispatch = useDispatch();
    const email = useSelector((state) => state.user.email);
    // Créez un state local pour les QR codes
    const [qrCodes, setQrCodes] = useState(user.qrcode || {});
  
    // Synchroniser le state local si user.qrcode change
    useEffect(() => {
      setQrCodes(user.qrcode || {});
    }, [user.qrcode]);
  
    const handleDelete = async (id) => {
      // Copiez l'objet et supprimez l'élément correspondant
      const updatedQRCodes = { ...qrCodes };
      delete updatedQRCodes[id];
      // Mettez à jour le state local pour un rendu immédiat
      setQrCodes(updatedQRCodes);
      // Mettez à jour l'objet user via sa méthode updateQRCode
      user.updateQRCode(updatedQRCodes);
      // Mettez à jour Firestore
      await updateUserInfo(email, {qrcode: updatedQRCodes}, dispatch);
    };

  return (
    <div className="DashboardPages">
        <div className='flex w-full flex-col gap-5'>
            <div className="flex flex-wrap gap-5 flex-row w-full p-5">
                {user.qrcode && Object.values(user.qrcode).map(qr => (
                <QRCodeCard key={qr.id} qrCodeData={qr} onDelete={handleDelete} />
                ))}
            </div>
        </div>
    </div>
  );
}

export default AllQRCode;
