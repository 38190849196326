const accessTokenGlobal = process.env.REACT_APP_TOKEN_ACCESS_INSTAGRAM;

export async function getInstagramReelThumbnail(reelUrl) {
    try {
      const endpoint = `https://graph.facebook.com/v22.0/instagram_oembed
        ?url=${encodeURIComponent(reelUrl)}
        &fields=thumbnail_url,author_name,provider_name,provider_url
        &access_token=${accessTokenGlobal}`
        .replace(/\s+/g, ''); // pour supprimer les espaces si multi-lignes
  
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }
      const data = await response.json();
      return data; // contient thumbnail_url, author_name, etc.
    } catch (error) {
      console.error('Erreur oEmbed Instagram:', error);
      return null;
    }
}

export async function getInstagramReelsOEmbedData(reelUrl, options = {}) {
  const { hidecaption = false, maxwidth, omitscript = false, accessToken } = options;
  // Utilise le token passé dans options ou le token global défini dans votre fichier
  const token = accessToken || accessTokenGlobal;
  if (!token) {
    throw new Error("Un access token est requis pour appeler l'API Instagram oEmbed.");
  }
  
  const params = new URLSearchParams();
  params.append('url', reelUrl);
  if (hidecaption) {
    params.append('hidecaption', 'true');
  }
  if (maxwidth) {
    params.append('maxwidth', maxwidth.toString());
  }
  if (omitscript) {
    params.append('omitscript', 'true');
  }
  params.append('access_token', token);
  
  const endpoint = `https://graph.facebook.com/v22.0/instagram_oembed?${params.toString()}`;
  
  try {
    const response = await fetch(endpoint);
    console.log(response);
    if (!response.ok) {
      throw new Error(`Erreur lors de la récupération des données oEmbed : ${response.status}`);
    }
    const data = await response.json();
    console.log(data)
    return data;
  } catch (error) {
    console.error('getInstagramReelsOEmbedData error:', error);
    throw error;
  }
}

export async function fetchUserProfile() {
  const accessToken =  accessTokenGlobal; // Remplacez par votre access token valide
  const endpoint = `https://graph.facebook.com/v22.0/me?fields=id,name&access_token=${accessToken}`;

  try {
    const response = await fetch(endpoint);
    if (!response.ok) {
      throw new Error(`Erreur HTTP : ${response.status}`);
    }
    const data = await response.json();
    console.log('Données utilisateur :', data);
    return data;
  } catch (error) {
    console.error('Erreur lors de la récupération des données :', error);
    throw error;
  }
}
  
export function extractReelsShortcode(reelUrl) {
    try {
      const urlObj = new URL(reelUrl);
      const baseWithoutQuery = `${urlObj.origin}${urlObj.pathname}`;
      const parts = baseWithoutQuery.split('/reel/');
      if (parts.length > 1) {
        const reelPart = parts[1].replace('/', '');
        return reelPart; // ex: "CnyZgjvK3aE"
      }
      return null;
    } catch (err) {
      console.error('Erreur extractReelsShortcode:', err);
      return null;
    }
}
