import React, { useState, useEffect } from 'react';
import InputFieldText from '../InputField/InputFieldText';
import { useUser } from '../../utils/contexts/userContext';
import './LinkPodcast.css';
import { useDispatch } from 'react-redux';
import { setPage } from '../../services/store/reducers/userSlice';
import { searchSpotifyPodcasts } from '../../services/api/spotify';
import { searchDeezerPodcasts } from '../../services/api/deezer';
import { searchAppleMusicPodcasts } from '../../services/api/appleMusic';
import { searchYouTubeMusicPodcasts } from '../../services/api/youtubeMusic';
import { getSpotifyPodcastEpisodeById } from '../../services/api/spotify';
import { getDeezerPodcastEpisodesById } from '../../services/api/deezer';
import { getAppleMusicPodcastEpisodeById } from '../../services/api/appleMusic';
import { getYouTubeMusicPodcastEpisodeById } from '../../services/api/youtubeMusic';
import { Podcast, Search } from 'lucide-react';
import AppleMusic from '../../assets/applemusic-color.png';
import YoutubeMusic from '../../assets/youtubemusic-color.png';
import Spotify from '../../assets/spotify-color.png';
import Deezer from '../../assets/deezer-color.png';

const LinkPodcastEdit = ({ obj, handleCloseModal }) => {
    const { user } = useUser();
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [hoveredSong, setHoveredSong] = useState(null); // Stocke l'index de la chanson survolée
    const [selectedPlatforms, setSelectedPlatforms] = useState([]); // Plateformes sélectionnées
    const [normalizedMusic, setNormalizedMusic] = useState(null); // Données normalisées de la musique
    const dispatch = useDispatch();

    const platforms = [
        { name: 'spotify', logo: Spotify },
        { name: 'deezer', logo: Deezer },
        { name: 'applemusic', logo: AppleMusic },
        { name: 'youtubemusic', logo: YoutubeMusic },
    ];

    useEffect(() => {
        // Initialise les plateformes sélectionnées à partir de `idMedia`
        if (obj.idMedia) {
            const initialPlatforms = Object.keys(obj.idMedia);
            setSelectedPlatforms(initialPlatforms);
        }
        // Récupère les données normalisées de la musique si une plateforme avec un ID existant est configurée
        const existingPlatform = obj.idMedia && Object.keys(obj.idMedia).find((key) => obj.idMedia[key]);
        if (existingPlatform) {
            const musicId = obj.idMedia[existingPlatform];
            const fetchFunction = getTrackFunctionByPlatform(existingPlatform);
            if (fetchFunction && musicId) {
                fetchFunction(musicId)
                    .then((data) => {
                        if (data) {
                            setNormalizedMusic({
                                title: data.title,
                                podcast: data.podcastTitle,
                            });
                        } else {
                            setError(`Impossible de récupérer les informations du podcast sur ${existingPlatform}.`);
                        }
                    })
                    .catch((error) => {
                        console.error(`Erreur lors de la récupération des données sur ${existingPlatform}:`, error);
                        setError(`Erreur lors de la récupération des données sur ${existingPlatform}.`);
                    });
            }
        }
    }, [obj.idMedia]);

    const togglePlatform = async (platform) => {
        setSelectedPlatforms((prev) => {
            const isSelected = prev.includes(platform);
            const updated = isSelected ? prev.filter((p) => p !== platform) : [...prev, platform];

            if (isSelected) {
                // Supprime la plateforme de `idMedia`
                const idMedia = { ...obj.idMedia };
                delete idMedia[platform];
                user.updateObjectInPageById(obj.id, { idMedia });
                dispatch(setPage(user.page));
            } else if (normalizedMusic) {
                // Recherche la musique sur la nouvelle plateforme
                const {podcast, title } = normalizedMusic;
                const searchFunction = getSearchFunctionByPlatform(platform, 1);
                if (searchFunction) {
                    searchFunction(podcast, title)
                        .then((songs) => {
                            if (songs && songs.length > 0) {
                                const song = songs[0]; // Prend le premier résultat
                                const idMedia = { ...obj.idMedia, [platform]: song.id };
                                user.updateObjectInPageById(obj.id, { idMedia });
                                dispatch(setPage(user.page));
                            } else {
                                setError(`Musique introuvable sur ${platform}.`);
                            }
                        })
                        .catch((error) => {
                            console.error(`Erreur lors de la recherche sur ${platform}:`, error);
                            setError(`Erreur lors de la recherche sur ${platform}.`);
                        });
                }
            }

            return updated;
        });
    };

    const getTrackFunctionByPlatform = (platform) => {
        switch (platform) {
            case 'spotify':
                return getSpotifyPodcastEpisodeById;
            case 'deezer':
                return getDeezerPodcastEpisodesById;
            case 'applemusic':
                return getAppleMusicPodcastEpisodeById;
            case 'youtubemusic':
                return getYouTubeMusicPodcastEpisodeById;
            default:
                return null;
        }
    };

    const getSearchFunctionByPlatform = (platform, nbTracks) => {
        switch (platform) {
            case 'spotify':
                return (podcast, title) => searchSpotifyPodcasts(`${podcast}, ${title}`, nbTracks);
            case 'deezer':
                return (podcast, title) => searchDeezerPodcasts(`${podcast}, ${title}`, nbTracks);
            case 'applemusic':
                return (podcast, title) => searchAppleMusicPodcasts(`${podcast}, ${title}`, nbTracks);
            case 'youtubemusic':
                return (podcast, title) => searchYouTubeMusicPodcasts(`${podcast}, ${title}`, nbTracks);
            default:
                return null;
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (query.trim()) {
                handleSearch();
            }
        }, 200);

        return () => clearTimeout(delayDebounceFn);
    }, [query]);

    const handleSearch = async () => {
        if (selectedPlatforms.length === 0) {
            setError('Veuillez sélectionner une ou plusieurs plateformes avant de rechercher des podcasts.');
            return;
        }

        setLoading(true);
        setError('');
        setResults([]);

        try {
            const promises = selectedPlatforms.map((platform) => {
                const searchFunction = getSearchFunctionByPlatform(platform, 20);
                if (searchFunction) {
                    return searchFunction(query, ['']);
                }
                return Promise.resolve([]);
            });

            const platformResults = await Promise.all(promises);
            const aggregatedResults = platformResults.flat().reduce((acc, song) => {
                //console.log(acc, platformResults)
                const existingSong = acc.find(
                    (item) =>
                        item.title.toLowerCase() === song.title.toLowerCase() &&
                        item.podcastCreator.toLowerCase() === song.podcastCreator.toLowerCase()
                );
                if (existingSong) {
                    if (!existingSong.platforms.includes(song.platform)) {
                        existingSong.platforms.push(song.platform);
                    }
                } else {
                    acc.push({ ...song, platforms: [song.platform] });
                }
                return acc;
            }, []);
            setResults(aggregatedResults);
        } catch (err) {
            setError('Une erreur est survenue lors de la recherche.');
            console.error(err);
        }

        setLoading(false);
    };


    const handleSave = (song) => {
        const idMedia = song.platforms.reduce((acc, platform) => {
            acc[platform] = song.id;
            return acc;
        }, {});
        console.log(idMedia);
        user.updateObjectInPageById(obj.id, { idMedia });
        dispatch(setPage(user.page));
        handleCloseModal();
    };

    return (
        <>
        <div className="flex items-center flex-col justify-center w-full">
            <div className="platformSelection">
                {platforms.map(({ name, logo }) => (
                    <button
                        key={name}
                        onClick={() => togglePlatform(name)}
                        className={`platformButton ${selectedPlatforms.includes(name) ? 'selected' : ''}`}
                    >
                        <img className="w-5 h-5 object-contain object-center rounded" src={logo} alt={name} title={name} />
                    </button>
                ))}
            </div>
            <div className="searchBarWrapper">
                <InputFieldText
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Rechercher un podcast..."
                    title={
                        <>
                            Rechercher{" "}
                            <span style={{ color: "var(--text-secondary)", fontSize: "10px", textTransform: "lowercase", lineHeight: "10px", marginBottom: "8px" }}>
                                (pour une recherche plus précise : "titre podcast, titre épisode")
                            </span>
                        </>
                    }
                    type={true}
                />
                <Search className="searchIcon" />
            </div>
            {error && <p className="text-xs text-red-600 w-full text-left">{error}</p>}
            {loading && <p className="text-sm text-blue-600 w-full text-left">Chargement...</p>}
            <div className="resultsContainer">
                {results.map((song, index) => (
                    <div
                        key={index}
                        className={`resultItem ${hoveredSong === index ? 'hovered' : ''}`}
                        onMouseEnter={() => setHoveredSong(index)}
                        onMouseLeave={() => setHoveredSong(null)}
                        onClick={() => handleSave(song)}
                    >
                        {song.image ? 
                            <img src={song.image} alt={song.title} className="songImage" />
                        :
                            <div className='' style={{minWidth: "50px", maxWidth: "50px", height: "50px", borderRadius: "10px", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <i className="fa-solid fa-image" style={{color: "#000", fontSize: "360%"}}></i>
                            </div>
                        }
                        
                        <p className="titleSingerSong">
                            <strong>{song.title}</strong>
                            {song.podcastTitle 
                                ? song.podcastCreator 
                                    ? `${song.podcastTitle} - ${song.podcastCreator}` 
                                    : `${song.podcastTitle}` 
                                : song.podcastCreator || ""}
                        </p>
                    </div>
                ))}
            </div>
        </div>
        <div className="containerButtonImgProfil">
            <button className="text-red-600 no-underline font-semibold uppercase text-center py-2 px-6 text-base rounded-[50px] hover:border-red-600 hover:bg-red-600 hover:text-[var(--text-primary)] border-2 border-[var(--text-primary)] bg-[var(--text-primary)] cursor-pointer" onClick={handleCloseModal}>
                Annuler
            </button>
        </div>
    </>
    );
};

export default LinkPodcastEdit;
