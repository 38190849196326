import React from 'react'
import styles from './Logo.module.css';
import ImageProfile from '../ImageProfile/ImageProfile';

function Logo(props) {

    const hobspace = props.hobspace;
 
    return (
        <div className={styles.Logo}>
            <div className={styles.containerCover}>
                <ImageProfile img={props.image} imgFullCover={false} format={true} />
            </div>
            {props.logo &&
                <img className={styles.imgLogo} src={props.logo} alt="logo personnalité" />
            }
            {hobspace.firstname === "" && hobspace.lastname === "" ? null :
                <h1 className={styles.titleProfile} style={{color: hobspace.colorFont}}>{hobspace.firstname} {hobspace.lastname}</h1>
            }
            {hobspace.description === "" ? null :
                <p className={styles.subtitleProfile} style={{color: hobspace.colorFont}}>{hobspace.description}</p>
            }
            
        </div>
    );
}

export default Logo;