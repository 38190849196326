import React, { useEffect, useState } from 'react';
import './MusicProfil.css';
import { useUser } from '../../utils/contexts/userContext';
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../utils';
import CustomModalPreview from '../Modal/CustomModal/CustomModalPreview';
import AppleMusic from '../../assets/AppleMusic-logo-complet.png';
import Deezer from '../../assets/Deezer-logo-complet.png';
import Spotify from '../../assets/Spotify-logo-complet.png';
import YoutubeMusic from '../../assets/YtbMusic-logo-complet.png';

function MusicProfil(props) {
    const bgdImage = useSelector(state => state.user.backgroundImage);
    const { user } = useUser();
    const images = useSelector((state) => state.user.images);
    const [showModal, setShowModal] = useState(false);
    const [availablePlatforms, setAvailablePlatforms] = useState([]);

    const platforms = ['youtubemusic', 'spotify', 'deezer', 'applemusic'];
    
    useEffect(() => {
        const available = platforms.filter(platform => {
            const link = user.links.find(link => link.name === platform)?.link;
            return link;
        });
        setAvailablePlatforms(available);
    }, [user.links]);

    const getLinkPlateforme = (platform) => {
        return user.links.find(link => link.name === platform)?.link;
    }

    const renderLogo = (platform) => {
        switch (platform) {
            case 'spotify':
                return {logo: Spotify};
            case 'deezer':
                return {logo: Deezer};
            case 'applemusic':
                return {logo: AppleMusic};
            case 'youtubemusic':
                return {logo: YoutubeMusic};
            default:
                return {logo: ""};
        }
    };

    // Handle modal visibility
    const toggleModal = () => setShowModal(!showModal);

    return (
        <div className={`MusicProfil`} style={bgdImage ? { backgroundColor: hexToRgb(user.backgroundColor) } : { backgroundColor: `rgba(255,255,255,0.15)` }}>
            <img className={`imgProfil`} src={images} alt='profile' />
            <div className={`containerInfoPerso`}>
                <p className={`titleProfil`} style={{ color: user.colorFont }}>{user.lastname + " " + user.firstname}</p>
                <p className={`singerProfil`} style={{ color: user.colorFont }}>{props.description !== "" ? props.description : "Décrivez vous."}</p>
            </div>

            <div className={`linkPlateformMusic`} style={bgdImage ? { backgroundColor: hexToRgb(user.colorFont, 1) } : { backgroundColor: user.backgroundColor }}>
                {availablePlatforms.slice(0, 2).map((platform, index) => (
                    <>
                    <a key={platform} className={"linkImgPlateformMusic gap-1"} href={getLinkPlateforme(platform)} target='_blank' rel="noreferrer">
                        <div className={`flex items-center justify-center gap-1`}>
                            <img className={`object-contain object-center h-[16px] w-auto`} src={renderLogo(platform).logo} alt={`${platform} logo`}/>
                        </div>
                    </a>
                    {index !== availablePlatforms.length - 1 &&
                        <div className={`h-[60%] min-w-[1.5px] rounded-[50px] mx-2`} style={bgdImage ? {backgroundColor: user.backgroundColor} : {backgroundColor: user.colorFont}}></div> 
                    }
                    </>
                ))}
                
                {availablePlatforms.length > 2 && (
                    <>
                    <button className="moreButton" onClick={toggleModal} style={bgdImage ? {color: user.backgroundColor} : {color: user.colorFont}}>
                        More
                    </button>
                    </>
                )}
            </div>

            <CustomModalPreview
            isOpen={showModal}
            onClose={() => setShowModal(false)}>
                <div className={`flex items-center flex-col justify-center relative w-full`}>
                    <div className='relative flex flex-row items-center justify-around w-4/5 h-20 gap-2 rounded-t-[10px]' style={{ backgroundColor: user.backgroundColor }}>
                        <img className={`rounded-[10px] object-cover object-center ml-3 mb-7 h-[80px] w-[80px] top-[-20px] left-[15px]`} src={images || null} alt="music"/>
                        <div className={`text-left top-[10px] mr-3`}>
                            <p className={`text-[14px] capitalize truncate text-left font-semibold overflow-hidden w-[130px] m-0`} style={{ color: user.colorFont }}>
                            {user.lastname + " " + user.firstname}
                            </p>
                            <p className={`capitalize text-white text-opacity-60 font-medium text-[12px] mt-[2px] mb-[10px] w-[130px] truncate`} style={{ color: user.colorFont }}>
                            {props.description !== "" ? props.description : "Décrivez vous."}
                            </p>
                        </div>
                    </div>
                    <div className={`flex flex-col w-4/5 items-center justify-center rounded-b-[10px] bottom-[0px] gap-0 px-3 py-2`} style={{ backgroundColor: user.colorFont }}>
                        {availablePlatforms.length > 1 && availablePlatforms.map((platform, index) => (
                            <div className='w-full' key={platform}>
                                <a className={`flex items-center justify-between flex-row cursor-pointer no-underline`} href={getLinkPlateforme(platform)} target="_blank" rel="noreferrer">
                                    <div className={`flex items-center justify-center gap-2`}>
                                        <img className={`object-contain object-center h-[16px] w-auto`} src={renderLogo(platform).logo} alt={`${platform} logo`}/>
                                    </div>
                                    <i className="fa-solid fa-arrow-up-right-from-square" style={{color: "#000", fontSize: "12px"}}></i>
                                    {/* <p className={`rounded-[20px] no-underline leading-[12px] cursor-pointer border border-black bg-transparent capitalize text-black font-medium text-[12px] px-[8px] py-[3px]`}>Play</p> */}
                                </a>
                                {index !== availablePlatforms.length - 1 &&
                                    <div className={`h-[0.5px] w-[90%] bg-black bg-opacity-10 rounded-[50px] my-[5px]`}></div> 
                                }
                                
                            </div>
                        ))}
                    </div>
                </div>
            </CustomModalPreview>
        </div>
    );
}

export default MusicProfil;
