import React, { useState, useEffect } from 'react'
import './VideoCollection.css';
import Title from '../Title/Title';
import Slider from '../Slider/Slider';
import OneVideo from '../OneVideo/OneVideo';
import { getLatestYoutubeVideos } from '../../services/api/youtube';
import { useUser } from '../../utils/contexts/userContext';

function VideoCollection(props) {
    const {user} = useUser();
    const [videos, setVideos] = useState(undefined);
  
    useEffect(() => {  
      async function fetchData() {
        const v = await getLatestYoutubeVideos(user, props.nbVideo);
        console.log(v);

        if (v === null) {
          return;
        }
        setVideos(v.map((video, index) => (
          <OneVideo
            key={index}
            idMedia={`https://www.youtube.com/watch?v=${video.id.videoId}`}
            description={false}
          />
        )));
      }

      fetchData();
    }, [user, props.nbVideo])
 
    return (
        <div className='VideoCollection'>
            {props.title &&
                <Title id={props.ancre} color={user.colorFont} title={props.title} marginBottom={true} />
            }
            {videos && videos.lenght > 0 && <Slider components={videos} />}
        </div>
    );
}

export default VideoCollection;