import React, {useState, useEffect} from 'react';
import "./Link.css";
import InputFieldText from '../InputField/InputFieldText';
import { useUser } from '../../utils/contexts/userContext';
import ButtonEditImageComponent from "../ButtonEditImage/ButtonEditImageComponent";
import { getImageComponent } from '../../services/storage/storageService';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../../services/store/reducers/userSlice';

const LinkEdit = ({ obj, handleCloseModal, type }) => { // Renommé container en linkContainer

    const {user} = useUser();
    const email = useSelector((state) => state.user.email)
    const [url, setUrl] = useState("");
    const [error, setError] = useState({status: false, message: ""});
    const [image, setImage] = useState(null);
    const [titleComponent, setTitleComponent] = useState("");
    const [subtitleComponent, setSubtitleComponent] = useState("");
    const dispatch = useDispatch();

    const handleChangeLink = (newValue) => {
        setUrl(newValue);
    };

    const handleChangeTitle = (newValue) => {
        setTitleComponent(newValue);
    };

    const handleChangeSubtitle = (newValue) => {
        setSubtitleComponent(newValue);
    };

    const sentEditComponent = () => {
        user.updateObjectInPageById(obj.id, {title: titleComponent, subtitle: subtitleComponent, link: url});
        dispatch(setPage(user.page));
        handleCloseModal();     
    }

    useEffect(() => {
        setTitleComponent(obj.title); 
        setUrl(obj.link); 
        setSubtitleComponent(obj.subtitle); 
        const fetchMedia = async () => {
            if (obj) {
                try {
                    // Utilisation de la fonction pour récupérer le média
                    const url = await getImageComponent(email, 'Link', obj.id);
                    setImage(url); // Mettre à jour la prévisualisation avec l'URL récupérée
                } catch (error) {
                    console.error('Erreur lors de la récupération du média:', error);
                    setImage(null); // Réinitialiser si une erreur se produit
                }
            }
        };

        fetchMedia();
    }, [user, obj]);; 

    return (
        <>
        <div className='flex items-center flex-col justify-center w-full gap-6'>
            <InputFieldText value={titleComponent} maxLength={256} onChange={(e) => handleChangeTitle(e.target.value)} placeholder={""} title={type ? "Nom du produit" : "Titre"} type={true} />
            <InputFieldText value={subtitleComponent} maxLength={256} onChange={(e) => handleChangeSubtitle(e.target.value)} placeholder={""} title={type ? "Prix du produit" : "Sous-titre"} type={true} />
            <InputFieldText value={url} maxLength={2048} onChange={(e) => handleChangeLink(e.target.value)} placeholder={""} title={type ? "Lien du produit" : "Lien"} type={true} />
            <div className='flex flex-col justify-center items-center w-full gap-3'>
                <p className='InputFieldTextTitle'>{type ? "Image du produit" : "Image"}</p>
                <div className='containerEditImgComponent'>
                    {image !== null ? 
                        <img className='ImgEditComponent' src={image} />
                    :
                        <div className='ImgEditComponent'>
                            <i className={"fa-solid fa-image"}></i>
                        </div>
                    }
                    <ButtonEditImageComponent title={"Charger une photo"} folder={"Link"} nameFile={obj.id} setImage={setImage} />
                </div>
            </div>
        </div>
        <div className='containerButtonImgProfil'>
            <button className='text-red-600 no-underline font-semibold uppercase text-center py-2 px-6 text-base rounded-[50px] hover:border-red-600 hover:bg-red-600 hover:text-[var(--text-primary)] border-2 border-[var(--text-primary)] bg-[var(--text-primary)] cursor-pointer' onClick={handleCloseModal}>Annuler</button>
            <button className='text-[var(--text-primary)] no-underline text-center font-semibold uppercase bg-[var(--accent)] hover:bg-[var(--hover)] hover:border-[var(--hover)] cursor-pointer py-2 px-6 text-base rounded-[50px] border-2 border-[var(--accent)] ' onClick={sentEditComponent}>Enregistrer</button>
        </div>
        </>
    );
};

export default LinkEdit;
