import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function formatPhoneNumber(phoneNumber, countryCode) {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber, countryCode);
    if (!phoneNumberObj) {
        return phoneNumber; // Return the original if parsing fails
    }

    // Get the national number format (without country code)
    const nationalNumber = phoneNumberObj.formatNational();

    // If the number starts with the international dialing code, replace it with the local prefix
    if (phoneNumberObj.countryCallingCode) {
        const regex = new RegExp(`^\\+${phoneNumberObj.countryCallingCode}`);
        if (regex.test(phoneNumber)) {
            return nationalNumber.replace(/\D/g, ''); // Remove non-digit characters
        }
    }
    return phoneNumber;
}

export function formatStringSinger(inputString) {
    let formattedString = inputString.toLowerCase().replace(/[()-\s]/g, '');
    const featIndex = formattedString.indexOf('feat');
    if (featIndex !== -1) {
        formattedString = formattedString.substring(0, featIndex).trim();
    }
    return formattedString;
}

export function copyToClipboard(text, setVisible) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Texte copié dans le presse-papier');
            setVisible(true); // Affiche le message de confirmation
            setTimeout(() => setVisible(false), 2000); // Cache le message après 2 secondes
        }).catch(err => {
            console.error('Erreur lors de la copie du texte : ', err);
        });
    } else {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
            console.log('Texte copié dans le presse-papier');
            setVisible(true); // Affiche le message de confirmation
            setTimeout(() => setVisible(false), 2000); // Cache le message après 2 secondes
        } catch (err) {
            console.error('Erreur lors de la copie du texte : ', err);
        }
        document.body.removeChild(textarea);
    }
}

export const transformerChaine = (chaine) => {
    const chaineMinuscules = chaine.toLowerCase();
    const positionFeat = chaineMinuscules.indexOf('feat');
    let chaineSansFeat;
    if (positionFeat !== -1) {
        chaineSansFeat = chaineMinuscules.substring(0, positionFeat);
    } else {
        chaineSansFeat = chaineMinuscules;
    }
    const chaineSansSpeciaux = chaineSansFeat.replace(/[^a-zA-Z0-9\s]/g, '');
    const chaineTransformee = chaineSansSpeciaux.replace(/ /g, '%20');
    return chaineTransformee;
}

export const formatAncre = (text) => {
    return text.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
};

export const generateUniqueId = () => {
    return 'id-' + Date.now() + '-' + Math.floor(Math.random() * 10000);
};

export function isValidUrl(url) {
    // Expression régulière pour valider une URL avec des chemins supplémentaires
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)' + // Protocole obligatoire (http ou https)
        '([^\\s]+\\.)' + // Au moins un caractère avant le point, aucun espace autorisé
        '[a-z]{2,}' + // Domaine de premier niveau (au moins 2 lettres)
        '(\\/[^\\s]*)*$', // Chemin optionnel (peut inclure des sous-chemins), aucun espace autorisé
        'i' // Modificateur insensible à la casse
    );

    return urlPattern.test(url);
}

export function isValidString(str) {
    const pattern = /^[a-zA-Z0-9\-_'\u00C0-\u017F]*$/;
    return pattern.test(str);
}

// Fonction pour tronquer le nom du fichier si trop long
export const truncateFileName = (fileName, maxLength = 20) => {
    if (fileName.length <= maxLength) return fileName;

    const extension = fileName.substring(fileName.lastIndexOf('.'));
    const nameWithoutExt = fileName.substring(0, fileName.lastIndexOf('.'));
    const truncatedName = nameWithoutExt.substring(0, maxLength - extension.length - 3); // -3 pour '...'

    return `${truncatedName}...${extension}`;
};

export function capitalizeFirstLetter(str) {
    if (!str || typeof str !== 'string') return ''; // Retourne une chaîne vide si str est undefined ou non une chaîne
    return str.charAt(0).toUpperCase() + str.slice(1); // Capitalise la première lettre
}

export function normalizeSearchTerm(searchTerm) {
    return searchTerm
        // Supprimer les apostrophes avant ou après les lettres
        .replace(/'([^']+)'/g, '$1')  // Retirer les apostrophes entourant des mots
        // Supprimer les caractères spéciaux comme les ponctuations
        .replace(/[^a-zA-Z0-9\s]/g, '')  // Supprimer les caractères non alphanumériques
        // Supprimer les mots qui contiennent des caractères spéciaux (les mots avec des symboles)
        .replace(/\b[\W_]+\b/g, '')  // Supprimer les mots uniquement composés de caractères non alphanumériques
        // Remplacer les espaces multiples par un seul espace
        .replace(/\s+/g, ' ')
        // Supprimer les espaces en début et fin de chaîne
        .trim()
        // Convertir en minuscule pour une recherche insensible à la casse
        .toLowerCase()
        // Garder uniquement les mots de plus de 3 lettres
        .split(' ') // Diviser la chaîne en mots
        .filter(word => word.length > 3) // Garder seulement les mots de plus de 3 lettres
        .join(' '); // Rejoindre les mots restants avec des espaces
}
