import React, { useState, useEffect, useRef } from 'react';
import styles from './LinksTikTok.module.css';
import Title from '../Title/Title';
import Slider from '../Slider/Slider';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import LinkTikTok from '../LinkTikTok/LinkTikTok';
import CustomModal from '../Modal/CustomModal/CustomModal';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { extractShortId } from '../../../services/api/youtubeShort';
import { extractReelsShortcode } from '../../../services/api/instagram';
import { extractTiktokVideoId , getTiktokOEmbedData} from '../../../services/api/tiktok';

function VideoEmbed({ idMedia, activePlatform }) {
  const [embedUrl, setEmbedUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchEmbedUrl() {
      if (!activePlatform) {
        setError("Aucune plateforme active");
        return;
      }
      const { platform } = activePlatform;
      const link = idMedia && typeof idMedia === 'object' ? (idMedia[platform] || '').trim() : '';
      if (!link) {
        setError(`URL ${platform} invalide.`);
        return;
      }
      let videoId, url;
      if (platform === 'tiktok') {
        if (link.includes("vm.tiktok.com")) {
          try {
            const data = await getTiktokOEmbedData(link);
            videoId = data.embed_product_id;
          } catch (err) {
            setError("Erreur lors du chargement de TikTok.");
            return;
          }
        } else if (link.includes("www.tiktok.com")) {
          videoId = extractTiktokVideoId(link);
        }
        if (!videoId) {
          setError("URL TikTok invalide.");
          return;
        }
        url = `https://www.tiktok.com/embed/v2/${videoId}`;
      } else if (platform === 'short') {
        videoId = extractShortId(link);
        if (!videoId) {
          setError("URL Short invalide.");
          return;
        }
        url = `https://www.youtube.com/embed/${videoId}`;
      } else if (platform === 'reels') {
        videoId = extractReelsShortcode(link);
        if (!videoId) {
          setError("URL Reels invalide.");
          return;
        }
        url = `https://www.instagram.com/reel/${videoId}/embed`;
      } else {
        setError("Plateforme non prise en charge.");
        return;
      }
      setEmbedUrl(url);
    }
    fetchEmbedUrl();
  }, [idMedia, activePlatform]);

  if (error) return <p>{error}</p>;
  if (!embedUrl) return <p>Chargement...</p>;

  let containerClass, playerClass, title;
  if (activePlatform.platform === 'tiktok') {
    containerClass = "container-player-tiktok";
    playerClass = "player-tiktok";
    title = "TikTok video";
  } else if (activePlatform.platform === 'short') {
    containerClass = "container-player-short";
    playerClass = "player-short";
    title = "YouTube short";
  } else if (activePlatform.platform === 'reels') {
    containerClass = "container-player-short";
    playerClass = "player-short";
    title = "Instagram reels";
  }
  return (
    <div className={containerClass}>
      <iframe className={playerClass} src={embedUrl} scrolling="no" title={title} />
    </div>
  );
}

function LinksTikTok(props) {
  const { hobspace } = useHobspace();
  const [activeIndex, setActiveIndex] = useState(0);
  const [components, setComponents] = useState([]);
  const sliderRef = useRef(null);
  const componentsData = props.components || [];
  const [showModal, setShowModal] = useState(false);
  
  // On stocke ici pour chaque composant (index) la plateforme active (ex: { platform: 'tiktok', link: '...' })
  const [activePlatforms, setActivePlatforms] = useState({});

  // Stocke l'instance du Swiper de la modale
  const [modalSwiper, setModalSwiper] = useState(null);

  // Boutons de navigation désactivés si on est au début ou à la fin
  const disablePrev = activeIndex === 0;
  const disableNext = activeIndex === componentsData.length - 1;

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    setShowModal(true);
  };

  const handlePrev = () => {
    if (activeIndex > 0) {
      sliderRef.current?.swiper.slidePrev();
      modalSwiper?.slidePrev();
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleNext = () => {
    if (activeIndex < componentsData.length - 1) {
      sliderRef.current?.swiper.slideNext();
      modalSwiper?.slideNext();
      setActiveIndex(activeIndex + 1);
    }
  };

  // Retourne l'icône associée à une plateforme donnée
  const renderIcon = (platform) => {
    switch (platform) {
      case 'tiktok':
        return <i className="fa-brands fa-tiktok" style={{ color: "#FFF" }} />;
      case 'short':
        return <i className="fa-brands fa-youtube" style={{ color: "#FFF" }} />;
      case 'reels':
        return <i className="fa-brands fa-instagram" style={{ color: "#FFF" }} />;
      default:
        return <i className="fa-solid fa-video" />;
    }
  };

  // Récupère les plateformes disponibles pour un idMedia donné
  const getAvailablePlatforms = (idMedia) => {
    if (!idMedia || typeof idMedia !== 'object') return [];
    const order = ['tiktok', 'short', 'reels'];
    const platforms = [];
    order.forEach((platform) => {
      const link = idMedia[platform];
      if (link && link.trim() !== '') {
        platforms.push({ platform, link: link.trim() });
      }
    });
    return platforms;
  };

  // Affiche la vidéo pour la plateforme active passée en paramètre pour ce composant
  const renderVideo = (idMedia, activePlatform) => {
    return <VideoEmbed idMedia={idMedia} activePlatform={activePlatform} />;
  };

  // Si aucune plateforme active n'est définie, on affiche la première disponible
  const renderFirstVideoForComponent = (idMedia) => {
    const available = getAvailablePlatforms(idMedia);
    if (available.length === 0) return <p>Aucune vidéo disponible</p>;
    return renderVideo(idMedia, available[0]);
  };

  // Lorsqu'on change de composant (activeIndex), on initialise la plateforme active
  useEffect(() => {
    if (componentsData.length > 0 && componentsData[activeIndex]) {
      const available = getAvailablePlatforms(componentsData[activeIndex].idMedia);
      setActivePlatforms((prev) => {
        // Si déjà définie pour cet index, on ne change pas
        if (prev[activeIndex]) return prev;
        return { ...prev, [activeIndex]: available[0] || null };
      });
    }
  }, [activeIndex, componentsData]);

  // Création du slider des miniatures
  useEffect(() => {
    const comps = componentsData.map((c, index) => (
      <LinkTikTok
        key={index}
        id={c.id}
        idMedia={c.idMedia}
        openModal={() => handleThumbnailClick(index)}
      />
    ));
    setComponents(comps);
  }, [componentsData]);

  // Mise à jour de activeIndex et activePlatforms si un composant est supprimé
  useEffect(() => {
    if (activeIndex > componentsData.length - 1) {
      setActiveIndex(componentsData.length - 1);
    }
    const newActivePlatforms = {};
    componentsData.forEach((component, index) => {
      const available = getAvailablePlatforms(component.idMedia);
      if (activePlatforms[index] && available.find(p => p.platform === activePlatforms[index].platform)) {
        newActivePlatforms[index] = activePlatforms[index];
      } else {
        newActivePlatforms[index] = available[0] || null;
      }
    });
    setActivePlatforms(newActivePlatforms);
  }, [componentsData]);

  // Dès que la modale s'ouvre ou que l'activeIndex change, on synchronise le swiper modal
  useEffect(() => {
    if (showModal && modalSwiper) {
      modalSwiper.slideTo(activeIndex, 0);
    }
  }, [showModal, activeIndex, modalSwiper]);

  // Récupération des plateformes disponibles pour le composant actif
  const currentAvailablePlatforms = componentsData[activeIndex]
    ? getAvailablePlatforms(componentsData[activeIndex].idMedia)
    : [];

  return (
    <div className={styles.LinksTikTok}>
      {props.title && (
        <Title
          id={props.ancre}
          color={hobspace.colorFont}
          title={props.title}
          marginBottom={true}
        />
      )}
      {components.length > 0 && (
        <Slider ref={sliderRef} components={components} column={false} />
      )}
      <CustomModal isOpen={showModal} onClose={() => setShowModal(false)}>
        <div className="flex flex-col items-center justify-center gap-3">
          <div className={"SliderLinksTiktok"}>
            <Swiper
              onSwiper={(swiper) => setModalSwiper(swiper)}
              slidesPerView="auto"
              centeredSlides={true}
              spaceBetween={8}
              className={"mySwiperLinksTiktok"}
            >
              {componentsData.map((component, index) => (
                <SwiperSlide key={index}>
                  {index === activeIndex
                    ? renderVideo(component.idMedia, activePlatforms[activeIndex])
                    : renderFirstVideoForComponent(component.idMedia)
                  }
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={styles.LinkTikTokIcons}>
            <div className="flex flex-row items-center justify-center gap-3">
              {currentAvailablePlatforms.map(({ platform, link }) => (
                <button
                  key={platform}
                  className={`${styles.LinkTikTokIconBtn} ${
                    activePlatforms[activeIndex]?.platform === platform
                      ? styles.active
                      : ''
                  }`}
                  onClick={() =>
                    setActivePlatforms((prev) => ({
                      ...prev,
                      [activeIndex]: { platform, link },
                    }))
                  }
                >
                  {renderIcon(platform)}
                </button>
              ))}
            </div>
            <div className="flex gap-3">
              <button
                className="nav-button prev gap-1 flex items-center justify-center"
                onClick={handlePrev}
                disabled={disablePrev}
                style={{
                  color: disablePrev ? 'rgba(255, 255, 255, 0.5)' : '#FFF',
                  fontSize: '14px',
                }}
              >
                <i
                  className="fa-solid fa-chevron-left"
                  style={{
                    color: disablePrev ? 'rgba(255, 255, 255, 0.5)' : '#FFF',
                    fontSize: '12px',
                  }}
                />
                <span>Précédent</span>
              </button>
              <button
                className="nav-button next gap-1 flex items-center justify-center"
                onClick={handleNext}
                disabled={disableNext}
                style={{
                  color: disableNext ? 'rgba(255, 255, 255, 0.5)' : '#FFF',
                  fontSize: '14px',
                }}
              >
                <span>Suivant</span>
                <i
                  className="fa-solid fa-chevron-right"
                  style={{
                    color: disableNext ? 'rgba(255, 255, 255, 0.5)' : '#FFF',
                    fontSize: '12px',
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

export default LinksTikTok;
