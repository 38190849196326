import React, { useState, useEffect, useRef } from 'react';
import './NavigationDashboard.css';
import ButtonMenu from '../../../components/ButtonMenu/ButtonMenu';
import { Link } from "react-router-dom";
import HobsPage from '../../DashboardPages/HobsPage/HobsPage';
import MailSignature from '../../DashboardPages/PortailMananger/MailSignature';
import Collaborateurs from "../../DashboardPages/PortailMananger/Collaborateurs";
import MonEntreprise from "../../DashboardPages/PortailMananger/MonEntreprise";
import Tutorial from '../../DashboardPages/Tutorial/Tutorial';
import Statistics from '../../DashboardPages/Statistics/Statistics';
import Settings from '../../DashboardPages/Settings/Settings';
import QrCodeGenerator from '../../DashboardPages/QrCodeGenerator/QrCodeGenerator';
import { useSelector, useDispatch } from "react-redux";
import { pageDashboard } from '../../../services/store/reducers/userSlice';
import { logout } from '../../../services/auth/authService';
import Logo from '../../../assets/logo.png';
import LogoBlack from "../../../assets/logo-black.png";
import { useUser } from "../../../utils/contexts/userContext";
import UserProfileMenu from '../../../components/UserProfilMenu/UserProfilMenu';
import ChooseSubscription from '../../../components/ButtonEditImage/ChooseSubscription';
import { ChevronDown } from 'lucide-react';
import AllQRCode from '../../DashboardPages/QrCodeGenerator/AllQRCode';

function NavigationDashboard(props) {
  const page = useSelector((state) => state.user.pageDashboard);
  const [showUserProfileMenu, setShowUserProfileMenu] = useState(false);
  const [isMenuAnimating, setIsMenuAnimating] = useState(false);
  
  // State pour le thème, initialisé à partir de data-theme
  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme') || 'dark');
  const { user, loading, isAuthenticated } = useUser();
  const images = useSelector((state) => state.user.images);
  const email = useSelector((state) => state.user.email);
  const dispatch = useDispatch();

  // Références pour les conteneurs desktop et mobile
  const desktopMenuContainerRef = useRef(null);
  const mobileMenuContainerRef = useRef(null);

  const toggleUserProfileMenu = () => {
    setShowUserProfileMenu(!showUserProfileMenu);
    if (showUserProfileMenu) {
      setTimeout(() => {
        setIsMenuAnimating(false);
      }, 300);
    } else {
      setIsMenuAnimating(true);
    }
  };

  const handleLogout = async () => {
    await logout(dispatch);
  };

  const handleButtonClick = (title) => {
    dispatch(pageDashboard(title));
  };

  useEffect(() => {
      // Observer pour détecter les changements de l'attribut 'data-theme'
      const observer = new MutationObserver((mutationsList) => {
          mutationsList.forEach(mutation => {
              if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
                  const currentTheme = document.documentElement.getAttribute('data-theme') || 'dark';
                  setTheme(currentTheme);
              }
          });
      });

      observer.observe(document.documentElement, { attributes: true });
      
      return () => observer.disconnect();
  }, []);

  // Fermeture du menu si clic en dehors des conteneurs
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (desktopMenuContainerRef.current && desktopMenuContainerRef.current.contains(event.target)) ||
        (mobileMenuContainerRef.current && mobileMenuContainerRef.current.contains(event.target))
      ) {
        return;
      }
      setShowUserProfileMenu(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className='NavigationDashboard' style={{ overflowX: isMenuAnimating ? "visible" : "hidden" }}>
        <div className='containerTitleDashboard'>
          <h1 className='titleDashboard'>{page}</h1>
          {isAuthenticated && (
            <div className="flex gap-4 flex-row items-center justify-center">
              <ChooseSubscription 
                email={email} 
                user={user} 
                title={"Débloquez toutes les fonctionnalités de Hobs Space"} 
                subTitle={"Ne passez plus à côté des outils qui feront la différence. Abonnez-vous et profitez d’une expérience complète sur Hobs Space."} 
              />
              {/* Version desktop : le bouton et le menu dans un conteneur relatif */}
              <div ref={desktopMenuContainerRef} style={{ position: 'relative', display: 'inline-block' }}>
                <button className='linkNavigationProfil signup' onClick={toggleUserProfileMenu}>
                  {images ? (
                    <img className='imgProfilNavigation' src={images} alt="profil" />
                  ) : (
                    <div className='imgProfilNavigation'>
                      <i className="fa-regular fa-user"></i>
                    </div>
                  )}
                  <p className='nameProfilNavigation'>{user.nameSpace}</p>
                </button>
                <UserProfileMenu
                  isAuthenticated={isAuthenticated}
                  user={user}
                  showUserProfileMenu={showUserProfileMenu}
                  toggleUserProfileMenu={toggleUserProfileMenu}
                  images={images}
                  handleLogout={handleLogout}
                  mobileView={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='containerMenuDashboard'>
        <Link to={"/"} className={`containerLogoDashboard border-r-[var(--border)] border-b-[var(--border)] border-r border-b`}>
          <img className='logo logoDashboard' src={theme === "light" ? LogoBlack : Logo} alt="logo hob's space retour accueil" />
        </Link>
        <br />
        <ButtonMenu title={"Mon Hob's Space"} active={page === "Mon Hob's Space"} onClick={() => handleButtonClick("Mon Hob's Space")} color={props.color} icon={"fa-solid fa-link"} />
        <ButtonMenu title={"QR Code"} active={page === "Générer un QR Code" || page === "Mes QR Codes" } menu={true} onClick={page === "Mes QR Codes" ? () => {} : () => handleButtonClick("Générer un QR Code")} color={props.color} icon={"fa-solid fa-qrcode"} height={"72px"}>
          <div className='flex flex-col items-end mt-2 justify-center w-full relative'>
            <div className='h-16 absolute left-3 top-0 w-1 rounded-full bg-[var(--text-primary)]'></div>
            <button active={page === "Générer un QR Code"} onClick={() => handleButtonClick("Générer un QR Code")} className={`${page === "Générer un QR Code" ? "text-[var(--accent)]" : "text-[var(--text-primary)]"} hover:text-[var(--hover)] text-sm w-[85%] text-left h-8`}>Générer un QR Code</button>
            <button active={page === "Mes QR Codes"} onClick={() => handleButtonClick("Mes QR Codes")} className={`${page === "Mes QR Codes" ? "text-[var(--accent)]" : "text-[var(--text-primary)]"} hover:text-[var(--hover)] text-sm w-[85%] text-left h-8`}>Mes QR Codes</button>
          </div>
        </ButtonMenu>
        {user.subscription.type === 'crew' &&
        <ButtonMenu title={"Portail Manager"} active={page === "Mon Entreprise" || page === "Collaborateurs" || page === "Signature Mail" } menu={true} onClick={page === "Collaborateurs" ? () => {} : () => handleButtonClick("Mon Entreprise")} color={props.color} icon={"fa-solid fa-user-tie"} height={"105px"}>
          <div className='flex flex-col items-end mt-2 justify-center w-full relative'>
            <div className='h-24 absolute left-3 top-0 w-1 rounded-full bg-[var(--text-primary)]'></div>
            <button active={page === "Mon Entreprise"} onClick={() => handleButtonClick("Mon Entreprise")} className={`${page === "Mon Entreprise" ? "text-[var(--accent)]" : "text-[var(--text-primary)]"} hover:text-[var(--hover)] text-sm w-[85%] text-left h-8`}>Mon Entreprise</button>
            <button active={page === "Collaborateurs"} onClick={() => handleButtonClick("Collaborateurs")} className={`${page === "Collaborateurs" ? "text-[var(--accent)]" : "text-[var(--text-primary)]"} hover:text-[var(--hover)] text-sm w-[85%] text-left h-8`}>Collaborateurs</button>
            <button active={page === "Signature Mail"} onClick={() => handleButtonClick("Signature Mail")} className={`${page === "Signature Mail" ? "text-[var(--accent)]" : "text-[var(--text-primary)]"} hover:text-[var(--hover)] text-sm w-[85%] text-left h-8`}>Signature Mail</button>
          </div>
        </ButtonMenu>}
        {/* Autres boutons si nécessaire */}
      </div>
      
      {page === "Mon Hob's Space" && <HobsPage />}
      {page === "Générer un QR Code" && <QrCodeGenerator />}
      {page === "Mes QR Codes" && <AllQRCode />}
      {page === "Mon Entreprise" && <MonEntreprise />}
      {page === "Collaborateurs" && <Collaborateurs />}
      {page === "Signature Mail" && <MailSignature />}
      {page === "Tutoriel" && <Tutorial />}
      {page === "Statistiques" && <Statistics />}
      {page === "Settings" && <Settings />}
    </>
  );
}

export default NavigationDashboard;
