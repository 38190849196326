import React, { useState } from 'react';
import './ButtonMenu.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { ChevronDown } from 'lucide-react';

function ButtonMenu(props) {
  const [menuActive, setMenuActive] = useState(false);

  const handleClick = () => {
    setMenuActive((prev) => !prev);
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div className="w-full flex items-center justify-center flex-col">
      <button
        className={`ButtonMenu text-[var(--text-primary)] ${props.active ? 'active' : ''}`}
        style={{ color: menuActive ? "var(--text-primary)" : "var(--text-primary)" }}
        onClick={handleClick}
      >
        <div className="iconButton">
          <i style={{ color: "var(--text-primary)" }} className={props.icon}></i>
        </div>
        {props.title}
        {props.menu && (
          <ChevronDown
            size={16}
            color="var(--text-primary)"
            className={`absolute right-2 ${menuActive ? 'rotate' : "rotate-180"}`}
          />
        )}
      </button>
      {props.children && (
        <div className="menu-content overflow-hidden" style={{height: menuActive ? props.height : "0px"}}>
          {props.children}
        </div>
      )}
    </div>
  );
}

export default ButtonMenu;
