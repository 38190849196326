import React, { useState, useEffect } from 'react';
import "./UserProfilMenu.css";
import { Link } from 'react-router-dom';
import { CircleHelp, Sun, Moon, UserCog, LogOut, Copy, PanelTop, Headset, Mail, LogIn, BadgeCheck } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import {toggleTheme} from "../../services/store/reducers/themeSlice";

const UserProfileMenu = ({
  isAuthenticated,
  user,
  showUserProfileMenu,
  images,
  handleLogout,
  mobileView
}) => {
  const [copied, setCopied] = useState(false);
  
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);

  // Fonction pour copier l'URL et déclencher l'animation du message
  const handleCopy = () => {
    if (user) {
      const urlToCopy = `https://hobs.space/${user.nameSpace}`;
      navigator.clipboard.writeText(urlToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }
  };

  return (
    <div className={`userProfileMenu ${showUserProfileMenu ? "active" : ""}`}>
      <p
        className={`absolute top-5 right-5 py-[2px] px-1 rounded-md text-[var(--text-primary)] text-[10px] transition-all duration-300 ease-in-out transform ${
          copied ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full'
        }`}
        style={{ backgroundColor: "var(--accent)" }}
      >
        Hob's Space copié
      </p>
      <div className='flex flex-col w-full gap-3 items-start justify-start'>
        <p className='w-full text-xs uppercase text-[var(--background-primary)] text-left'>Hob's Space</p>
        {isAuthenticated ? (
          <>
            <div className="flex flex-row gap-3 w-full items-center justify-between">
              <div className='flex flex-row gap-3 w-full items-center justify-start'>
                {images ? (
                  <img src={images} alt="Profile" className="profilePicture" />
                ) : (
                  <div className="profilePicture">
                    <i className="fa-regular fa-user"></i>
                  </div>
                )}
                <div className='flex flex-col items-start justify-center'>
                  <p className="text-[var(--background-primary)] font-normal">{user?.nameSpace}</p>
                  <p className="text-[var(--background-primary)] font-light text-xs">
                    hobs.space/{user?.nameSpace}
                  </p>
                </div>
              </div>
              <button onClick={handleCopy} className='relative'>
                <Copy color='var(--background-primary)' size={24} />
              </button>
            </div>
            <Link
              to="/dashboard"
              className="flex flex-row items-center justify-start w-full gap-3 text-[var(--background-primary)] font-medium hover:underline-offset-2 hover:underline"
            >
              <PanelTop color='var(--background-primary)' size={21} />
              Configurez votre page
            </Link>
          </>
        ) : (
          <>
            <Link
              to="/login"
              className='flex flex-row items-center justify-start w-full gap-3 text-[var(--background-primary)] font-medium hover:underline-offset-2 hover:underline'
            >
              <LogIn color='var(--background-primary)' size={21} /> Se Connecter
            </Link>
            <Link
              to="/sign-up"
              className='flex flex-row items-center justify-start w-full gap-3 text-[var(--background-primary)] font-medium hover:underline-offset-2 hover:underline'
            >
              <BadgeCheck color='var(--background-primary)' size={21} /> S'inscrire
            </Link>
          </>
        )}
      </div>
      {mobileView && (
        <div className='flex flex-col w-full gap-3 mt-4 items-start justify-start'>
          <p className='w-full text-xs uppercase text-left'>Pages</p>
          <Link
            to="/support"
            className="flex flex-row items-center justify-start w-full gap-3 text-[var(--background-primary)] font-medium hover:underline-offset-2 hover:underline"
          >
            <Headset color='var(--background-primary)' size={21} />
            Support
          </Link>
          <Link
            to="/contact"
            className="flex flex-row items-center justify-start w-full gap-3 text-[var(--background-primary)] font-medium hover:underline-offset-2 hover:underline"
          >
            <Mail color='var(--background-primary)' size={21} />
            Contactez nous
          </Link>
        </div>
      )}
      {isAuthenticated && (
        <div className='flex flex-col w-full gap-3 mt-4 items-start justify-start'>
          <p className='w-full text-xs text-[var(--background-primary)] uppercase text-left'>Utilisateur</p>
          <Link
            to="/me"
            className="flex flex-row items-center justify-start w-full gap-3 text-[var(--background-primary)] font-medium hover:underline-offset-2 hover:underline"
          >
            <UserCog color='var(--background-primary)' size={21} />
            Informations personnelles
          </Link>
          <Link
            to="/"
            className="group flex flex-row items-center justify-start w-full gap-3 text-[var(--background-primary)] hover:text-red-600 hover:underline-offset-2 hover:underline font-medium"
            onClick={handleLogout}
          >
            <LogOut
              color="currentColor"
              size={21}
              className="group-hover:text-red-600"
            />
            Se déconnecter
          </Link>
        </div>
      )}

      {/* Toggle theme */}
      <div className='flex flex-col w-full gap-3 mt-4 items-start justify-start'>
        <p className='w-full text-xs text-[var(--background-primary)] uppercase text-left'>Thème</p>
        <div className="flex flex-row items-center justify-between w-full">
          <span className="text-[var(--background-primary)] font-medium">
            Préférence de thème
          </span>
          <label className="switchUserProfil" htmlFor="themeToggle">
            <input
              id="themeToggle"
              type="checkbox"
              className="circle"
              onChange={() => dispatch(toggleTheme())}
              checked={theme === 'light'}
            />
            <svg
              viewBox="0 0 384 512"
              xmlns="http://www.w3.org/2000/svg"
              className="moon svg"
            >
              <path d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4
                c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6
                c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5
                7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
            </svg>
            <div className="sun svg">
              <span className="dot"></span>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default UserProfileMenu;
