import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './InputField.css';

function InputFieldText(props) {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputChange = (event) => {
        if (props.onChange) {
            props.onChange(event);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <div className={`InputFieldText ${isFocused ? 'focused' : ''}`} style={{ color: `rgba(${props.color}, 1)` }}>
            {props.title && <p className='InputFieldTextTitle'>{props.title}</p>}
            {props.type ?
                <input
                    type="text"
                    className={`border bg-[var(--card-background)] relative caret-[var(--text-primary)] text-[var(--text-primary)] placeholder:text-[var(--text-secondary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)]`}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={handleInputChange}
                    required={props.required}
                    maxLength={props.maxLength}
                    onFocus={handleFocus}
                    onBlur={props.onBlur}
                    onKeyPress={props.onKeyPress}
                />
                :
                <textarea
                    className='border bg-[var(--card-background)] relative caret-[var(--text-primary)] text-[var(--text-primary)] placeholder:text-[var(--text-secondary)] p-2 rounded-lg border-[var(--text-secondary)] w-full focus:outline-none focus:border-[var(--text-secondary)] h-20'
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={handleInputChange}
                    rows={props.rows}
                    maxLength={props.maxLength}
                    required={props.required}
                    onFocus={handleFocus}
                    onBlur={props.onBlur}
                    onKeyPress={props.onKeyPress}
                />
            }
        </div>
    );
}

InputFieldText.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    rows: PropTypes.number,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    color: PropTypes.string,
    type: PropTypes.bool,
};

InputFieldText.defaultProps = {
    title: '',
    placeholder: '',
    value: '',
    rows: 5,
    maxLength: 500,
    required: false,
    color: '0,0,0',
    type: true,
};

export default InputFieldText;
