export const TYPE_SUBSCRIPTION = {
    ADMIN : "admin",
    FREEMIUM: "free",
    BASIC: "basic",
    PRO: "pro"
}

export const BILLING_TYPE = {
    MONTHLY: "monthly",
    ANNUALLY: "annually",
    WEEKLY: "weekly"
}