import { createSlice } from "@reduxjs/toolkit";
/*
- links lein modifier pas édit directement
- à chaque modifiecation de lien ou composant je vais devoir mettre à jour le state de links et page avec 
toutes les fonctions qui modifie un peu un composant

*/
const initialState = {
    email: "",
    pageDashboard: "Mon Hob's Space",
    hobsSpace: "",
    lastname: "",
    firstname: "",
    description: "Entrer une description de 140 caractères maximum",
    backgroundColor: "#000000",
    backgroundImage: undefined,
    bannerImage: undefined,
    colorFont: "#FFFFFF",
    offers: false,
    images: undefined,
    menu: [],
    page: [],
    subscription: {
        type: "free",
        howLong: "",
        createAt: new Date(),
        clientIdStripe: "", 
    },
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        pageDashboard(state, action) {
            state.pageDashboard = action.payload;
        },
        setOffer(state, action) {
            state.offers = action.payload;
        },
        setHobsSpace(state, action) {
            state.hobsSpace = action.payload;
        },
        setEmail(state, action) {
            state.email = action.payload;
        },
        setImages(state, action) {
            state.images = action.payload; // Mettre à jour l'état avec les URLs des images
        }, 
        setLastname(state, action) {
            state.lastname = action.payload;
        }, 
        setFirstname(state, action) {
            state.firstname = action.payload;
        }, 
        setDescription(state, action) {
            state.description = action.payload;
        },
        setBackgroundColor(state, action) {
            state.backgroundColor = action.payload;
        },
        setBackgroundImage(state, action) {
            state.backgroundImage = action.payload; // Mettre à jour l'état avec les URLs des images
        },
        setBannerImage(state, action) {
            state.bannerImage = action.payload; // Mettre à jour l'état avec les URLs des images
        },
        setColorFont(state, action) {
            state.colorFont = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setMenu(state, action) {
            state.menu = action.payload;
        },
    }
})

export const { 
    pageDashboard, 
    setOffer, 
    setHobsSpace, 
    setEmail, 
    setImages, 
    setDescription, 
    setFirstname, 
    setLastname, 
    setBackgroundColor,
    setBackgroundImage,
    setBannerImage,
    setColorFont,
    setPage,
    setMenu,

} = userSlice.actions;

// src/store/slices/userSlice.js
// import { createSlice } from "@reduxjs/toolkit";
// import User from "../../storage/user";

// const initialState = {
//     user: new User(), // Initialiser avec une nouvelle instance de User
//     login: false,
//     pageDashboard: "Accueil",
//     email: "",
//     hobsSpace: "",
//     images: undefined, // Nouvel état pour stocker les URLs des images
// };

// export const userSlice = createSlice({
//     name: "user",
//     initialState,
//     reducers: {
//         pageDashboard(state, action) {
//             state.pageDashboard = action.payload;
//         },
//         setOffer(state, action) {
//             state.user.offer = action.payload;
//         },
//         setHobsSpace(state, action) {
//             state.hobsSpace = action.payload;
//         },
//         setEmail(state, action) {
//             state.email = action.payload;
//         },
//         setImages(state, action) {
//             state.images = action.payload; // Mettre à jour l'état avec les URLs des images
//         },
//         setLastname(state, action) {
//             state.user.updateLastname(action.payload);
//         },
//         setFirstname(state, action) {
//             state.user.updateFirstname(action.payload);
//         },
//         setDescription(state, action) {
//             state.user.updateDescription(action.payload);
//         },
//         setUser(state, action) {
//             state.user = action.payload;
//         },
//         updateUserLink(state, action) {
//             const { name, link } = action.payload;
//             state.user.updateLinkByName(name, link);
//         },
//         updateSubscription(state, action) {
//             const { newType, newHowLong } = action.payload;
//             state.user.updateSubscription(newType, newHowLong);
//         },
//         updateColorFont(state, action) {
//             state.user.updateColorFont(action.payload);
//         },
//         updateBackgroundColor(state, action) {
//             state.user.updateBackgroundColor(action.payload);
//         },
//         updateMenu(state, action) {
//             state.user.updateMenu(action.payload);
//         },
//         updateCountry(state, action) {
//             state.user.updateCountry(action.payload);
//         },
//         updateContactActive(state, action) {
//             const { email, phone } = action.payload;
//             state.user.updateContactActive(email, phone);
//         },
//         updatePhoneNumber(state, action) {
//             state.user.updatePhoneNumber(action.payload);
//         },
//         updatePage(state, action) {
//             state.user.updatePage(action.payload);
//         },
//         reorderPage(state, action) {
//             state.user.reorderPage(action.payload);
//         },
//         removePage(state, action) {
//             state.user.removePage(action.payload);
//         },
//         reorderObjectToComponents(state, action) {
//             const { id, newOrder, component } = action.payload;
//             state.user.reorderObjectToComponents(id, newOrder, component);
//         },
//         addObjectToComponents(state, action) {
//             const { id, newComponent } = action.payload;
//             state.user.addObjectToComponents(id, newComponent);
//         },
//         removeObjectById(state, action) {
//             state.user.removeObjectById(action.payload);
//         },
//         updateObjectInPageById(state, action) {
//             const { id, updates } = action.payload;
//             state.user.updateObjectInPageById(id, updates);
//         },
//         updateImageById(state, action) {
//             const { id, newImage } = action.payload;
//             state.user.updateImageById(id, newImage);
//         },
//         resetUser(state) {
//             state.user = new User();
//         },
//     },
// });

// export const {
//     login,
//     pageDashboard,
//     setOffer,
//     setHobsSpace,
//     setEmail,
//     setImages,
//     setLastname,
//     setFirstname,
//     setDescription,
//     setUser,
//     updateUserLink,
//     updateSubscription,
//     updateColorFont,
//     updateBackgroundColor,
//     updateMenu,
//     updateCountry,
//     updateContactActive,
//     updatePhoneNumber,
//     updatePage,
//     reorderPage,
//     removePage,
//     reorderObjectToComponents,
//     addObjectToComponents,
//     removeObjectById,
//     updateObjectInPageById,
//     updateImageById,
//     resetUser,
// } = userSlice.actions;

