import { createSlice } from '@reduxjs/toolkit';

// Données initiales pour HobsManager – adaptez-les à votre contexte
const initialState = {
  description: "",
  country: { code: "FR", name: "France" },
  colorFont: "#FFFFFF",
  backgroundColor: "#000000",
  menu: [],
  page: [],
  backgroundImage: undefined,
  bannerImage: undefined,
  images: undefined,
  contactActive: { email: false, phone: false },
  entreprise: {
    name: "",
    address: "",
    description: ""
  },
  collaborators: []
};

export const hobsManagerSlice = createSlice({
  name: 'hobsManager',
  initialState,
  reducers: {
    setEntrepriseImages(state, action) {
        state.images = action.payload; // Mettre à jour l'état avec les URLs des images
    }, 
    setDescription(state, action) {
        state.description = action.payload;
    },
    setBackgroundColor(state, action) {
        state.backgroundColor = action.payload;
    },
    setEntrepriseBackgroundImage(state, action) {
        state.backgroundImage = action.payload; // Mettre à jour l'état avec les URLs des images
    },
    setEntrepriseBannerImage(state, action) {
        state.bannerImage = action.payload; // Mettre à jour l'état avec les URLs des images
    },
    setColorFont(state, action) {
        state.colorFont = action.payload;
    },
    setPage(state, action) {
        state.page = action.payload;
    },
    setMenu(state, action) {
        state.menu = action.payload;
    },
  }
});

export const {
    setEntrepriseImages, 
    setDescription, 
    setBackgroundColor,
    setEntrepriseBackgroundImage,
    setEntrepriseBannerImage,
    setColorFont,
    setPage,
    setMenu,
} = hobsManagerSlice.actions;

