import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./reducers/userSlice";
import { authSlice } from "./reducers/authSlice";
import { notifySlice } from "./reducers/notifySlice";
import { hobspaceSlice } from "./reducers/hobspaceSlice";
import { hobsManagerSlice} from "./reducers/hobsManagerSlice";
import { themeSlice } from "./reducers/themeSlice";

export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        auth: authSlice.reducer,
        notify: notifySlice.reducer,
        hobspace: hobspaceSlice.reducer,
        hobsManager: hobsManagerSlice.reducer,
        theme: themeSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredPaths: ['user.subscription.createAt'],
            },
        }),
})

