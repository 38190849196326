import React, { useState, useEffect, useRef } from 'react';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import { hexToRgb } from '../../../utils';
import styles from "./ProgressBar.module.css";
import { useSelector } from 'react-redux';

const ProgressBar = ({ duration, onPlay, onPause, isPlaying, onSkip, currentDuration, audio }) => {
    const [currentTime, setCurrentTime] = useState(currentDuration); // Commence à 0
    const [time, setTime] = useState(duration); // Initialise avec la durée
    const progressBarRef = useRef(null);
    const { hobspace } = useHobspace();
    const bgdImage = useSelector((state) => state.hobspace.backgroundImage);
    
    const formatTime = (timeInMs) => {
        const hours = Math.floor(timeInMs / 3600000); // Convertir en heures
        const minutes = Math.floor((timeInMs % 3600000) / 60000); // Convertir en minutes
        const seconds = Math.floor((timeInMs % 60000) / 1000); // Convertir en secondes
        // Formater le temps : Si l'heure est > 0, afficher HH:mm:ss, sinon afficher mm:ss
        if (hours > 0) {
            return `${hours}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
        } else {
            return `${minutes}:${('0' + seconds).slice(-2)}`;
        }
    };  
    
    useEffect(() => {
        setTime(duration);    
    }, [duration]);

    // Met à jour la durée restante toutes les secondes lorsque la lecture est active
    useEffect(() => {
        let interval;
        if (isPlaying) {
            interval = setInterval(() => {
                if (currentTime < duration) {
                    setCurrentTime((prevTime) => Math.min(prevTime + 1000, duration)); // Incrémente de 1000ms (1 seconde)
                    setTime((prevTime) => Math.max(prevTime - 1000, 0));
                }
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isPlaying, currentTime, duration]);

    useEffect(() => {
        const handleAudioEnd = () => {
            setCurrentTime(0);  // Réinitialiser le temps actuel à 0
            setTime(duration);   // Réinitialiser le temps restant à la durée totale
            console.log('reset');
        };

        // Ajouter un écouteur d'événement "ended" à l'élément audio
        if (audio.current) {
            audio.current.addEventListener('ended', handleAudioEnd);
        }

        // Nettoyer l'écouteur lorsque le composant est démonté ou que l'élément audio change
        return () => {
            if (audio.current) {
                audio.current.removeEventListener('ended', handleAudioEnd);
            }
        };
    }, [audio, duration]);
    
    const handleSkip = (event, seconds) => {
        event.preventDefault();
        event.stopPropagation();
    
        // Appeler la fonction de skip externe
        onSkip(seconds);
    
        // Calculer le nouveau temps en ajoutant ou soustrayant les secondes
        let newTime = currentTime + seconds * 1000;
    
        // Vérifier si on est dans les dernières secondes et ajuster en conséquence
        if (newTime < 0) {
            newTime = 0;  // Si le temps devient inférieur à 0, on le met à 0
        } else if (newTime > duration) {
            newTime = duration;  // Si le temps dépasse la durée, on le met à la durée maximale
        }
    
        // Si on est dans les dernières secondes, éviter de dépasser
        if (newTime >= duration - 1000 && newTime <= duration) {
            newTime = duration;  // Si on est proche de la fin, on met directement à la fin
        }
    
        // Mettre à jour currentTime et time
        setCurrentTime(newTime);
        setTime(duration - newTime);
    
        // Si l'audio est joué, on met à jour la position actuelle de l'audio
        if (audio.current) {
            audio.current.currentTime = newTime / 1000; // Convertir en secondes
        }
    
        // Si le temps actuel atteint la fin de la preview, arrêter le podcast
        if (newTime === duration) {
            onPause();  // Appel à onPause pour arrêter le podcast
        }
    };
    
    return (
        <div className="w-full flex flex-row gap-2">
            <div className="w-[75%] max-sm:w-[70%] flex flex-row gap-1 items-center justify-center">
                <button className="p-1 bg-transparent rounded" onClick={isPlaying ?(e) => handleSkip(e, -15) : () => {}}>
                    <svg data-encore-id="icon" role="img" aria-hidden="true" className="h-4 max-350:h-[14px]" fill={hobspace.colorFont} viewBox="0 0 16 16">
                        <path d="M2.464 4.5h1.473a.75.75 0 0 1 0 1.5H0V2.063a.75.75 0 0 1 1.5 0v1.27a8.25 8.25 0 1 1 10.539 12.554.75.75 0 0 1-.828-1.25A6.75 6.75 0 1 0 2.464 4.5Z"></path>
                        <path d="M0 10.347V9.291c.697-.051 1.199-.18 1.507-.385.315-.205.51-.51.583-.913h1.32v7.81H1.903v-5.456H0Zm7.322 5.643c-.543 0-1.03-.099-1.463-.297a2.46 2.46 0 0 1-1.023-.869c-.25-.389-.382-.85-.396-1.386h1.518c.007.242.066.455.176.638.11.183.26.326.45.43.191.102.411.153.66.153.257 0 .48-.059.672-.176.198-.117.348-.282.45-.495.11-.213.166-.454.166-.726 0-.271-.055-.51-.165-.715a1.135 1.135 0 0 0-.451-.495 1.254 1.254 0 0 0-.671-.176c-.286 0-.536.07-.748.21a1.23 1.23 0 0 0-.462.516H4.56L5 7.993h4.642V9.39H6.207l-.211 2.134c.057-.108.136-.214.237-.319a1.77 1.77 0 0 1 .616-.407c.249-.103.52-.154.814-.154.454 0 .861.103 1.22.308.367.206.653.499.859.88.205.381.308.825.308 1.331 0 .528-.11 1.008-.33 1.441-.22.426-.54.763-.957 1.012-.411.25-.891.374-1.441.374Z"></path>
                    </svg>
                </button>
                <div
                    className="relative w-full h-[4px] max-350:h-[3px] rounded-full cursor-pointer"
                    style={{ background: bgdImage ? "rgba(0,0,0,0.3)" : hobspace.backgroundColor }}
                    ref={progressBarRef}
                >
                    <div className="absolute top-0 left-0 h-[4px] max-350:h-[3px] rounded-full" style={{ background: hobspace.colorFont, width: `${(currentTime / duration) * 100}%` }} />
                </div>
                <button className="p-1 bg-transparent rounded" onClick={isPlaying ?(e) => handleSkip(e, 15) : () => {}}>
                    <svg data-encore-id="icon" role="img" aria-hidden="true" className="h-4 max-350:h-[14px]" fill={hobspace.colorFont} viewBox="0 0 16 16">
                        <path d="M13.536 4.488h-1.473a.75.75 0 1 0 0 1.5H16V2.051a.75.75 0 0 0-1.5 0v1.27A8.25 8.25 0 1 0 3.962 15.876a.75.75 0 0 0 .826-1.252 6.75 6.75 0 1 1 8.747-10.136Z"></path>
                        <path d="M11.81 15.681c.433.198.921.297 1.464.297.55 0 1.03-.124 1.44-.374.419-.25.738-.586.958-1.012.22-.432.33-.913.33-1.44 0-.507-.103-.95-.308-1.332a2.156 2.156 0 0 0-.858-.88 2.416 2.416 0 0 0-1.221-.308c-.294 0-.565.052-.814.154a1.77 1.77 0 0 0-.616.407c-.1.105-.18.211-.237.319l.211-2.134h3.436V7.981h-4.642l-.44 4.61h1.474a1.24 1.24 0 0 1 .462-.518c.212-.14.462-.209.748-.209.256 0 .48.059.67.176.199.118.349.283.452.495.11.206.165.444.165.715 0 .272-.055.514-.165.726a1.135 1.135 0 0 1-.451.495 1.25 1.25 0 0 1-.671.176c-.25 0-.47-.051-.66-.154a1.155 1.155 0 0 1-.451-.429 1.295 1.295 0 0 1-.176-.638h-1.518c.014.536.146.998.396 1.386a2.46 2.46 0 0 0 1.023.87Zm-5.858-5.346V9.28c.697-.051 1.199-.18 1.507-.385.315-.205.51-.51.583-.913h1.32v7.81H7.855v-5.456H5.952Z"></path>
                    </svg>
                </button>
            </div>
            <div className="flex flex-row justify-between w-[25%] max-sm:w-[30%]">
                <div className="text-base max-425:text-sm max-350:text-xs flex flex-row items-center justify-center" style={{ color: hobspace.colorFont }}>
                    - <span>{formatTime(time)}</span>
                </div>
                <div className={styles.playPausePodcast}>
                    {isPlaying ? (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onPause();
                            }}
                            className={styles.pausePodcast}
                            style={{
                                color: "#000",
                                backgroundColor: `${hexToRgb("#FFFFFF", 1)}`,
                            }}
                        >
                            <i className="fa-solid fa-pause" style={{ color: "#000" }}></i>
                        </button>
                    ) : (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onPlay();
                            }}
                            className={styles.playPodcast}
                            style={{
                                color: "#FFF",
                                backgroundColor: `${hexToRgb("#000", 0.6)}`,
                            }}
                        >
                            <i className="fa-solid fa-play" style={{ color: "#FFF" }}></i>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
